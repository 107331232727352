import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const LockIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M17 8V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H4v14h16V8h-3ZM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6Zm9 14H6V10h12v10Zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
