import { axiosExtended } from 'api/axios'

import { getOrderListUrl } from '../urls'
import { GetOrderListData, GetOrderListResponse } from './getOrderList.types'

export const getOrderList = async (query?: GetOrderListData) => {
  const { href } = getOrderListUrl(query ? query : undefined)
  const response = await axiosExtended.get<GetOrderListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
