import {
  fetchTransactionsList,
  FetchTransactionsListData,
} from 'api/requests/admin'
import {
  getTransactionUserList,
  GetTransactionUserListData,
} from 'api/requests/admin'
import { TableData } from 'components/CollapsibleTable'
import { ChangeEvent, ChangeEventHandler, useState } from 'react'
import { TransactionStatuses } from 'types/enums/transaction'
import { Transaction, TransactionUser } from 'types/transactions'
import { formatCurrency, formatDate } from 'utils/format'
import { smoothScroll } from 'utils/scroll'

export const useTransactions = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [users, setUsers] = useState<TransactionUser[]>([])
  const [tableData, setTableData] = useState<TableData | undefined>()
  const [usersTableData, setUsersTableData] = useState<TableData | undefined>()

  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [usersSearch, setUsersSearch] = useState<string>('')
  const [total, setTotal] = useState(0)

  const [filter, setFilter] = useState('all')

  const handleStatusChange = (value: string) => {
    setFilter(value)
  }

  const perPage = 25

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    smoothScroll()
  }

  const handleSearchChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    const value = e.target.value
    setSearch(value)
  }

  const handleClearSearchClick = () => {
    setSearch('')
  }

  const handleUsersSearchChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    const value = e.target.value
    setUsersSearch(value)
  }

  const handleClearUsersSearchClick = () => {
    setUsersSearch('')
  }

  const fetchTransactions = async (query?: FetchTransactionsListData) => {
    try {
      const response = await fetchTransactionsList(query)
      const data = response?.data
      if (data?.items) {
        setTransactions(data.items)
        const rows =
          data?.items?.map(
            ({
              id,
              status,
              amount,
              assignedAt,
              assignedTo,
              currency,
              description,
              stripeTime,
            }) => ({
              cells: [
                {
                  title: description,
                  isTextEllipsis: true,
                  maxCellWidth: '504px',
                },
                {
                  title: amount
                    ? formatCurrency(amount, { style: 'decimal' })
                    : '﹣',
                },
                { title: currency ? currency.toUpperCase() : '-' },
                {
                  title: assignedTo ? TransactionStatuses.assigned : status,
                  isStatus: true,
                },
                {
                  title: stripeTime
                    ? formatDate(new Date(stripeTime), 'M/dd/yyyy, p')
                    : 'N/A',
                },
                {
                  isAction: !Boolean(assignedTo),
                  title: assignedTo,
                  isTextEllipsis: true,
                  maxCellWidth: '186px',
                },
                {
                  title:
                    assignedTo && assignedAt
                      ? formatDate(new Date(assignedAt), 'M/dd/yyyy, p')
                      : 'N/A',
                  cellWidth: '152px',
                },
              ],
              id,
              isGreen: !Boolean(assignedTo),
            })
          ) || []
        setTableData({ rows })
        setTotal(data?.total || 0)
        setCount(Math.ceil(data?.total / 25) || 0)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchTransactionUsers = async (query?: GetTransactionUserListData) => {
    try {
      const response = await getTransactionUserList(query)
      const data = response?.data
      if (data?.items) {
        setUsers(data.items)
        const rows =
          data?.items?.map(
            ({ id, email, businessName, fullName, orderCount }) => ({
              cells: [
                {
                  title: businessName,
                  isTextEllipsis: true,
                  maxCellWidth: '300px',
                },
                {
                  title: email,
                  isTextEllipsis: true,
                  maxCellWidth: '226px',
                },
                {
                  title: fullName,
                  isTextEllipsis: true,
                  maxCellWidth: '192px',
                },
                { title: orderCount, textAlign: 'right' },
              ],
              id,
            })
          ) || []
        setUsersTableData({ rows })
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    users,
    usersSearch,
    page,
    count,
    search,
    perPage,
    total,
    filter,
    usersTableData,
    handleStatusChange,
    fetchTransactionUsers,
    fetchTransactions,
    handleChangePage,
    handleSearchChange,
    handleClearSearchClick,
    handleClearUsersSearchClick,
    handleUsersSearchChange,
    transactions,
    tableData,
  }
}
