import { axiosExtended } from 'api/axios'

import { getUpdateAuctionUrl } from '../urls'
import { UpdateAuctionData, UpdateAuctionResponse } from './updateAuction.types'

export const updateAuction = async (data: UpdateAuctionData) => {
  const { href } = getUpdateAuctionUrl()
  const response = await axiosExtended.post<UpdateAuctionResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
