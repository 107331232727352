import { axiosExtended } from 'api/axios'

import { getChangeNotificationRuleUrl } from '../urls'
import {
  ChangeNotificationRuleData,
  ChangeNotificationRuleResponse,
} from './changeNotificationRule.types'

export const changeNotificationRule = async (
  data: ChangeNotificationRuleData
) => {
  const { href } = getChangeNotificationRuleUrl()
  const response = await axiosExtended.put<ChangeNotificationRuleResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
