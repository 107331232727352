import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const ReminderIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8Zm4.837-6.19 4.607 3.845-1.28 1.535-4.61-3.843 1.283-1.537Zm-10.674 0 1.282 1.536L3.337 7.19l-1.28-1.536L6.663 1.81ZM12 4A9 9 0 1 0 12.001 22 9 9 0 0 0 12 4Zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
