import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const SimpleArrowForwardIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6-1.41 1.41Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
