import {
  addWatchlist,
  closeAuction,
  createAuctionReminder,
  deleteAuction,
  downloadLotFile,
  getAuction,
  GetAuctionData,
  getAuctionReminder,
  removeWatchlist,
} from 'api/requests/auction'
import { addSubscription, removeSubscription } from 'api/requests/subscriptions'
import { saveAs } from 'file-saver'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { AuctionDetailsState, OpenFields } from 'types/auction'
import { Table, TableRow } from 'types/shared'

export const useAuctionDetails = () => {
  const [state, setState] = useState<AuctionDetailsState>({
    open: false,
    openBlock: false,
    openSetReminder: false,
    openCatFollowing: false,
    openBiddingInfo: false,
    openPaymentInfo: false,
    openShipmentInfo: false,
    openBidConfirm: false,
    openSelectWinner: false,
    openSelectWinner2: false,
    openCloseAuction: false,
    openDeleteAuction: false,
    openSellerInfo: false,
    helpMenu: null,
    editAuctionMenu: null,
    tables: {},
  })
  const [selectedWatchlist, setSelectedWatchlist] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [remindersList, setRemindersList] = useState<number[]>([])
  const [remindersListCopy, setRemindersListCopy] = useState<number[]>([])

  const router = useRouter()
  const { id } = router.query

  const fetchRemindersList = async (params?: GetAuctionData) => {
    try {
      const response = await getAuctionReminder(params)

      if (response?.data?.remindTimes) {
        setRemindersList(response.data.remindTimes)
        setRemindersListCopy(response.data.remindTimes)
      } else {
        setRemindersList([])
        setRemindersListCopy([])
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleOpenField = (field: keyof OpenFields, value: boolean) => {
    setState(currentState => ({
      ...currentState,
      [field]: value,
    }))
  }

  const handleHelpMenu = (helpMenu: null | HTMLElement) => {
    setState(currentState => ({
      ...currentState,
      helpMenu,
    }))
  }

  const handleEditAuctionMenu = (editAuctionMenu: null | HTMLElement) => {
    setState(currentState => ({
      ...currentState,
      editAuctionMenu,
    }))
  }

  const handleDownloadLotFile = async () => {
    if (!state.data) {
      return false
    }

    const response = await downloadLotFile(String(state.data.auction.id))
    // TODO: Need to figure out what's wrong with axios types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    saveAs(response, `lot-file-${state.data.auction.id}`)
  }

  const handleDeleteAuction = async () => {
    if (!state.data) {
      return false
    }

    await deleteAuction(String(state.data.auction.id))
    handleOpenField('openDeleteAuction', false)
    await router.push('/auctions/my')
  }

  const fetchAuctionInfo = async () => {
    if (id === undefined) {
      return
    }

    try {
      const { data } = await getAuction({ auctionId: id as string })

      const tables: Record<string, Table> = {}

      if (data) {
        data.assets.forEach((asset, assetIndex) => {
          const category = asset.find(attribute => attribute.isCategory)
          if (!category || !category.value) {
            throw new Error('Asset has no category')
          }

          let categoryData = tables[category.value]
          if (!categoryData) {
            tables[category.value] = {
              quantity: 0,
              table: {
                columns: [],
                rows: [],
              },
            }
            categoryData = tables[category.value]
          }

          const addedColumnIds: Record<number, boolean> = {}
          const row: TableRow = {
            id: assetIndex,
            cells: [],
          }
          let hasQuantityColumn = false

          asset
            .filter(attribute => !attribute.isCategory)
            .forEach((attribute, attributeIndex, attributes) => {
              if (!addedColumnIds[attribute.attributeId]) {
                addedColumnIds[attribute.attributeId] = true
                categoryData.table.columns.push({
                  title: attribute.name,
                  id: attribute.attributeId,
                })
                categoryData.table.columns = categoryData.table.columns.slice(
                  0,
                  attributes.length
                )
              }

              let cell = ''
              if (attribute.value) {
                cell = attribute.value
              } else if (attribute.subAssets) {
                attribute.subAssets.forEach((subAsset, subAssetIndex) => {
                  cell += `${subAssetIndex ? ', ' : ''}${subAsset.value} (${
                    subAsset.quantity
                  })`
                })
              }

              if (attribute.isQuantity) {
                hasQuantityColumn = true
                categoryData.quantity += Number(attribute.value) || 1
              }

              const isLastAttribute = attributeIndex === attributes.length - 1
              if (isLastAttribute && !hasQuantityColumn) {
                categoryData.quantity += 1
              }

              row.cells.push({
                title: cell,
              })
            })

          categoryData.table.rows.push(row)
        })
      }

      setState(currentState => ({
        ...currentState,
        data,
        tables,
      }))
    } catch (e) {
      console.error(e)
    }
  }

  const handleCloseAuction = async () => {
    if (!state.data) {
      return false
    }

    try {
      await closeAuction({ auctionId: state.data.auction.id })
      handleOpenField('openCloseAuction', false)
      await fetchAuctionInfo()
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubscribe = async () => {
    const isSubscribed = state?.data?.isSubscribed
    const sellerId = state?.data?.lot?.sellerId

    if (sellerId) {
      try {
        if (isSubscribed) {
          await removeSubscription({ sellerId })
        } else {
          await addSubscription({ sellerId })
        }
        await fetchAuctionInfo()
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleWatchlistChange = async (event: React.MouseEvent) => {
    if (id) {
      try {
        event.stopPropagation()
        if (selectedWatchlist) {
          setOpenRemoveModal(true)
        } else {
          await addWatchlist({ auctionId: Number(id) })
          setSelectedWatchlist(!selectedWatchlist)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleAcceptDeleting = async () => {
    if (id) {
      try {
        await removeWatchlist(Number(id))
        setSelectedWatchlist(!selectedWatchlist)
      } catch (e) {
        console.error(e)
      } finally {
        setOpenRemoveModal(false)
      }
    }
  }

  const handleCloseDeletingModal = () => setOpenRemoveModal(false)

  useEffect(() => {
    if (state?.data?.isFavourite) {
      setSelectedWatchlist(true)
    }
  }, [state?.data?.isFavourite])

  useEffect(() => {
    void fetchAuctionInfo()
  }, [id])

  const handleReminderChange = (value: number) => {
    if (remindersListCopy.includes(value)) {
      setRemindersListCopy(reminders =>
        reminders.filter(reminder => reminder !== value)
      )
    } else {
      setRemindersListCopy(reminders => [...reminders, value])
    }
  }

  const handleSetSelectedReminders = () => {
    setRemindersListCopy(remindersList)
  }

  const handleClearReminders = () => {
    setRemindersListCopy([])
  }

  const handleAcceptClick = async () => {
    try {
      await createAuctionReminder({
        auctionId: Number(id),
        remindTimes: remindersListCopy,
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    ...state,
    selectedWatchlist,
    openRemoveModal,
    remindersList,
    remindersListCopy,
    fetchAuctionInfo,
    fetchRemindersList,
    handleOpenField,
    handleHelpMenu,
    handleEditAuctionMenu,
    handleDownloadLotFile,
    handleDeleteAuction,
    handleCloseAuction,
    handleSubscribe,
    handleWatchlistChange,
    handleAcceptDeleting,
    handleCloseDeletingModal,
    handleReminderChange,
    handleClearReminders,
    handleSetSelectedReminders,
    handleAcceptClick,
  }
}
