import { StatusType } from 'components/Datatable'

export const renderStatusTitle = (type: StatusType) => {
  switch (type) {
    case 'pending':
      return 'Pending'
    case 'accepted':
      return 'Accepted'
    case 'rejected':
      return 'Rejected'
    case 'invited':
      return 'Invited'
    case 'failed':
      return 'Failed'
    case 'action_required':
      return 'Action required'
    case 'rp_did_not_meet':
      return 'Reserve price not met'
    case 'rp_meet':
      return 'Reserve price met'
    case 'paid':
    case 'paid_bright':
      return 'Paid'
    case 'awaitingPayment':
    case 'awaitingPayment_bright':
      return 'Awaiting payment'
    case 'overdue':
    case 'overdue_bright':
      return 'Overdue'
    case 'voided':
    case 'voided_bright':
      return 'Voided'
    case 'awaitingShipment':
    case 'awaitingShipment_bright':
      return 'Awaiting shipment'
    case 'labelMissing':
    case 'labelMissing_bright':
      return 'Label missing'
    case 'labelUploaded':
    case 'labelUploaded_bright':
      return 'Label uploaded'
    case 'processing':
    case 'processing_bright': {
      return 'Processing'
    }
    case 'readyForPickup':
    case 'readyForPickup_bright':
      return 'Ready for pickup'
    case 'pickedUp':
    case 'pickedUp_bright':
      return 'Picked up'
    case 'delivered':
    case 'delivered_bright':
      return 'Delivered'
    case 'pickupLate':
    case 'pickupLate_bright':
      return 'Pickup late'
    case 'open':
      return 'Open'
    case 'cancelled':
    case 'canceled':
      return 'Cancelled'
    case 'consolidated':
    case 'consolidated_bright':
      return 'Consolidated'
    case 'completed':
    case 'completed_bright':
      return 'Completed'
    case 'partiallyPaid':
    case 'partiallyPaid_bright':
      return 'Partially paid'
    case 'active':
      return 'Active'
    case 'awaiting':
      return 'Awaiting'
    case 'sold':
      return 'Sold'
    case 'closed':
      return 'Closed'
    case 'reposted':
      return 'Reposted'
    case 'scheduled':
      return 'Scheduled'
    case 'shipped':
      return 'Shipped'
    case 'suspended':
      return 'Suspended'
    case 'auctioning':
      return 'Auctioning'
    case 'inStock':
      return 'In stock'
    case 'posted':
      return 'Posted'
    case 'lastBid':
      return 'Last bid'
    case 'inProgress':
      return 'In progress'
    case 'assigned':
      return 'Assigned'
    case 'ready':
      return 'Ready'
    case 'deleted':
      return 'Deleted'
    case 'winning':
      return 'Winning Bid'
    case 'clearingFunds':
      return 'Clearing Funds'
    case 'verification':
      return 'E-mail verification'
    case null:
    case 'null_bright':
      return 'N/A'
    default:
      return type as string
  }
}
