import {
  getConditionsList,
  GetConditionsListResponse,
} from 'api/requests/auction'
import { useEffect, useState } from 'react'
import { useQueryParam } from 'use-query-params'
import { CommaArrayParam } from 'utils/use-query-params'

export const useConditions = (syncWithUrl = false) => {
  const [conditionsQuery, setConditionsQuery] = useQueryParam(
    'conditions',
    CommaArrayParam
  )

  const [conditions, setConditions] = useState<GetConditionsListResponse>()
  const [favoriteConditions, setFavoriteConditions] = useState<number[]>(() =>
    syncWithUrl
      ? conditionsQuery?.map(condition => Number(condition)) ?? []
      : []
  )

  useEffect(() => {
    if (!syncWithUrl) {
      return
    }

    if (favoriteConditions.length === 0) {
      setConditionsQuery(null)
      return
    }

    setConditionsQuery(
      favoriteConditions.map(manufacturer => String(manufacturer))
    )
  }, [favoriteConditions, syncWithUrl])

  const fetchConditions = async () => {
    try {
      const response = await getConditionsList({ extended: true })
      if (response?.data) {
        setConditions(response.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleConditionSelect = (conditionId: number) => {
    let nextFavoriteConditions = [...favoriteConditions]

    if (nextFavoriteConditions?.includes(conditionId)) {
      nextFavoriteConditions = nextFavoriteConditions.filter(
        id => id !== conditionId
      )
    } else {
      nextFavoriteConditions.push(conditionId)
    }
    setFavoriteConditions(nextFavoriteConditions)
  }

  const handleConditionsSelect = (conditionIds: number[]) => {
    let nextFavoriteConditions = [...favoriteConditions]
    if (nextFavoriteConditions?.includes(conditionIds[0])) {
      nextFavoriteConditions = nextFavoriteConditions.filter(
        id => !conditionIds.includes(id)
      )
    } else {
      nextFavoriteConditions = [...nextFavoriteConditions, ...conditionIds]
    }
    setFavoriteConditions(nextFavoriteConditions)
  }
  return {
    conditions,
    fetchConditions,
    handleConditionSelect,
    handleConditionsSelect,
    favoriteConditions,
    setFavoriteConditions,
  }
}
