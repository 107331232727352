import { axiosExtended } from 'api/axios'

import { getStaffUserListUrl } from '../urls'
import { GetStaffUserListResponse } from './getStaffUserList.types'

export const getStaffUserList = async () => {
  const { href } = getStaffUserListUrl()
  const response = await axiosExtended.get<GetStaffUserListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
