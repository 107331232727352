import { axiosExtended } from 'api/axios'

import { getCompleteOrderUrl } from '../urls'
import { CompleteOrderData } from './completeOrder.types'

export const completeOrder = async (data: CompleteOrderData) => {
  const { href } = getCompleteOrderUrl()
  // TODO: replace "any" with actual response type
  const response = await axiosExtended.post<any>(href, data, {
    withAuth: true,
  })
  return response.data
}
