import { axiosExtended } from 'api/axios'

import { getAuctionCategoriesFavoritesUrl } from '../urls'
import { GetAuctionCategoriesFavoritesResponse } from './getAuctionCategoriesFavorites.types'

export const getAuctionCategoriesFavorites = async () => {
  const { href } = getAuctionCategoriesFavoritesUrl()
  const response =
    await axiosExtended.get<GetAuctionCategoriesFavoritesResponse>(href, {
      withAuth: true,
    })
  return response.data
}
