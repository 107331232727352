import { Checkbox, FormControlLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { forwardRef } from 'react'

import { CheckboxCustomProps } from './Checkbox.types'

const BpIcon = styled('span')(() => ({
  borderRadius: 5,
  width: 22,
  height: 22,
  border: '1px solid rgba(34, 34, 34, 0.24)',
  'input:hover ~ &': {
    border: '1px solid rgba(34, 34, 34, 0.48)',
  },
  'input:disabled ~ &': {
    border: '1px solid rgba(34, 34, 34, 0.12)',
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#009970',
  border: 'none',
  '&:before': {
    display: 'block',
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 12.6669L4.49967 9.66655L3.33301 10.8332L7.5 15.0002L16.6722 5.82804L15.5055 4.66138L7.5 12.6669Z' fill='white'/%3E%3C/svg%3E%0A\")",
    width: '100%',
    height: '100%',
    content: '""',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  'input:hover ~ &': {
    border: 'none',
    backgroundColor: '#008662',
  },
})

const BpIndeterminateIcon = styled(BpIcon)({
  backgroundColor: '#D99C00',
  border: 'none',
  '&:before': {
    display: 'block',
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 12.6669L4.49967 9.66655L3.33301 10.8332L7.5 15.0002L16.6722 5.82804L15.5055 4.66138L7.5 12.6669Z' fill='white'/%3E%3C/svg%3E%0A\")",
    width: '100%',
    height: '100%',
    content: '""',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  'input:hover ~ &': {
    border: 'none',
    backgroundColor: '#D99C00',
  },
})

export const CheckboxCustom = forwardRef<
  HTMLInputElement,
  React.PropsWithoutRef<CheckboxCustomProps>
>((props, ref) => {
  return (
    <FormControlLabel
      sx={{
        '& .MuiTypography-root': {
          '&.MuiLink-root': {
            fontWeight: 600,
            textDecoration: 'none',
            padding: '0 5px',
          },
        },
      }}
      control={
        <Checkbox
          inputRef={ref}
          checkedIcon={<BpCheckedIcon />}
          indeterminateIcon={<BpIndeterminateIcon />}
          icon={<BpIcon />}
          sx={{
            backgroundColor: 'transparent',
            pr: '12px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& .Mui-checked': {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          }}
        />
      }
      {...props}
    />
  )
})
