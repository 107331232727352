import { UserRoles } from 'types/enums'

import { useUserContext } from './useUserContext'

interface SessionData {
  [key: string]: unknown
}

export const useHelpScout = (sessionData?: SessionData) => {
  const { user } = useUserContext()
  const isSeller = Boolean(
    user?.roles.some(role => role.code === UserRoles.Seller)
  )
  const isBuyer = Boolean(
    user?.roles.some(role => role.code === UserRoles.Buyer)
  )

  const handleHelpItemClick = (item: string, extraData?: SessionData) => {
    if (window?.Beacon) {
      window.Beacon('session-data', {
        'help subject': item,
        'User ID': user?.id || 'N/A',
        ...(!user?.id ? { 'Auction ID': extraData?.auctionId } : {}),
        ...(sessionData && sessionData),
      })
      window.Beacon('identify', {
        name: user ? `${user?.firstName} ${user?.lastName}` : 'Guest',
        ...(user?.email ? { email: user?.email } : {}),
        'user-type': isSeller ? 'Seller' : isBuyer ? 'Buyer' : 'Guest',
        // signature: '28b3M2PMi8jidGX4oFxxNYbwq2tP05me2xagRtl6+Oc=',
      })
      window.Beacon('prefill', {
        subject: item,
      })
      window.Beacon('open')
      window.Beacon('navigate', '/ask/message')
    }
  }

  return {
    handleHelpItemClick,
  }
}
