import { QueryParamConfig } from 'serialize-query-params'
import { decodeDelimitedArray, encodeDelimitedArray } from 'use-query-params'

/**
 * Uses a comma to delimit entries
 *
 * @example ['a', 'b'] => qp?=a,b
 */
export const CommaArrayParam: Pick<
  QueryParamConfig<
    string[] | null | undefined,
    (string | null)[] | null | undefined
  >,
  'encode' | 'decode'
> = {
  encode: array => encodeDelimitedArray(array, ','),
  decode: arrayStr => decodeDelimitedArray(arrayStr, ','),
}
