import { axiosExtended } from 'api/axios'

import { getLoginUrl } from '../urls'
import { LoginData, LoginResponse } from './login.types'

export const login = async (data: LoginData) => {
  const { href } = getLoginUrl()
  const response = await axiosExtended.post<LoginResponse>(href, data)
  return response.data
}
