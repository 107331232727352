import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const RepostIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M11.674 7H12a6 6 0 1 1-4.728 2.306L5.85 7.884A8 8 0 1 0 12 5h-.705l2.08-2.08-1.41-1.42-4.5 4.5 4.5 4.5 1.41-1.41-2.08-2.08.379-.005V7Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
