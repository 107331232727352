import {
  changeNotificationRule,
  ChangeNotificationRuleData,
  getNotificationsRulesList,
  GetNotificationsRulesListResponse,
  updateNotificationRuleEmail,
  UpdateNotificationRuleEmailData,
} from 'api/requests/notifications'
import { useState } from 'react'

export const useNotificationRules = () => {
  const [notificationsList, setNotificationsList] =
    useState<GetNotificationsRulesListResponse>()

  const [isNotificationsLoading, setIsNotificationsLoading] =
    useState<boolean>(true)

  const fetchNotificationRules = async () => {
    try {
      setIsNotificationsLoading(true)
      const response = await getNotificationsRulesList()
      if (response?.data) {
        setNotificationsList(response.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsNotificationsLoading(false)
    }
  }

  const handleChangeNotificationRule = async (
    data: ChangeNotificationRuleData
  ) => {
    try {
      await changeNotificationRule(data)
      await fetchNotificationRules()
    } catch (e) {
      console.error(e)
    }
  }

  const handleUpdateEmail = async (data: UpdateNotificationRuleEmailData) => {
    try {
      await updateNotificationRuleEmail(data)
      await fetchNotificationRules()
    } catch (e) {
      console.error(e)
    }
  }

  return {
    notificationsList,
    isNotificationsLoading,
    handleUpdateEmail,
    fetchNotificationRules,
    handleChangeNotificationRule,
  }
}
