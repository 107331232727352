import { axiosExtended } from 'api/axios'

import { getGetAuctionListSimpleUrl } from '../urls'
import {
  GetAuctionListSimpleData,
  GetAuctionListSimpleResponse,
} from './getAuctionListSimple.types'

export const getAuctionListSimple = async (
  query?: GetAuctionListSimpleData
) => {
  const { href } = getGetAuctionListSimpleUrl(query ? query : undefined)
  const response = await axiosExtended.get<GetAuctionListSimpleResponse>(href, {
    withAuth: true,
  })
  return response.data
}
