import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const DocumentIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path d='M14 2H4v20h16V8l-6-6Zm4 18H6V4h7v5h5v11Z' fill='currentColor' />
    <path d='M8 12h8v2H8v-2ZM8 16h8v2H8v-2Z' fill='currentColor' />
  </SvgWrapper>
)
