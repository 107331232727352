import { Menu, MenuItem, MenuProps } from '@mui/material'
import React, { FC } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import { Props } from './DropdownMenu.types'

const wrapperStyles = {
  mt: '40px',
  '& .MuiPaper-root': {
    minWidth: '180px',
    borderRadius: '12px',
    boxShadow:
      '0px 0px 2px rgba(34, 34, 34, 0.1), 0px 8px 18px rgba(34, 34, 34, 0.1)',
  },
  '& .MuiMenuItem-root': {
    padding: '12px 24px',
  },
}

export const DropdownMenu: FC<Props & MenuProps> = ({
  id,
  items = [],
  anchorEl,
  onCloseClick,
  isCloseOnSelect,
  ...props
}) => {
  return (
    <OutsideClickHandler onOutsideClick={onCloseClick}>
      <Menu
        sx={wrapperStyles}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...props}
      >
        {items.map(({ title, clickHandler }, index) => {
          const onItemClickHandler = () => {
            if (isCloseOnSelect) {
              onCloseClick()
            }
            clickHandler(id)
          }

          return (
            <MenuItem onClick={onItemClickHandler} key={index}>
              {title}
            </MenuItem>
          )
        })}
      </Menu>
    </OutsideClickHandler>
  )
}
