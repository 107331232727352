import { axiosExtended } from 'api/axios'

import { getNotificationsRulesListUrl } from '../urls'
import { GetNotificationsRulesListResponse } from './getNotificationsRulesList.types'

export const getNotificationsRulesList = async () => {
  const { href } = getNotificationsRulesListUrl()
  const response = await axiosExtended.get<GetNotificationsRulesListResponse>(
    href,
    {
      withAuth: true,
    }
  )
  return response.data
}
