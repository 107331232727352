import { axiosExtended } from 'api/axios'

import { getGetPayoutUrl } from '../urls'
import { GetPayoutData, GetPayoutResponse } from './getPayout.types'

export const getPayout = async (query?: GetPayoutData) => {
  const { href } = getGetPayoutUrl(query)
  const response = await axiosExtended.get<GetPayoutResponse>(href, {
    withAuth: true,
  })
  return response.data
}
