import { axiosExtended } from 'api/axios'

import { getUserSettingsUrl } from '../urls'
import { SetUserSettingsData } from './setUserSettings.types'

export const setUserSettings = async (data: SetUserSettingsData) => {
  const { href } = getUserSettingsUrl()
  // TODO: add corresponding type for the response
  // eslint-disable-next-line
  const response = await axiosExtended.post<any>(href, data, {
    withAuth: true,
  })
  return response.data
}
