import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const SimpleArrowDownwardIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
