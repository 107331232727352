export interface ValidatedFiles {
  status: 'error' | 'success'
  data: File[]
  isValidFileLength: boolean
}

export const MAX_FILE_SIZE = 20971520 // 20 MB

export const isFileSizesValid = (
  filesList: FileList | File[],
  maxFileSize?: number
) => {
  return Array.from(filesList).every(
    file => file.size < (maxFileSize || MAX_FILE_SIZE)
  )
}

export const validateFiles = (
  filesList: FileList | File[],
  oldFilesList: File[],
  maxFileLength?: number,
  maxFileSize?: number
): ValidatedFiles => {
  const isValidFileLength =
    filesList.length > 0 &&
    filesList.length + oldFilesList?.length <= (maxFileLength || 5)
  const isValid = isValidFileLength && isFileSizesValid(filesList, maxFileSize)
  const result: ValidatedFiles = {
    status: 'error',
    data: [],
    isValidFileLength: Boolean(isValidFileLength),
  }

  if (isValid) {
    result.status = 'success'
    result.data = [...oldFilesList, ...Array.from(filesList)]
  }
  return result
}
