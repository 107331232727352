import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const LogoIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M8.268 12.8041C9.568 12.8041 10.7467 13.1074 11.804 13.7141C12.8787 14.3034 13.7193 15.1441 14.326 16.2361C14.9327 17.3108 15.236 18.5674 15.236 20.0061C15.236 21.4448 14.9327 22.7101 14.326 23.8021C13.7193 24.8768 12.8787 25.7174 11.804 26.3241C10.7467 26.9134 9.568 27.2081 8.268 27.2081C6.344 27.2081 4.87933 26.6014 3.874 25.3881V27.0001H0V7.5H4.056V14.4941C5.07867 13.3674 6.48267 12.8041 8.268 12.8041ZM7.566 23.8801C8.606 23.8801 9.45533 23.5334 10.114 22.8401C10.79 22.1294 11.128 21.1848 11.128 20.0061C11.128 18.8274 10.79 17.8914 10.114 17.1981C9.45533 16.4874 8.606 16.1321 7.566 16.1321C6.526 16.1321 5.668 16.4874 4.992 17.1981C4.33333 17.8914 4.004 18.8274 4.004 20.0061C4.004 21.1848 4.33333 22.1294 4.992 22.8401C5.668 23.5334 6.526 23.8801 7.566 23.8801Z'
      fill='currentColor'
    />
    <path
      d='M18.2112 13.0121H22.2672V27.0001H18.2112V13.0121ZM20.2392 11.0621C19.4938 11.0621 18.8872 10.8454 18.4192 10.4121C17.9512 9.97875 17.7172 9.44142 17.7172 8.80009C17.7172 8.15875 17.9512 7.62142 18.4192 7.18809C18.8872 6.75475 19.4938 6.53809 20.2392 6.53809C20.9845 6.53809 21.5912 6.74609 22.0592 7.16209C22.5272 7.57809 22.7612 8.09809 22.7612 8.72209C22.7612 9.39809 22.5272 9.96142 22.0592 10.4121C21.5912 10.8454 20.9845 11.0621 20.2392 11.0621Z'
      fill='currentColor'
    />
    <path
      d='M40.2795 7.5V27.0001H36.4055V25.3881C35.4002 26.6014 33.9441 27.2081 32.0375 27.2081C30.7201 27.2081 29.5242 26.9134 28.4495 26.3241C27.3922 25.7348 26.5602 24.8941 25.9535 23.8021C25.3468 22.7101 25.0435 21.4448 25.0435 20.0061C25.0435 18.5674 25.3468 17.3021 25.9535 16.2101C26.5602 15.1181 27.3922 14.2774 28.4495 13.6881C29.5242 13.0988 30.7201 12.8041 32.0375 12.8041C33.8228 12.8041 35.2182 13.3674 36.2235 14.4941V7.5H40.2795ZM32.7395 23.8801C33.7621 23.8801 34.6115 23.5334 35.2875 22.8401C35.9635 22.1294 36.3015 21.1848 36.3015 20.0061C36.3015 18.8274 35.9635 17.8914 35.2875 17.1981C34.6115 16.4874 33.7621 16.1321 32.7395 16.1321C31.6995 16.1321 30.8415 16.4874 30.1655 17.1981C29.4895 17.8914 29.1515 18.8274 29.1515 20.0061C29.1515 21.1848 29.4895 22.1294 30.1655 22.8401C30.8415 23.5334 31.6995 23.8801 32.7395 23.8801Z'
      fill='currentColor'
    />
    <path
      d='M49.5194 27.2081C48.3581 27.2081 47.2228 27.0694 46.1134 26.7921C45.0041 26.4974 44.1201 26.1334 43.4614 25.7001L44.8134 22.7881C45.4374 23.1868 46.1914 23.5161 47.0754 23.7761C47.9594 24.0188 48.8261 24.1401 49.6754 24.1401C51.3914 24.1401 52.2494 23.7154 52.2494 22.8661C52.2494 22.4674 52.0154 22.1814 51.5474 22.0081C51.0794 21.8348 50.3601 21.6874 49.3894 21.5661C48.2454 21.3928 47.3008 21.1934 46.5554 20.9681C45.8101 20.7428 45.1601 20.3441 44.6054 19.7721C44.0681 19.2001 43.7994 18.3854 43.7994 17.3281C43.7994 16.4441 44.0508 15.6641 44.5534 14.9881C45.0734 14.2948 45.8188 13.7574 46.7894 13.3761C47.7774 12.9948 48.9388 12.8041 50.2734 12.8041C51.2614 12.8041 52.2408 12.9168 53.2114 13.1421C54.1994 13.3501 55.0141 13.6448 55.6554 14.0261L54.3034 16.9121C53.0728 16.2188 51.7294 15.8721 50.2734 15.8721C49.4068 15.8721 48.7568 15.9934 48.3234 16.2361C47.8901 16.4788 47.6734 16.7908 47.6734 17.1721C47.6734 17.6054 47.9074 17.9088 48.3754 18.0821C48.8434 18.2554 49.5888 18.4201 50.6114 18.5761C51.7554 18.7668 52.6914 18.9748 53.4194 19.2001C54.1474 19.4081 54.7801 19.7981 55.3174 20.3701C55.8548 20.9421 56.1234 21.7394 56.1234 22.7621C56.1234 23.6288 55.8634 24.4001 55.3434 25.0761C54.8234 25.7521 54.0608 26.2808 53.0554 26.6621C52.0674 27.0261 50.8888 27.2081 49.5194 27.2081Z'
      fill='currentColor'
    />
    <path
      d='M67.3879 12.8041C69.1213 12.8041 70.5166 13.3241 71.5739 14.3641C72.6486 15.4041 73.1859 16.9468 73.1859 18.9921V27.0001H69.1299V19.6161C69.1299 18.5068 68.8873 17.6834 68.4019 17.1461C67.9166 16.5914 67.2146 16.3141 66.2959 16.3141C65.2733 16.3141 64.4586 16.6348 63.8519 17.2761C63.2453 17.9001 62.9419 18.8361 62.9419 20.0841V27.0001H58.8859V7.5H62.9419V14.4681C63.4793 13.9308 64.1293 13.5234 64.8919 13.2461C65.6546 12.9514 66.4866 12.8041 67.3879 12.8041Z'
      fill='currentColor'
    />
    <path
      d='M83.9085 23.9581C84.6365 23.9581 85.2778 23.8541 85.8325 23.6461C86.4045 23.4208 86.9332 23.0741 87.4185 22.6061L89.5765 24.9461C88.2592 26.4541 86.3352 27.2081 83.8045 27.2081C82.2272 27.2081 80.8318 26.9048 79.6185 26.2981C78.4052 25.6741 77.4692 24.8161 76.8105 23.7241C76.1518 22.6321 75.8225 21.3928 75.8225 20.0061C75.8225 18.6368 76.1432 17.4061 76.7845 16.3141C77.4432 15.2048 78.3358 14.3468 79.4625 13.7401C80.6065 13.1161 81.8892 12.8041 83.3105 12.8041C84.6452 12.8041 85.8585 13.0901 86.9505 13.6621C88.0425 14.2168 88.9092 15.0314 89.5505 16.1061C90.2092 17.1634 90.5385 18.4201 90.5385 19.8761L80.1905 21.8781C80.4852 22.5714 80.9445 23.0914 81.5685 23.4381C82.2098 23.7848 82.9898 23.9581 83.9085 23.9581ZM83.3105 15.8721C82.2878 15.8721 81.4558 16.2014 80.8145 16.8601C80.1732 17.5188 79.8352 18.4288 79.8005 19.5901L86.6125 18.2641C86.4218 17.5361 86.0318 16.9554 85.4425 16.5221C84.8532 16.0888 84.1425 15.8721 83.3105 15.8721Z'
      fill='currentColor'
    />
    <path
      d='M100.571 23.9581C101.299 23.9581 101.94 23.8541 102.495 23.6461C103.067 23.4208 103.596 23.0741 104.081 22.6061L106.239 24.9461C104.922 26.4541 102.998 27.2081 100.467 27.2081C98.8895 27.2081 97.4942 26.9048 96.2808 26.2981C95.0675 25.6741 94.1315 24.8161 93.4728 23.7241C92.8142 22.6321 92.4848 21.3928 92.4848 20.0061C92.4848 18.6368 92.8055 17.4061 93.4468 16.3141C94.1055 15.2048 94.9982 14.3468 96.1248 13.7401C97.2688 13.1161 98.5515 12.8041 99.9728 12.8041C101.308 12.8041 102.521 13.0901 103.613 13.6621C104.705 14.2168 105.572 15.0314 106.213 16.1061C106.872 17.1634 107.201 18.4201 107.201 19.8761L96.8528 21.8781C97.1475 22.5714 97.6068 23.0914 98.2308 23.4381C98.8722 23.7848 99.6522 23.9581 100.571 23.9581ZM99.9728 15.8721C98.9502 15.8721 98.1182 16.2014 97.4768 16.8601C96.8355 17.5188 96.4975 18.4288 96.4628 19.5901L103.275 18.2641C103.084 17.5361 102.694 16.9554 102.105 16.5221C101.516 16.0888 100.805 15.8721 99.9728 15.8721Z'
      fill='currentColor'
    />
    <path
      d='M118.351 26.3241C117.953 26.6188 117.459 26.8441 116.869 27.0001C116.297 27.1388 115.699 27.2081 115.075 27.2081C113.394 27.2081 112.103 26.7834 111.201 25.9341C110.3 25.0848 109.849 23.8368 109.849 22.1901V10H113.905V13.3241H117.363V16.4441H113.905V22.1381C113.905 22.7274 114.053 23.1868 114.347 23.5161C114.642 23.8281 115.067 23.9841 115.621 23.9841C116.245 23.9841 116.8 23.8108 117.285 23.4641L118.351 26.3241Z'
      fill='currentColor'
    />
    <path d='M130 0H121V4H130V0Z' fill='currentColor' />
    <path d='M141 0H132V4H141V0Z' fill='currentColor' />
    <path d='M121 6H130V10H121V6Z' fill='currentColor' />
    <path d='M141 6H132V10H141V6Z' fill='currentColor' />
  </SvgWrapper>
)
