import { axiosExtended } from 'api/axios'

import { getAuctionCategoriesFavoritesUrl } from '../urls'
import {
  SetAuctionCategoriesFavoritesData,
  SetAuctionCategoriesFavoritesResponse,
} from './setAuctionCategoriesFavorites.types'

export const setAuctionCategoriesFavorites = async (
  data: SetAuctionCategoriesFavoritesData
) => {
  const { href } = getAuctionCategoriesFavoritesUrl()
  const response =
    await axiosExtended.post<SetAuctionCategoriesFavoritesResponse>(
      href,
      data,
      {
        withAuth: true,
      }
    )
  return response.data
}
