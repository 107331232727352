import { axiosExtended } from 'api/axios'

import { getDeleteUserAccountUrl } from '../urls'
import { DeleteUserAccountResponse } from './deleteUserAccount.types'

export const deleteUserAccount = async () => {
  const { href } = getDeleteUserAccountUrl()
  const response = await axiosExtended.delete<DeleteUserAccountResponse>(href, {
    withAuth: true,
  })
  return response.data
}
