import { getLocationList } from 'api/requests/location'
import { useState } from 'react'
import { Location } from 'types/location'

export const useDeliveryLocations = () => {
  const [locations, setLocations] = useState<Location[]>([])

  const fetchLocationList = async () => {
    const response = await getLocationList()
    if (response?.data) {
      setLocations(response.data)
    }
  }

  return {
    locations,
    fetchLocationList,
  }
}
