import { axiosExtended } from 'api/axios'

import { getLotUrl } from '../urls'
import { GetLotData, GetLotResponse } from './getLot.types'

export const getLot = async (data: GetLotData) => {
  const { href } = getLotUrl(data)
  const response = await axiosExtended.get<GetLotResponse>(href, {
    withAuth: true,
  })
  return response.data
}
