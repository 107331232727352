import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const MasterCardLogo: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path d='M24.518 6.984H14.483v18.032h10.035V6.984Z' fill='#FF5F00' />
    <path
      d='M15.12 16c0-3.663 1.72-6.913 4.364-9.016a11.399 11.399 0 0 0-7.072-2.453c-6.34 0-11.47 5.13-11.47 11.47 0 6.34 5.13 11.468 11.47 11.468 2.676 0 5.129-.924 7.072-2.453A11.452 11.452 0 0 1 15.12 16Z'
      fill='#EB001B'
    />
    <path
      d='M38.057 16c0 6.34-5.129 11.47-11.469 11.47-2.676 0-5.129-.924-7.072-2.454A11.404 11.404 0 0 0 23.88 16c0-3.663-1.72-6.913-4.364-9.016a11.399 11.399 0 0 1 7.072-2.453c6.34 0 11.47 5.161 11.47 11.47Z'
      fill='#F79E1B'
    />
  </SvgWrapper>
)
