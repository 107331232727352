import { axiosExtended } from 'api/axios'

import { getDownloadPackingListUrl } from '../urls'
import { DownloadPackingListResponse } from './downloadPackingList.types'

export const downloadPackingList = async (id: number) => {
  const { href } = getDownloadPackingListUrl(id)
  const response = await axiosExtended.get<DownloadPackingListResponse>(href, {
    withAuth: true,
    responseType: 'blob',
  })

  return response.data
}
