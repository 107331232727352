import { axiosExtended } from 'api/axios'

import { getCheckIsEmailRegisteredUrl } from '../urls'
import { CheckIsEmailRegisteredData } from './checkIsEmailRegistered.types'

export const checkIsEmailRegistered = async (
  data: CheckIsEmailRegisteredData
) => {
  const { href } = getCheckIsEmailRegisteredUrl(data)
  // TODO: add corresponding type for the response
  // eslint-disable-next-line
  const response = await axiosExtended.get<any>(href)
  return response.data
}
