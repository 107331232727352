import { useRouter } from 'next/router'

export const useClearQueryParams = (): (() => Promise<void>) => {
  const router = useRouter()
  const clearQueryParams = async () => {
    try {
      await router.replace(router.pathname, undefined, {
        shallow: true,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return clearQueryParams
}
