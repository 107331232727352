import {
  deleteLot as deleteLotRequest,
  getLot,
  GetLotResponse,
} from 'api/requests/lot'
import { useEffect, useState } from 'react'

export const useLot = (lotId: string | number) => {
  const [lot, setLot] = useState<GetLotResponse | null>(null)

  const fetchLot = async () => {
    if (!lotId) {
      return
    }

    try {
      const response = await getLot({ lotId })
      if (!response.data) {
        return
      }

      setLot(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const deleteLot = async (lotIdToDelete: string | number) => {
    if (!lotIdToDelete) {
      return
    }

    try {
      await deleteLotRequest({ lotId: lotIdToDelete })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    void fetchLot()
  }, [lotId])

  return {
    lot,
    deleteLot,
  }
}
