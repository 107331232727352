import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const AmexLogo: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path d='M25 28H1V4h24v24Z' fill='#fff' />
    <path
      d='M13.045 12.694H11.22l.912-2.197.912 2.197Zm1.199 2.85h2.063l-2.965-6.716h-2.36l-2.965 6.716h2.015l.556-1.343h3.09l.566 1.343Zm8.942 0H25V8.828h-2.821l-1.506 4.183-1.497-4.183h-2.87v6.716h1.814v-4.702l1.727 4.702h1.612l1.727-4.711v4.711Zm-10.764 6.137v-1.046h3.79v-1.516h-3.79v-1.046h3.885V16.52H10.59v6.716h5.718v-1.554h-3.885Zm10.733-1.82L25 21.823v-3.908l-1.845 1.946Zm-.563 3.374H25l-3.185-3.377L25 16.518h-2.37l-1.966 2.15-1.948-2.15h-2.409l3.167 3.359-3.166 3.358h2.34l1.977-2.168 1.967 2.168ZM25 28v-3.803h-2.895l-1.491-1.648-1.498 1.648H9.569v-7.682H6.49l3.821-8.65h3.686l1.316 2.964V7.866h4.562l.792 2.232.797-2.232H25V4H1v24h24Z'
      fill='#1977C6'
    />
  </SvgWrapper>
)
