import { capitalize } from 'lodash'
import { Package } from 'types/lot'

export const createPackageRows = (
  packages: Array<Package<number> & { id: number }>
) => {
  return packages.map(({ type, width, length, height, weight }, index) => ({
    cells: [
      {
        title: `${capitalize(type)} #${index + 1}`,
        maxCellWidth: '152px',
      },
      {
        title: `${width}"`,
        textAlign: 'center',
        maxCellWidth: '232px',
      },
      {
        title: `${length}"`,
        textAlign: 'center',
        maxCellWidth: '232px',
      },
      {
        title: `${height}"`,
        textAlign: 'center',
        maxCellWidth: '232px',
      },
      {
        title: `${weight} lbs`,
        textAlign: 'right',
        maxCellWidth: '164px',
      },
    ],
    id: index,
  }))
}
