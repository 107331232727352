import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { CheckIsEmailRegisteredData } from './checkIsEmailRegistered'

export const getCheckIsEmailRegisteredUrl = (
  query?: CheckIsEmailRegisteredData
): URL => {
  const url = `${API_URL}/user/checkEmail`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getConfirmEmailUrl = (): URL => {
  const url = `${API_URL}/user/confirmEmail`
  return new URL(url)
}

export const getResendConfirmEmailUrl = (): URL => {
  const url = `${API_URL}/user/sendVerificationCode`
  return new URL(url)
}
