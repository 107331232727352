import { axiosExtended } from 'api/axios'

import { getConsolidateOrdersUrl } from '../urls'
import {
  ConsolidateOrdersData,
  ConsolidateOrdersResponse,
} from './consolidateOrders.types'

export const consolidateOrders = async (data: ConsolidateOrdersData) => {
  const { href } = getConsolidateOrdersUrl()
  const response = await axiosExtended.post<ConsolidateOrdersResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
