import { axiosExtended } from 'api/axios'

import { getCurrentUserInfoUrl } from '../urls'
import { GetCurrentUserInfoResponse } from './getCurrentUserInfo.types'

export const getCurrentUserInfo = async () => {
  const { href } = getCurrentUserInfoUrl()
  const response = await axiosExtended.get<GetCurrentUserInfoResponse>(href, {
    withAuth: true,
  })
  return response.data
}
