export const formatAmPmTo24 = (timeWithAmPm: string) => {
  const [time, postfix] = timeWithAmPm.split(' ')
  const [hours, minutes] = time.split(':')
  let currentHours = hours

  if (postfix === 'pm') {
    currentHours = `${Number(hours) + 12}`
  } else if (Number(hours) < 10) {
    currentHours = `0${Number(hours)}`
  }

  return `${[currentHours, minutes].join(':')}`
}
