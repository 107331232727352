import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const PremiumIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='m12 12.275-2.939 2.229 1.115-3.61-2.926-2.33h3.597L12 5.003l1.153 3.56h3.597l-2.939 2.33 1.115 3.61L12 12.274Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.279 18.91a9.077 9.077 0 0 1-2.557 0L6.937 24 5.38 20.204l-3.971-.573 3.27-4.397a9 9 0 1 1 14.645 0l3.27 4.397-3.972.573L17.063 24l-3.784-5.09ZM18.75 10a6.75 6.75 0 1 1-13.5 0 6.75 6.75 0 0 1 13.5 0Zm-3.393 8.353 1.2 1.613.648-1.579 1.78-.256-1.03-1.383a9.002 9.002 0 0 1-2.598 1.605Zm-9.312-1.605L5.016 18.13l1.78.256.648 1.58 1.2-1.614a8.999 8.999 0 0 1-2.6-1.605Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
