import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const BigUploadIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <g clipPath='url(#a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='m31.987 5.922 16.667 16.664-2.828 2.828L34 13.59V45h-4V13.652L18.323 25.535l-2.853-2.803 16.517-16.81ZM7 43h4v9h42v-9h4v13H7V43Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h64v64H0z' />
      </clipPath>
    </defs>
  </SvgWrapper>
)
