import { axiosExtended } from 'api/axios'

import { getRemoveSubscriptionUrl } from '../urls'
import {
  RemoveSubscriptionData,
  RemoveSubscriptionResponse,
} from './removeSubscription.types'

export const removeSubscription = async (data: RemoveSubscriptionData) => {
  const { href } = getRemoveSubscriptionUrl(data)
  const response = await axiosExtended.delete<RemoveSubscriptionResponse>(
    href,
    { withAuth: true }
  )
  return response.data
}
