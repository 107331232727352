import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const DateRangeIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M7 11h2v2H7v-2Zm14 11H3c0-6 .01-12 .01-18H6V2h2v2h8V2h2v2h3v18ZM5 8h14V6H5v2Zm14 12V10H5v10h14Zm-4-7h2v-2h-2v2Zm-4 0h2v-2h-2v2Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
