import { API_URL } from 'constants/env'

export const getRegisterUrl = (): URL => {
  const url = `${API_URL}/user/register`
  return new URL(url)
}

export const getLoginUrl = (): URL => {
  const url = `${API_URL}/user/login`
  return new URL(url)
}

export const getRefreshTokensUrl = (): URL => {
  const url = `${API_URL}/user/refreshToken`
  return new URL(url)
}
