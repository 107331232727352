import { NotificationsContext } from 'context'
import { useContext } from 'react'

export const useNotificationsContext = () => {
  const {
    notificationList,
    unreadNotificationsTotal,
    fetchNotificationsList,
    popNotification,
    handleChangePage,
    page,
    count,
  } = useContext(NotificationsContext)

  return {
    notificationList,
    unreadNotificationsTotal,
    fetchNotificationsList,
    popNotification,
    handleChangePage,
    page,
    count,
  }
}
