import {
  Box,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import ActionButton from 'components/ActionButton/ActionButton'
import { ButtonCustom } from 'components/Button'
import { ButtonWithIcon } from 'components/ButtonWIthIcon'
import { CheckboxCustom } from 'components/Checkbox'
import { LabelButton } from 'components/LabelButton'
import { LinkedText } from 'components/LinkedText'
import { ModalCustom } from 'components/ModalCustom'
import { StatusField } from 'components/StatusField'
import { TooltipForEllipsis } from 'components/TooltipForEllipsis'
import { useUserContext } from 'hooks'
import {
  ArrowDownwardIcon,
  AuctionIcon,
  ClearIcon,
  DownloadIcon,
  EditIcon,
  LockOpenIcon,
  MergeIcon,
  MoreVertIcon,
  RepostIcon,
  SubscribedIcon,
  TickIcon,
  TrashIcon,
  WinnerIcon,
} from 'icons'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, {
  forwardRef,
  MouseEvent,
  PropsWithoutRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import { DataRow, Props, StatusType } from './Datatable.types'

export type DatatableStateHandle = {
  deselectAll: () => void
}

export const Datatable = forwardRef<
  DatatableStateHandle,
  PropsWithoutRef<Props>
>(
  (
    {
      columns,
      data,
      size = 'medium',
      minWidth = '100%',
      maxWidth = '100%',
      maxHeight = '100%',
      isFooterAction,
      isFooterActionDisabled,
      isConsolidateButton,
      pagination,
      orderBy,
      order,
      onRequestSort,
      onSelect,
      onVerifyEmail,
      onAccept,
      onReject,
      onDelete,
      onRepost,
      onClose,
      onEditLot,
      onActionLabel,
      dropdownItems,
      stickyHeader = false,
      onRowClick,
      isCheckbox,
      tdSx = {},
      loading,
      selectWinner,
      withSorting = true,
      isBidsheetTable = false,
    },
    ref
  ) => {
    const router = useRouter()
    const { user } = useUserContext()
    const isEnabled = user?.isEnabled

    const [selectedItems, setSelectedItems] = useState<DataRow[]>([])
    const rowCount = data?.rows.length ?? 0

    useImperativeHandle(ref, () => ({
      deselectAll: () => {
        setSelectedItems([])
      },
    }))

    const handleSelectAll = (selectAll: boolean): void => {
      if (!data?.rows) {
        return
      }

      setSelectedItems(
        selectAll ? data.rows.filter(row => !row.isCheckboxDisabled) : []
      )
    }

    const handleSelectItem = (itemIndex: number) => {
      if (!data?.rows) {
        return
      }

      setSelectedItems(currentSelectedItems => {
        const item = data.rows[itemIndex]
        const index = currentSelectedItems.findIndex(
          selectedItem => selectedItem.id === item.id
        )

        if (index === -1) {
          return [...currentSelectedItems, item]
        }

        return [
          ...currentSelectedItems.slice(0, index),
          ...currentSelectedItems.slice(index + 1),
        ]
      })
    }

    const createSortHandler =
      (property: string) => (event: React.MouseEvent<unknown>) => {
        if (onRequestSort) {
          onRequestSort(event, property)
        }
      }

    const handleRowClick = (id: string | number) => {
      if (onRowClick) {
        onRowClick(id)
      }
    }

    const [openNotice, setOpenNotice] = useState(false)

    const handleOpenNotice = (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      setOpenNotice(true)
    }
    const handleCloseNotice = () => {
      setOpenNotice(false)
    }

    useEffect(() => {
      if (onSelect) {
        onSelect(selectedItems)
      }
    }, [selectedItems.length])

    return (
      <Box
        sx={{
          background: 'white',
          borderRadius: '16px',
          filter:
            'drop-shadow(0px 0px 2px rgba(34, 34, 34, 0.16)) drop-shadow(0px 1px 2px rgba(34, 34, 34, 0.08))',
        }}
      >
        <Paper
          sx={{
            boxShadow: 'none',
            borderRadius: '16px',
            overflow: 'hidden',
            // for safari only
            '@media not all and (min-resolution:.001dpcm)': {
              '@supports (-webkit-appearance:none) and (stroke-color:transparent)':
                {
                  filter:
                    'drop-shadow(0px 0px 2px rgba(34, 34, 34, 0.16)) drop-shadow(0px 1px 2px rgba(34, 34, 34, 0.08))',
                },
            },
          }}
        >
          <TableContainer
            sx={{
              borderRadius: '16px',
              minWidth,
              maxWidth,
              maxHeight,
              overflow: 'overlay',
              // for chrome only
              '@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm)':
                {
                  '&::-webkit-scrollbar': {
                    backgroundColor: 'transparent',
                    width: 14,
                    height: 14,
                  },
                },
            }}
          >
            <Table stickyHeader={stickyHeader} size={size}>
              {loading ? (
                <TableBody>
                  <TableRow>
                    {columns.map(({ id, cellWidth = 'auto' }) => (
                      <TableCell
                        key={id}
                        sx={{
                          padding: '12px 8px 16px',
                          width: cellWidth,
                          '&:last-of-type': { pr: '24px' },
                          '&:first-of-type': { pl: '24px' },
                        }}
                      >
                        <Skeleton variant={'text'} />
                      </TableCell>
                    ))}
                  </TableRow>
                  {data?.rows?.map(({ cells, id }, i) => (
                    <TableRow
                      sx={{
                        position: 'relative',
                      }}
                      key={i}
                    >
                      {cells.map(({ cellWidth = 'auto' }, idx) => (
                        <TableCell
                          onClick={() => handleRowClick(id)}
                          key={idx}
                          sx={{
                            ...tdSx,
                            position: 'relative',
                            padding: '15px 8px',
                            '&:nth-last-of-type(2)': { pr: '24px' },
                            '&:first-of-type': { pl: '24px' },
                            width: cellWidth,
                          }}
                        >
                          <Skeleton variant={'text'} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <>
                  <TableHead>
                    <TableRow
                      sx={{
                        bgcolor: 'rgba(34, 34, 34, 0.04)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {isCheckbox && (
                        <TableCell
                          sx={{
                            p: '8px 0 8px 26px',
                            width: '60px',
                            bgcolor: stickyHeader
                              ? 'rgba(237, 237 ,237, 1)'
                              : 'inherit',
                          }}
                        >
                          <CheckboxCustom
                            label=''
                            disabled={
                              rowCount === 0 ||
                              data?.rows?.every(row => row.isCheckboxDisabled)
                            }
                            checked={
                              rowCount > 0 &&
                              selectedItems.length > 0 &&
                              selectedItems.length ===
                                data?.rows.filter(
                                  row => !row.isCheckboxDisabled
                                ).length
                            }
                            sx={{
                              '&.MuiFormControlLabel-root': {
                                mr: '0',
                              },
                            }}
                            onChange={(_, checked) => handleSelectAll(checked)}
                          />
                        </TableCell>
                      )}
                      {columns.map(
                        ({
                          title,
                          id,
                          textAlign = 'left',
                          cellWidth = 'auto',
                        }) => (
                          <TableCell
                            sortDirection={
                              orderBy === (id as string) ? order : false
                            }
                            key={id}
                            sx={{
                              padding: isBidsheetTable
                                ? '12px 16px 16px 8px'
                                : '12px 8px 16px',
                              fontWeight: 600,
                              textAlign,
                              width: cellWidth,
                              bgcolor: stickyHeader
                                ? 'rgba(237, 237 ,237, 1)'
                                : 'inherit',
                              '&:last-of-type': { pr: '24px' },
                              '&:first-of-type': { pl: '24px' },
                            }}
                          >
                            <TableSortLabel
                              active={orderBy === id}
                              direction={orderBy === id ? order : 'asc'}
                              onClick={createSortHandler(id as string)}
                              IconComponent={ArrowDownwardIcon}
                              sx={{
                                pointerEvents: withSorting ? 'inherit' : 'none',
                                '&:hover': {
                                  color: 'rgba(34, 34, 34, 1)',
                                  '& .MuiTableSortLabel-icon': {
                                    opacity: 1,
                                  },
                                },
                                '&:active': {
                                  color: 'rgba(34, 34, 34, 1)',
                                },
                                '&:focus': {
                                  color: 'rgba(34, 34, 34, 1)',
                                },
                                '&.Mui-active': {
                                  color: 'rgba(34, 34, 34, 1)',
                                  '& .MuiTableSortLabel-icon': {
                                    color: 'rgba(34, 34, 34, 1)',
                                  },
                                },
                                '& .MuiTableSortLabel-icon': {
                                  display: withSorting ? 'block' : 'none',
                                  position: 'absolute',
                                  width: '16px',
                                  height: '16px',
                                  right:
                                    textAlign === 'right' ? 'auto' : '-22px',
                                  left:
                                    textAlign === 'right' ? '-22px' : 'auto',
                                  top: '4px',
                                  color: 'rgba(34, 34, 34, 1)',
                                  transition: 'all 0.5s',
                                },
                              }}
                            >
                              {title}
                            </TableSortLabel>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.rows?.map(
                      (
                        {
                          cells,
                          isActionRequired,
                          showActions = false,
                          availableActions = [],
                          id,
                          isSuspended,
                          lotId,
                          linkTo,
                          isDisabled = false,
                          isCheckboxDisabled = false,
                          isGreen,
                        },
                        i
                      ) => (
                        <TableRow
                          // className={'active'}
                          sx={{
                            bgcolor: isActionRequired
                              ? '#feeeec'
                              : isDisabled
                              ? 'rgba(34, 34, 34, 0.04)'
                              : isGreen
                              ? 'rgba(0, 152, 22, 0.08)'
                              : 'transparent',
                            position: 'relative',
                            '&: hover': {
                              bgcolor: 'rgba(34, 34, 34, 0.04)',
                            },
                            '&: hover .actions': {
                              display: 'flex',
                            },
                            '&.active ': {
                              bgcolor: 'rgba(34, 34, 34, 0.04)',
                            },
                            '&.active .actions': {
                              display: 'flex',
                            },
                          }}
                          key={i}
                        >
                          {isCheckbox && (
                            <TableCell
                              sx={{ p: '8px 0 8px 26px', width: '60px' }}
                            >
                              <CheckboxCustom
                                label={''}
                                disabled={isCheckboxDisabled}
                                checked={selectedItems.some(
                                  selectedItem => selectedItem.id === id
                                )}
                                onChange={() => handleSelectItem(i)}
                              />
                            </TableCell>
                          )}
                          {cells.map(
                            (
                              {
                                title,
                                isStatus,
                                isLink,
                                linkAddress = '/',
                                textAlign = 'left',
                                isTextEllipsis = false,
                                maxCellWidth = '200px',
                                cellWidth = 'auto',
                                isConsolidatedOrder,
                                tooltipText = '',
                                consolidatedFrom,
                                withLockIcon,
                                isAction,
                              },
                              idx
                            ) => (
                              <TableCell
                                onClick={event => {
                                  const isClickedOnTooltipItem = (
                                    event.target as Element
                                  ).classList.contains('tooltip-item')
                                  if (isClickedOnTooltipItem) {
                                    return
                                  }

                                  handleRowClick(id)
                                }}
                                key={idx}
                                sx={{
                                  ...tdSx,
                                  position: 'relative',
                                  padding: isBidsheetTable
                                    ? '15px 16px 15px 8px'
                                    : '15px 8px',
                                  whiteSpace: 'nowrap',
                                  cursor: onRowClick ? 'pointer' : 'default',
                                  textAlign: textAlign,
                                  color: isDisabled
                                    ? 'rgba(34, 34, 34, 0.48)'
                                    : 'rgba(34, 34, 34, 1)',
                                  '&:nth-last-of-type(2)': { pr: '24px' },
                                  '&:first-of-type': { pl: '24px' },
                                  width: cellWidth,
                                  ...(Boolean(isTextEllipsis) && {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: maxCellWidth,
                                    width: maxCellWidth,
                                    minWidth: maxCellWidth,
                                  }),
                                  ...(Boolean(isStatus) && {
                                    '>p': {
                                      ml: textAlign === 'right' ? 'auto' : '',
                                    },
                                  }),
                                  '> a': {
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    opacity: 0,
                                    left: 0,
                                    top: 0,
                                    overflow: 'hidden',
                                  },
                                }}
                              >
                                {linkTo && <Link href={linkTo}>{linkTo}</Link>}
                                {withLockIcon ? (
                                  <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    spacing={'4px'}
                                  >
                                    <Typography variant={'body2'}>
                                      {title}
                                    </Typography>
                                    <LockOpenIcon
                                      width={20}
                                      height={20}
                                      viewBox='0 0 24 24'
                                      color='rgba(0, 152, 22, 1)'
                                    />
                                  </Stack>
                                ) : isAction ? (
                                  <LabelButton
                                    onClick={() => onActionLabel?.(id)}
                                  >
                                    Select buyer
                                  </LabelButton>
                                ) : isStatus ? (
                                  isConsolidatedOrder ? (
                                    <Box onClick={handleOpenNotice}>
                                      <StatusField
                                        title={title as StatusType}
                                      />
                                    </Box>
                                  ) : (
                                    <StatusField title={title as StatusType} />
                                  )
                                ) : isLink ? (
                                  <Box
                                    onClick={event => {
                                      event.stopPropagation()
                                      void router.push(linkAddress)
                                    }}
                                  >
                                    <LinkedText
                                      fontSize={'14px'}
                                      marginLeft={0}
                                      to={linkAddress}
                                    >
                                      {title == '0' ? (
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '7px',
                                            height: '1px',
                                            background: 'rgba(34, 34, 34, 1)',
                                          }}
                                        />
                                      ) : (
                                        title
                                      )}
                                    </LinkedText>
                                  </Box>
                                ) : isConsolidatedOrder ? (
                                  <Tooltip
                                    placement={'bottom-start'}
                                    title={
                                      <Stack p={'12px 8px'} spacing={'8px'}>
                                        <Typography
                                          mb={'7px'}
                                          variant={'body2'}
                                        >
                                          Consolidated from:
                                        </Typography>
                                        {!!consolidatedFrom &&
                                          consolidatedFrom?.map(orderId => (
                                            <LinkedText
                                              className='tooltip-item'
                                              key={orderId}
                                              to={`/orders/${orderId}`}
                                            >
                                              #{orderId}
                                            </LinkedText>
                                          ))}
                                      </Stack>
                                    }
                                  >
                                    <Stack
                                      direction={'row'}
                                      alignItems={'center'}
                                      spacing={'4px'}
                                    >
                                      <MergeIcon
                                        width={20}
                                        height={20}
                                        viewBox='0 0 24 24'
                                        color='rgba(166, 0, 244, 1)'
                                      />
                                      <Typography variant={'body2'}>
                                        {title}
                                      </Typography>
                                    </Stack>
                                  </Tooltip>
                                ) : ['number', 'string'].includes(
                                    typeof title
                                  ) ? (
                                  <TooltipForEllipsis
                                    title={String(title)}
                                    tooltipText={tooltipText || String(title)}
                                    forEllipsis={!tooltipText}
                                    sx={{ fontSize: '14px' }}
                                  />
                                ) : (
                                  title
                                )}
                              </TableCell>
                            )
                          )}
                          <TableCell
                            padding='none'
                            sx={{ position: 'relative' }}
                          >
                            {!!availableActions.length &&
                              showActions &&
                              isEnabled && (
                                <Box
                                  className='actions'
                                  sx={{
                                    display: 'none',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    minWidth: '240px',
                                    background:
                                      'linear-gradient(270deg, #F6F6F6 90.06%, rgba(246, 246, 246, 0) 96%)',
                                    position: 'absolute',
                                    height: '100%',
                                    right: 0,
                                    top: 0,
                                  }}
                                >
                                  {availableActions.includes('verifyEmail') && (
                                    <ActionButton
                                      Icon={
                                        <SubscribedIcon
                                          width={24}
                                          height={24}
                                          color='rgba(34, 34, 34, 1)'
                                        />
                                      }
                                      onClick={() => onVerifyEmail?.(id)}
                                      tooltipTitle='Verify email'
                                    />
                                  )}
                                  {availableActions.includes('accept') &&
                                    isActionRequired && (
                                      <ActionButton
                                        Icon={
                                          <TickIcon
                                            width={24}
                                            height={24}
                                            color='rgba(34, 34, 34, 1)'
                                          />
                                        }
                                        onClick={() => onAccept?.(id)}
                                        tooltipTitle='Accept'
                                      />
                                    )}
                                  {availableActions.includes(
                                    'selectWinner'
                                  ) && (
                                    <ActionButton
                                      Icon={
                                        <WinnerIcon
                                          width={24}
                                          height={24}
                                          color='rgba(34, 34, 34, 1)'
                                        />
                                      }
                                      onClick={() => selectWinner?.(id)}
                                      tooltipTitle='Winner'
                                    />
                                  )}
                                  {availableActions.includes('close') && (
                                    <ActionButton
                                      Icon={
                                        <ClearIcon
                                          width={24}
                                          height={24}
                                          color='rgba(34, 34, 34, 1)'
                                        />
                                      }
                                      onClick={() => onClose?.(id)}
                                      tooltipTitle='Close'
                                    />
                                  )}
                                  {availableActions.includes('reject') &&
                                    isActionRequired && (
                                      <ActionButton
                                        Icon={
                                          <ClearIcon
                                            width={24}
                                            height={24}
                                            color='rgba(34, 34, 34, 1)'
                                          />
                                        }
                                        onClick={() => onReject?.(id)}
                                        tooltipTitle='Reject'
                                      />
                                    )}
                                  {availableActions.includes('delete') && (
                                    <ActionButton
                                      Icon={
                                        <TrashIcon
                                          width={24}
                                          height={24}
                                          color='rgba(34, 34, 34, 1)'
                                        />
                                      }
                                      onClick={() => onDelete?.(id)}
                                      tooltipTitle='Delete'
                                    />
                                  )}
                                  {availableActions.includes(
                                    'repostAuction'
                                  ) && (
                                    <ActionButton
                                      id={id}
                                      onClick={() => onRepost?.(id, lotId)}
                                      Icon={
                                        <RepostIcon
                                          width={24}
                                          height={24}
                                          color='rgba(34, 34, 34, 1)'
                                        />
                                      }
                                      tooltipTitle='Repost'
                                    />
                                  )}
                                  {availableActions.includes('edit') &&
                                    dropdownItems?.length && (
                                      <ActionButton
                                        isDropdown
                                        id={id}
                                        dropdownItems={dropdownItems}
                                        Icon={
                                          <EditIcon
                                            width={24}
                                            height={24}
                                            color='rgba(34, 34, 34, 1)'
                                          />
                                        }
                                        tooltipTitle='Edit'
                                      />
                                    )}
                                  {availableActions.includes('deleteLot') && (
                                    <ActionButton
                                      Icon={
                                        <TrashIcon
                                          width={24}
                                          height={24}
                                          color='rgba(34, 34, 34, 1)'
                                        />
                                      }
                                      onClick={() => onDelete?.(id)}
                                      tooltipTitle='Delete'
                                    />
                                  )}
                                  {availableActions.includes('editLot') && (
                                    <ActionButton
                                      onClick={() => onEditLot?.(id)}
                                      id={id}
                                      Icon={
                                        <EditIcon
                                          width={24}
                                          height={24}
                                          color='rgba(34, 34, 34, 1)'
                                        />
                                      }
                                      tooltipTitle='Edit'
                                    />
                                  )}
                                  {availableActions.includes('publishLot') && (
                                    <ActionButton
                                      id={id}
                                      Icon={
                                        <AuctionIcon
                                          width={24}
                                          height={24}
                                          color='rgba(34, 34, 34, 1)'
                                        />
                                      }
                                      tooltipTitle='Publish'
                                      onClick={() =>
                                        router.push(`/lots/${id}/publish`)
                                      }
                                    />
                                  )}
                                  {availableActions.includes('adminEdit') &&
                                    !!dropdownItems?.length && (
                                      <ActionButton
                                        isDropdown
                                        id={id}
                                        dropdownItems={dropdownItems.filter(
                                          ({
                                            isDisabledForUnverified,
                                            title,
                                          }) => {
                                            if (isActionRequired) {
                                              return !isDisabledForUnverified
                                            } else {
                                              if (
                                                (isSuspended &&
                                                  title === 'Suspend') ||
                                                (!isSuspended &&
                                                  title === 'Unsuspend')
                                              ) {
                                                return false
                                              }
                                            }
                                            return true
                                          }
                                        )}
                                        Icon={
                                          <MoreVertIcon
                                            width={24}
                                            height={24}
                                            color='rgba(34, 34, 34, 1)'
                                          />
                                        }
                                      />
                                    )}
                                </Box>
                              )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
          {isFooterAction && (
            <Box
              p={isConsolidateButton ? '16px' : '8px'}
              textAlign={'center'}
              position={'sticky'}
              sx={{
                bottom: 1,
                // left: 0,
                // background: 'white',
                borderTop: '1px solid rgba(228, 228, 228, 1)',
                boxSizing: 'border-box',
              }}
            >
              {isConsolidateButton ? (
                <ButtonCustom
                  width={'auto'}
                  height={'40px'}
                  fontSize={'16px'}
                  onClick={isFooterAction}
                  disabled={isFooterActionDisabled}
                >
                  Consolidate orders
                </ButtonCustom>
              ) : (
                <ButtonWithIcon
                  Icon={
                    <DownloadIcon
                      width={24}
                      height={24}
                      color='rgba(34, 34, 34, 1)'
                    />
                  }
                  title={'Download bidsheet'}
                  size={'small'}
                  variant={'text'}
                  onClick={isFooterAction}
                  sx={
                    {
                      // position: 'absolute',
                    }
                  }
                />
              )}
            </Box>
          )}
          {pagination && (
            <TablePagination
              rowsPerPageOptions={pagination.rowsPerPageOptions}
              component='div'
              count={pagination.count}
              rowsPerPage={pagination.rowsPerPage}
              page={pagination.page}
              onPageChange={pagination.handleChangePage}
              onRowsPerPageChange={pagination.handleChangeRowsPerPage}
            />
          )}
          <ModalCustom
            open={openNotice}
            onCloseClick={handleCloseNotice}
            isCancelButton={false}
            acceptText={'Got it'}
            onAcceptClick={handleCloseNotice}
            title={'Notice'}
          >
            <Typography>
              You can notify or tell user about something this way
            </Typography>
          </ModalCustom>
        </Paper>
      </Box>
    )
  }
)
