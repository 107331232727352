import { axiosExtended } from 'api/axios'

import { getDownloadAuctionTemplatesUrl } from '../urls'
import {
  DownloadAuctionTemplatesData,
  DownloadAuctionTemplatesResponse,
} from './downloadAuctionTemplates.types'

export const downloadAuctionTemplates = async (
  data: DownloadAuctionTemplatesData
) => {
  const { href } = getDownloadAuctionTemplatesUrl(data)
  const response = await axiosExtended.get<DownloadAuctionTemplatesResponse>(
    href,
    {
      withAuth: true,
      responseType: 'blob',
    }
  )
  return response.data
}
