import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const BigWarningIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32 54.666c12.519 0 22.667-10.148 22.667-22.666C54.667 19.48 44.519 9.333 32 9.333 19.482 9.333 9.333 19.481 9.333 32c0 12.518 10.149 22.666 22.667 22.666Zm0 4c14.728 0 26.667-11.939 26.667-26.666 0-14.728-11.94-26.667-26.667-26.667C17.272 5.333 5.333 17.273 5.333 32c0 14.727 11.94 26.666 26.667 26.666Z'
      fill='currentColor'
    />
    <path d='M30 35V19h4v16h-4ZM30 45v-5h4v5h-4Z' fill='currentColor' />
  </SvgWrapper>
)
