import { axiosExtended } from 'api/axios'

import { getCreateAdminBankAccountUrl } from '../urls'
import {
  CreateAdminBankAccountData,
  CreateAdminBankAccountResponse,
} from './createAdminBankAccount.types'

export const createAdminBankAccount = async (
  data: CreateAdminBankAccountData
) => {
  const { href } = getCreateAdminBankAccountUrl()
  const response = await axiosExtended.post<CreateAdminBankAccountResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
