import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const LanguageIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.14 19.893c4.36-3.392 5.144-9.675 1.752-14.033C16.501 1.5 10.218.717 5.86 4.108 1.5 7.5.716 13.782 4.108 18.141c3.391 4.36 9.674 5.143 14.033 1.752zm-9.059-4.355c1.886 2.193 3.775 3.41 5.365 4.082a8.01 8.01 0 0 1-8.097-1.956l2.732-2.126zm1.581-1.23c1.989 2.288 3.91 3.293 5.293 3.743.346.112.663.192.945.248a8.204 8.204 0 0 0-.008-.977c-.096-1.452-.598-3.561-2.326-6.051l-3.904 3.037zm2.676-4.616L9.434 12.73c-1.728-2.49-2.23-4.6-2.326-6.05a8.22 8.22 0 0 1-.008-.978c.282.056.599.136.945.248 1.383.45 3.304 1.455 5.293 3.742zm2.809.35c1.662 2.365 2.377 4.496 2.637 6.202a8.009 8.009 0 0 0 .095-8.329l-2.732 2.126zm1.504-3.705-2.733 2.126c-1.885-2.193-3.774-3.41-5.364-4.082a8.009 8.009 0 0 1 8.097 1.956zM7.853 13.96l-2.732 2.126a8.009 8.009 0 0 1 .095-8.33c.26 1.707.975 3.838 2.637 6.204z'
      fill='currentColor'
    />
  </SvgWrapper>
)
