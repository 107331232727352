import { axiosExtended } from 'api/axios'

import { getDeleteUserFile } from '../urls'
import {
  DeleteUserFileData,
  DeleteUserFileResponse,
} from './deleteUserFile.types'

export const deleteUserFile = async (query: DeleteUserFileData) => {
  const { href } = getDeleteUserFile(query)
  const response = await axiosExtended.delete<DeleteUserFileResponse>(href, {
    withAuth: true,
  })
  return response.data
}
