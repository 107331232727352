import { axiosExtended } from 'api/axios'

import { getGetShippingCarrierListUrl } from '../urls'
import { GetShippingCarrierListResponse } from './getShippingCarrierList.types'

export const getShippingCarrierList = async () => {
  const { href } = getGetShippingCarrierListUrl()
  const response = await axiosExtended.get<GetShippingCarrierListResponse>(
    href,
    {
      withAuth: true,
    }
  )
  return response.data
}
