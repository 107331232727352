import { axiosExtended } from 'api/axios'

import { getAuctionListAsAdminUrl } from '../urls'
import {
  GetAuctionListAsAdminData,
  GetAuctionListResponse,
} from './getAuctionListAsAdmin.types'

export const getAuctionListAsAdmin = async (
  query?: GetAuctionListAsAdminData
) => {
  const { href } = getAuctionListAsAdminUrl(query ? query : undefined)
  const response = await axiosExtended.get<GetAuctionListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
