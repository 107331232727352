import { axiosExtended } from 'api/axios'

import { getRepostAuctionUrl } from '../urls'
import { RepostAuctionData, RepostAuctionResponse } from './repostAuction.types'

export const repostAuction = async (data: RepostAuctionData) => {
  const { href } = getRepostAuctionUrl()
  const response = await axiosExtended.post<RepostAuctionResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
