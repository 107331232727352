import { ChangeEvent, useRef, useState } from 'react'
import { validateFiles } from 'utils/file'

export const useLotImagesUpload = () => {
  const [photos, setPhotos] = useState<File[]>([])
  const [imagesError, setImagesError] = useState(false)
  const [imagesLengthError, setImagesLengthError] = useState(false)
  const imagesUploaderRef = useRef<HTMLInputElement>(null)
  const imagesUploaderMoreRef = useRef<HTMLInputElement>(null)

  const resetImages = () => {
    if (imagesUploaderRef?.current?.files) {
      imagesUploaderRef.current.files = null
    }
    if (imagesUploaderMoreRef?.current?.files) {
      imagesUploaderMoreRef.current.files = null
    }
  }

  const handleUploadImages = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files ?? [])
    if (!files.length) {
      return
    }

    const { status, data, isValidFileLength } = validateFiles(
      files,
      photos,
      6,
      10485760
    )
    if (status === 'success') {
      setPhotos(data)
      setImagesError(false)
      setImagesLengthError(false)
    } else {
      if (!isValidFileLength) {
        setImagesLengthError(true)
      } else {
        setImagesError(true)
      }
      resetImages()
    }
  }

  return {
    photos,
    setPhotos,
    imagesError,
    setImagesError,
    imagesLengthError,
    setImagesLengthError,
    imagesUploaderRef,
    imagesUploaderMoreRef,
    resetImages,
    handleUploadImages,
  }
}
