import { axiosExtended } from 'api/axios'

import { getAddSubscriptionUrl } from '../urls'
import {
  AddSubscriptionData,
  AddSubscriptionResponse,
} from './addSubscription.types'

export const addSubscription = async (data: AddSubscriptionData) => {
  const { href } = getAddSubscriptionUrl()
  const response = await axiosExtended.post<AddSubscriptionResponse>(
    href,
    data,
    { withAuth: true }
  )
  return response.data
}
