import { axiosExtended } from 'api/axios'

import { getUserPackageAndShippingSettingsUrl } from '../urls'
import { GetUserPackageAndShippingSettingsResponse } from './getUserPackageAndShippingSettings.types'

export const getUserPackageAndShippingSettings = async () => {
  const { href } = getUserPackageAndShippingSettingsUrl()
  const response =
    await axiosExtended.get<GetUserPackageAndShippingSettingsResponse>(href, {
      withAuth: true,
    })
  return response.data
}
