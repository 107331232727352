import { axiosExtended } from 'api/axios'

import { getConfirmEmailUrl } from '../urls'
import { ConfirmEmailData, ConfirmEmailResponse } from './confirmEmail.types'

export const confirmEmail = async (data: ConfirmEmailData) => {
  const { href } = getConfirmEmailUrl()
  const response = await axiosExtended.post<ConfirmEmailResponse>(href, data)
  return response.data
}
