import { yupResolver } from '@hookform/resolvers/yup'
import {
  createLocation,
  editLocation,
  getLocationList,
  LocationResponse,
} from 'api/requests/location'
import { getLocation } from 'api/requests/location'
import {
  getSubscriptionList,
  GetSubscriptionListResponse,
} from 'api/requests/subscriptions'
import {
  getStaffUserList,
  inviteUser,
  removeStaffUser,
} from 'api/requests/team'
import {
  getUserPackageAndShippingSettings,
  getUserSettings,
  setUserPackageAndShippingSettings,
  setUserSettings,
} from 'api/requests/user'
import {
  validationBusinessSchema,
  validationBuyerLocationSchema,
  validationEmailSchema,
  validationLocationSchema,
  validationPasswordSchema,
} from 'constants/settings'
import { useRouter } from 'next/router'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { UserRoles } from 'types/enums'
import { UserDeletionStatuses } from 'types/enums'
import { Location } from 'types/location'
import {
  BusinessFormInput,
  EmailFormInput,
  PackageAndShippingSettings,
  PasswordFormInput,
  RatingInfo,
  SettingsState,
} from 'types/settings'
import { DayOfWeek, ScheduleByDay } from 'types/shared'
import { User } from 'types/user'
import { validateFiles } from 'utils/file'
import { formatAmPmTo24, formatTimeToAmPm } from 'utils/locations'

import { getRatingInfo } from '../api/requests/rating'
import { resendInvite } from '../api/requests/team/resendInvite'
import { InviteMember } from '../types/member'
import { useDeliveryLocations } from './useDeliveryLocations'
import { useLocationForm } from './useLocationForm'

export const useSettings = (fetchUserData = true) => {
  const {
    LocationControl,
    LocationState,
    LocationReset,
    LocationSetValue,
    LocationGetValues,
    SchedulesControl,
    SchedulesState,
    SchedulesReset,
    SchedulesSetValue,
    SchedulesGetValues,
    SchedulesWatch,
    handleLocationSubmit,
  } = useLocationForm()

  const [state, setState] = useState<SettingsState>({
    tab: '1',
    files: [],
    error: false,
    isSeller: false,
    isAdmin: false,
    isBuyer: false,
    documents: [],
  })
  const router = useRouter()
  const [isUserSettingsLoading, setIsUserSettingsLoading] =
    useState<boolean>(true)
  const [isPackAndShippingLoading, setIsPackAndShippingLoading] =
    useState<boolean>(true)
  const [locationItemData, setLocationItemData] = useState<
    LocationResponse | undefined
  >()
  const [subscriptions, setSubscriptions] =
    useState<GetSubscriptionListResponse>([])
  const [requestToBeDeleted, setRequestToBeDeleted] = useState(false)
  const [deletedAccount, setDeletedAccount] = useState(true)
  const [ratingInfo, setRatingInfo] = useState<RatingInfo | undefined>()

  const { locations, fetchLocationList } = useDeliveryLocations()
  const [openLocation, setOpenLocation] = useState(false)
  const [editLocationId, setEditLocationId] = useState<number | null>(null)

  const [members, setMembers] = useState<User[]>([])
  const [openMember, setOpenMember] = useState(false)

  const {
    control: PackAndShippingControl,
    formState: PackAndShippingState,
    setValue: PackAndShippingSetValue,
    getValues: PackAndShippingGetValues,
  } = useForm<PackageAndShippingSettings>({
    mode: 'all',
    defaultValues: {
      isConsolidatedShipping: false,
      pickupTime: 0,
    },
  })

  const {
    control: UserSettingEmailControl,
    formState: UserSettingEmailState,
    setValue: UserSettingEmailSetValue,
    getValues: UserSettingEmailGetValues,
  } = useForm<EmailFormInput>({
    mode: 'all',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    resolver: yupResolver(validationEmailSchema),
  })

  const {
    control: UserSettingPasswordControl,
    formState: UserSettingPasswordState,
    getValues: UserSettingPasswordGetValues,
  } = useForm<PasswordFormInput>({
    mode: 'all',
    defaultValues: {
      password: '',
      newPassword: '',
      repeatPassword: '',
    },
    resolver: yupResolver(validationPasswordSchema),
  })

  const {
    control: UserSettingBusinessControl,
    formState: UserSettingBusinessState,
    setValue: UserSettingBusinessSetValue,
    getValues: UserSettingBusinessGetValues,
  } = useForm<BusinessFormInput>({
    mode: 'all',
    defaultValues: {
      businessName: '',
      address: '',
      apartment: '',
      city: '',
      country: '',
      state: '',
      zip: '',
      phoneNumber: '',
    },
    resolver: yupResolver(validationBusinessSchema),
  })

  const {
    control: BuyerLocationControl,
    formState: BuyerLocationState,
    setValue: BuyerLocationSetValue,
    getValues: BuyerLocationGetValues,
    reset: BuyerLocationReset,
  } = useForm<Location>({
    mode: 'all',
    defaultValues: {
      name: '',
      address: '',
      apartment: '',
      city: '',
      state: '',
      contactPhoneNumber: '',
      country: '',
      zip: '',
      isDefault: false,
    },
    resolver: yupResolver(validationBuyerLocationSchema),
  })

  const isFirstStepSubmitValid =
    UserSettingEmailState.isValid &&
    (!UserSettingPasswordState.isDirty || UserSettingPasswordState.isValid)

  const fetchUserSettings = async () => {
    try {
      setIsUserSettingsLoading(true)
      const userSettings = await getUserSettings()
      await getLocationList()

      const userSettingsData = userSettings.data

      if (userSettingsData) {
        setRequestToBeDeleted(
          userSettingsData.deletionStatus === UserDeletionStatuses.Pending
        )
        setDeletedAccount(
          userSettingsData.deletionStatus === UserDeletionStatuses.Deleted
        )
        UserSettingEmailSetValue('firstName', userSettingsData.firstName, {
          shouldValidate: true,
        })
        UserSettingEmailSetValue('lastName', userSettingsData.lastName, {
          shouldValidate: true,
        })
        UserSettingEmailSetValue('email', userSettingsData.email, {
          shouldValidate: true,
        })

        UserSettingBusinessSetValue(
          'businessName',
          userSettingsData.businessName,
          {
            shouldValidate: true,
          }
        )
        UserSettingBusinessSetValue(
          'businessCountry',
          userSettingsData.businessCountry,
          {
            shouldValidate: true,
          }
        )
        UserSettingBusinessSetValue('city', userSettingsData.city, {
          shouldValidate: true,
        })
        UserSettingBusinessSetValue('state', userSettingsData.state, {
          shouldValidate: true,
        })
        UserSettingBusinessSetValue(
          'phoneNumber',
          userSettingsData.phoneNumber,
          {
            shouldValidate: true,
          }
        )
        UserSettingBusinessSetValue('apartment', userSettingsData.apartment, {
          shouldValidate: true,
        })
        UserSettingBusinessSetValue('address', userSettingsData.address, {
          shouldValidate: true,
        })
        UserSettingBusinessSetValue('zip', userSettingsData.zip, {
          shouldValidate: true,
        })
        UserSettingBusinessSetValue('country', userSettingsData.country, {
          shouldValidate: true,
        })
        UserSettingBusinessSetValue(
          'primaryContactPersonRole',
          userSettingsData.primaryContactPersonRole,
          { shouldValidate: true }
        )
        UserSettingBusinessSetValue('fullName', userSettingsData.fullName, {
          shouldValidate: true,
        })
      }

      if (userSettingsData) {
        const isSeller = !!userSettingsData.roles.find(
          role => role.code.toString() === UserRoles.Seller
        )
        const isAdmin = !!userSettingsData.roles.find(
          role => role.code.toString() === UserRoles.Admin
        )
        const isBuyer = !!userSettingsData.roles.find(
          role => role.code.toString() === UserRoles.Buyer
        )

        setState(currentState => ({
          ...currentState,
          isSeller,
          isAdmin,
          isBuyer,
          isOwner: !userSettingsData.ownerId && !isAdmin,
          documents: Array.isArray(userSettingsData.userDocuments)
            ? userSettingsData.userDocuments
            : [userSettingsData.userDocuments],
        }))
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsUserSettingsLoading(false)
    }
  }

  const fetchPackageAndShippingSettings = async () => {
    try {
      setIsPackAndShippingLoading(true)
      const packageAndShippingSettings =
        await getUserPackageAndShippingSettings()
      const packageAndShippingData = packageAndShippingSettings.data

      if (packageAndShippingData) {
        PackAndShippingSetValue(
          'isConsolidatedShipping',
          packageAndShippingData.isConsolidatedShipping
        )
        PackAndShippingSetValue('pickupTime', packageAndShippingData.pickupTime)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsPackAndShippingLoading(false)
    }
  }

  const fetchRatingInfo = async () => {
    try {
      const response = await getRatingInfo()
      if (response.data) {
        setRatingInfo(response.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchTeamMembers = async () => {
    try {
      const response = await getStaffUserList()
      if (response.data) {
        setMembers(response.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (!fetchUserData) {
      return
    }

    void fetchUserSettings()
  }, [])

  useEffect(() => {
    if (locationItemData) {
      const { pickupSchedules } = locationItemData
      Object.keys(locationItemData).forEach(key => {
        if (
          key !== 'createdAt' &&
          key !== 'updatedAt' &&
          key !== 'userId' &&
          key !== 'type' &&
          key !== 'pickupSchedules'
        ) {
          const currentKey = key as keyof Location

          if (state.isBuyer) {
            if (locationItemData[currentKey]) {
              BuyerLocationSetValue(currentKey, locationItemData[currentKey], {
                shouldValidate: true,
              })
            }
          } else {
            LocationSetValue(currentKey, locationItemData[currentKey], {
              shouldValidate: true,
            })
          }
        }
      })
      if (pickupSchedules && !state.isBuyer) {
        pickupSchedules.forEach(({ day, dayOff, endTime, startTime, id }) => {
          SchedulesSetValue(day, {
            day,
            dayOff,
            endTime: formatTimeToAmPm(endTime),
            startTime: formatTimeToAmPm(startTime),
            id,
          })
        })
      }
    }
  }, [locationItemData, state.isBuyer])

  useEffect(() => {
    if (state.isSeller) {
      void fetchRatingInfo()
    }
  }, [state.isSeller])

  useEffect(() => {
    if (state.isOwner) {
      void fetchTeamMembers()

      if (state.isSeller) {
        void fetchPackageAndShippingSettings()
      }
    }
  }, [state.isOwner])

  const handleFetchSubscriptions = async () => {
    try {
      const response = await getSubscriptionList()
      const data = response?.data

      if (data) {
        setSubscriptions(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleTabChange = async (event: React.SyntheticEvent, tab: string) => {
    setState(currentState => ({
      ...currentState,
      tab,
    }))
    await router.push({ pathname: '/settings', hash: tab })
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState(currentState => {
      const { status, data } = validateFiles(
        event.target.files ?? [],
        currentState.files
      )
      return {
        ...currentState,
        files: data,
        error: status !== 'success',
      }
    })
  }

  const handleRemoveFiles = (index: number) => {
    setState(currentState => ({
      ...currentState,
      files: currentState.files.filter(
        (_, currentIndex) => currentIndex !== index
      ),
    }))
  }

  const handleFirstStepSubmit = async () => {
    if (!isFirstStepSubmitValid) {
      return false
    }

    const submitData = {
      ...UserSettingEmailGetValues(),
      ...(UserSettingPasswordState.isDirty
        ? UserSettingPasswordGetValues()
        : {}),
    }

    await setUserSettings(submitData)
  }

  const handleSecondStepSubmit = async () => {
    const submitData = UserSettingBusinessGetValues()
    await setUserSettings(submitData)
  }

  const handleThirdStepSubmit = async () => {
    const submitData = PackAndShippingGetValues()
    await setUserPackageAndShippingSettings(submitData)
  }

  const prepareScheduleDay = (day: DayOfWeek, schedulesData: ScheduleByDay) => {
    return {
      id: schedulesData[day].id,
      startTime: formatAmPmTo24(schedulesData[day].startTime),
      endTime: formatAmPmTo24(schedulesData[day].endTime),
      dayOff: schedulesData[day].dayOff,
    }
  }

  const prepareLocationEditRequestData = () => {
    const locationData = LocationGetValues()
    const schedulesData = SchedulesGetValues()
    return {
      location: locationData,
      schedules: [
        prepareScheduleDay('sunday', schedulesData),
        prepareScheduleDay('monday', schedulesData),
        prepareScheduleDay('tuesday', schedulesData),
        prepareScheduleDay('wednesday', schedulesData),
        prepareScheduleDay('thursday', schedulesData),
        prepareScheduleDay('friday', schedulesData),
        prepareScheduleDay('saturday', schedulesData),
      ],
    }
  }

  const handleBuyerLocationSubmit = async () => {
    const locationData = BuyerLocationGetValues()
    await createLocation({
      location: locationData,
    })
    BuyerLocationReset()
  }

  const handleLocationEditSubmit = async (locationId: number) => {
    const requestData = prepareLocationEditRequestData()
    await editLocation({
      ...requestData,
      location: { ...requestData.location, id: locationId },
    })
    LocationReset()
    SchedulesReset()
  }

  const handleBuyerLocationEditSubmit = async (locationId: number) => {
    const locationData = BuyerLocationGetValues()
    await editLocation({
      location: { ...locationData, id: locationId },
    })
    BuyerLocationReset()
  }

  const makeLocationDefault = async (locationId: number) => {
    try {
      await editLocation({
        location: { id: locationId, isDefault: true },
      })
    } catch (error) {
      console.error(error)
    }
  }

  const fetchLocationItemData = async (id: number) => {
    try {
      const response = await getLocation({ id })
      const locationData = response?.data?.location
      setLocationItemData(locationData)
    } catch (e) {}
  }

  const handleCloseLocationClick = () => {
    setOpenLocation(false)
    setEditLocationId(null)
    LocationReset()
    BuyerLocationReset()
    SchedulesReset()
  }

  const handleAddLocationClick = () => {
    setEditLocationId(null)
    setOpenLocation(true)
  }

  const handleEditLocation = (id: number) => {
    setEditLocationId(id)
    setOpenLocation(true)
  }

  const handleLocationMakeDefault = async (locationId: number) => {
    try {
      await makeLocationDefault(locationId)
      await fetchLocationList()
    } catch (error) {
      console.error(error)
    }
  }

  const handleAcceptLocation = async () => {
    try {
      if (editLocationId) {
        await handleLocationEditSubmit(editLocationId)
        setEditLocationId(null)
      } else {
        await handleLocationSubmit()
      }
      setOpenLocation(false)
      await fetchLocationList()
    } catch (e) {
      console.error(e)
    }
  }

  const handleAcceptBuyerLocation = async () => {
    try {
      if (editLocationId) {
        await handleBuyerLocationEditSubmit(editLocationId)
        setEditLocationId(null)
      } else {
        toast.error(
          'Something went wrong! Please contact us to add new delivery locations.'
        )
      }
      setOpenLocation(false)
      await fetchLocationList()
    } catch (e) {
      console.error(e)
    }
  }

  const handleAddMember = async (data: InviteMember) => {
    try {
      await inviteUser(data)
      await fetchTeamMembers()
      setOpenMember(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDeleteMember = async (id: number) => {
    try {
      await removeStaffUser({ id })
      const filteredMembers = members.filter(member => member.id !== id)
      setMembers(filteredMembers)
    } catch (err) {
      console.log(err)
    }
  }

  const handleResendInvite = async (email: string) => {
    try {
      await resendInvite({ email })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const { asPath } = router
    const tab = asPath.split('#')[1]
    if (tab) {
      setState(currentState => ({
        ...currentState,
        tab,
      }))
    }
  }, [router])

  return {
    ...state,
    requestToBeDeleted,
    subscriptions,
    handleTabChange,
    handleFileChange,
    handleRemoveFiles,
    UserSettingEmailControl,
    UserSettingEmailState,
    UserSettingPasswordControl,
    UserSettingPasswordState,
    UserSettingBusinessControl,
    BuyerLocationControl,
    UserSettingBusinessState,
    BuyerLocationState,
    PackAndShippingControl,
    PackAndShippingState,
    LocationControl,
    LocationState,
    LocationReset,
    SchedulesState,
    SchedulesControl,
    SchedulesGetValues,
    SchedulesReset,
    SchedulesWatch,
    isFirstStepSubmitValid,
    handleFirstStepSubmit,
    handleSecondStepSubmit,
    handleThirdStepSubmit,
    handleLocationSubmit,
    handleBuyerLocationSubmit,
    handleFetchSubscriptions,
    isUserSettingsLoading,
    isPackAndShippingLoading,
    fetchLocationItemData,
    handleLocationEditSubmit,
    makeLocationDefault,
    fetchUserSettings,
    handleBuyerLocationEditSubmit,
    BuyerLocationReset,
    deletedAccount,
    ratingInfo,
    members,
    openLocation,
    editLocationId,
    locations,
    handleAddLocationClick,
    handleEditLocation,
    fetchLocationList,
    handleLocationMakeDefault,
    handleCloseLocationClick,
    handleAcceptLocation,
    handleAcceptBuyerLocation,
    openMember,
    setOpenMember,
    handleAddMember,
    handleDeleteMember,
    handleResendInvite,
  }
}
