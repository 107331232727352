import { axiosExtended } from 'api/axios'
import { serialize } from 'object-to-formdata'

import { getUploadShippingLabelUrl } from '../urls'
import { UploadShippingLabelData } from './uploadShippingLabel.types'

export const uploadShippingLabel = async (data: UploadShippingLabelData) => {
  const { href } = getUploadShippingLabelUrl()
  // TODO: replace "any" with real response' type
  const response = await axiosExtended.post<any>(href, serialize(data), {
    withAuth: true,
  })
  return response.data
}
