import { axiosExtended } from 'api/axios'

import { getAuctionTemplateListUrl } from '../urls'
import { GetAuctionTemplateListResponse } from './getAuctionTemplateList.types'

export const getAuctionTemplateList = async () => {
  const { href } = getAuctionTemplateListUrl()
  const response = await axiosExtended.get<GetAuctionTemplateListResponse>(
    href,
    { withAuth: true }
  )
  return response.data
}
