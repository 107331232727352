import { axiosExtended } from 'api/axios'

import { getAuctionTemplateRulesUrl } from '../urls'
import {
  GetAuctionTemplateRulesData,
  GetAuctionTemplateRulesResponse,
} from './getAuctionTemplateRules.types'

export const getAuctionTemplateRules = async (
  data: GetAuctionTemplateRulesData
) => {
  const { href } = getAuctionTemplateRulesUrl(data)
  const response = await axiosExtended.get<GetAuctionTemplateRulesResponse>(
    href,
    { withAuth: true }
  )
  return response.data
}
