import { axiosExtended } from 'api/axios'

import { getRemoveWatchlistAllUrl } from '../urls'
import { RemoveWatchlistAllResponse } from './removeWatchlistAll.types'

export const removeWatchlistAll = async () => {
  const { href } = getRemoveWatchlistAllUrl()
  const response = await axiosExtended.delete<RemoveWatchlistAllResponse>(
    href,
    {
      withAuth: true,
    }
  )
  return response.data
}
