import { Button, ButtonProps } from '@mui/material'
import React, { FC } from 'react'

export const LabelButton: FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => {
  return (
    <Button
      {...props}
      sx={{
        color: 'white',
        bgcolor: 'rgba(0, 152, 22, 1)',
        borderRadius: '13px',
        height: '26px',
        p: '0 8px',
        width: 'fit-content',
        fontSize: '14px',
        lineHeight: '26px',
        '&:hover': {
          bgcolor: 'rgba(0, 152, 22, 1)',
        },
        '&:active': {
          bgcolor: 'rgba(0, 152, 22, 1)',
        },
      }}
    >
      {children}
    </Button>
  )
}
