import { axiosExtended } from 'api/axios'

import { getPayoutListUrl } from '../urls'
import { GetPayoutListData, GetPayoutListResponse } from './getPayotList.types'

export const getPayoutList = async (query?: GetPayoutListData) => {
  const { href } = getPayoutListUrl(query)
  const response = await axiosExtended.get<GetPayoutListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
