import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const SettingsIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M16.655 6.061a7.806 7.806 0 0 0-.814-.55 7.8 7.8 0 0 0-.883-.43L14.5 2h-5l-.281 3.081c-.607.25-1.173.57-1.694.974L4.624 4.94l-2.5 4.33 2.53 1.756a7.683 7.683 0 0 0 .005 1.954l-2.435 1.94 2.5 4.33 2.804-1.3c.257.195.528.386.813.55.286.166.587.305.884.43l.363 3.077h5l.376-3.077a7.304 7.304 0 0 0 1.694-.974l2.786 1.314 2.5-4.33-2.416-1.955a7.68 7.68 0 0 0-.004-1.954l2.473-1.864-2.452-4.247-2.89 1.141Zm-2.47.86c.288.12.475.216.656.321.182.105.363.233.608.409l.908.686 1.052-.42 1.267-.516.698 1.211-1.077.845-.884.69.14 1.12c.061.532.064 1.008.006 1.448l-.157 1.133.898.703 1.07.848-.7 1.213-2.323-.937-.903.703c-.37.282-.77.514-1.23.71l-1.06.438-.158 1.133-.194 1.355-1.397.001-.188-1.355-.161-1.12-1.049-.445a8.042 8.042 0 0 1-.666-.326 7.641 7.641 0 0 1-.607-.409l-.908-.686-1.052.42-1.267.516-.698-1.211 1.077-.845.883-.69-.138-1.12a5.857 5.857 0 0 1-.008-1.447l.158-1.133-.899-.704-1.069-.848.695-1.204 2.323.937.902-.703c.371-.283.77-.514 1.23-.71l1.062-.438.157-1.133.193-1.355 1.398-.001.201 1.351.162 1.121 1.048.444Zm-5.558 3.084a3.999 3.999 0 0 0 1.464 5.465 3.999 3.999 0 0 0 5.465-1.465 3.999 3.999 0 0 0-1.464-5.464 3.999 3.999 0 0 0-5.465 1.464Zm5.196 3a2.006 2.006 0 0 1-2.732.733 2.006 2.006 0 0 1-.732-2.732 2.006 2.006 0 0 1 2.732-.732c.953.55 1.283 1.779.732 2.732Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
