import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const TrashIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V21H18V7Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
