import { axiosExtended } from 'api/axios'

import { getAuctionManufacturerListUrl } from '../urls'
import {
  GetAuctionManufacturerListData,
  GetAuctionManufacturerListResponse,
} from './getAuctionManufacturerList.types'

export const getAuctionManufacturerList = async (
  query?: Partial<GetAuctionManufacturerListData>
) => {
  const { href } = getAuctionManufacturerListUrl(query)
  const response = await axiosExtended.get<GetAuctionManufacturerListResponse>(
    href,
    { withAuth: true }
  )
  return response.data
}
