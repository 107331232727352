import { axiosExtended } from 'api/axios'

import { getTransactionUserListUrl } from '../urls'
import {
  GetTransactionUserListData,
  GetTransactionUserListResponse,
} from './getTransactionUserList.types'

export const getTransactionUserList = async (
  query?: GetTransactionUserListData
) => {
  const { href } = getTransactionUserListUrl(query)
  const response = await axiosExtended.get<GetTransactionUserListResponse>(
    href,
    {
      withAuth: true,
    }
  )
  return response.data
}
