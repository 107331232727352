import { axiosExtended } from 'api/axios'

import { getEditShippingDetailsUrl } from '../urls'
import {
  EditShippingDetailsData,
  EditShippingDetailsResponse,
} from './editShippingDetails.types'

export const editShippingDetails = async (data: EditShippingDetailsData) => {
  const { href } = getEditShippingDetailsUrl()
  const response = await axiosExtended.put<EditShippingDetailsResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
