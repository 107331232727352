import { axiosExtended } from 'api/axios'

import { getNotificationsListUrl } from '../urls'
import {
  GetNotificationsListData,
  GetNotificationsListResponse,
} from './getNotificationsList.types'

export const getNotificationsList = async (
  query?: GetNotificationsListData
) => {
  const { href } = getNotificationsListUrl(query)
  const response = await axiosExtended.get<GetNotificationsListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
