import { yupResolver } from '@hookform/resolvers/yup'
import { login } from 'api/requests/auth'
import { getCurrentUserInfo } from 'api/requests/user'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { UserRoles } from 'types/enums'
import { TokenStorage } from 'utils/auth'
import { emailRegex } from 'utils/regex'
import { getUserHomeUrlByRole } from 'utils/user'
import * as Yup from 'yup'

import { useUserContext } from './useUserContext'

interface IFormInput {
  email: string
  password: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email is required')
    .matches(emailRegex, 'Email is invalid'),
  password: Yup.string().trim().required('Password is required'),
})

export const useLogin = () => {
  const { setUser } = useUserContext()
  const router = useRouter()
  const redirectToUrl = router.query.redirectTo as string

  const { control, handleSubmit, getValues, formState, setFocus } =
    useForm<IFormInput>({
      mode: 'all',
      defaultValues: {
        email: '',
        password: '',
      },
      resolver: yupResolver(validationSchema),
    })

  const onSubmit = async (data: IFormInput) => {
    try {
      const response = await login(data)
      if (response.data) {
        const { accessToken, refreshToken } = response.data
        const tokenStorage = TokenStorage.getInstance()
        tokenStorage.setAccessToken(accessToken)
        tokenStorage.setRefreshToken(refreshToken)

        try {
          const result = await getCurrentUserInfo()
          const user = result.data
          if (!user) {
            return
          }

          setUser(user)

          await router.push(
            redirectToUrl &&
              user.roles.some(role => role.code === UserRoles.Buyer)
              ? redirectToUrl
              : getUserHomeUrlByRole(user?.roles[0].code).pathname
          )
        } catch (error) {
          console.error(error)
        }
      }
    } catch (error) {
      if (error.response.status === 403 && error.response?.data?.error) {
        switch (error.response.data.error) {
          case 'Your email address was not verified.':
            return router.push(
              `/account/register/confirm-email?email=${getValues('email')}`
            )
          case 'Your account is not verified yet.':
            return router.push('/account/verifying')
          case 'We could not verify your account at this time.':
            return router.push('/account/rejected')
          default:
            console.error(error)
            return
        }
      }
    }
  }

  return {
    control,
    handleSubmit,
    formState,
    setFocus,
    onSubmit,
  }
}
