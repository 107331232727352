import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const CopyIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M16 2H3v15h2V4h11V2ZM7 6v17h14V6H7Zm12 15H9V8h10v13Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
