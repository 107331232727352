import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker'
import {
  getPayoutList,
  GetPayoutListData,
  GetPayoutListResponse,
  getPayoutStatistic,
  GetPayoutStatisticResponse,
} from 'api/requests/payout'
import { TableData } from 'components/Datatable'
import { LinkedText } from 'components/LinkedText'
import { useOrdering } from 'hooks/useOrdering'
import { debounce } from 'lodash'
import { ChangeEvent, ChangeEventHandler, useCallback, useState } from 'react'
import { PayoutStatuses } from 'types/enums'
import { formatCurrency, formatDate } from 'utils/format'

export const usePayouts = () => {
  const [payouts, setPayouts] = useState<GetPayoutListResponse>()
  const [statistics, setStatistics] = useState<
    GetPayoutStatisticResponse | undefined
  >()
  const [payoutsData, setPayoutsData] = useState<TableData | undefined>()
  const [filter, setFilter] = useState('all')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)
  const [notEmpty, setNotEmpty] = useState<boolean | undefined>(false)
  const [total, setTotal] = useState(0)
  const [dateValues, setDateValues] = useState<DateRange<Date>>([null, null])
  const [finalDateValues, setFinalDateValues] = useState<DateRange<Date>>([
    null,
    null,
  ])

  const { order, orderBy, handleRequestSort } = useOrdering()

  const perPage = 25

  const fetchPayoutsList = async (query?: GetPayoutListData) => {
    try {
      const response = await getPayoutList(query)
      if (response?.data) {
        setPayouts(response.data)
        const totalPayouts = response.data.total
        const isNotEmpty = response?.data?.isNotEmpty
        const currentData = {
          rows: response?.data?.items?.map(
            ({
              id,
              createdAt,
              status,
              awaitingMoney,
              netAmount,
              salesAmount,
              fees,
              orders,
            }) => ({
              cells: [
                {
                  title: createdAt
                    ? formatDate(new Date(createdAt), 'MMMM dd, yyyy')
                    : 'N/A',
                  cellWidth: '27.5%',
                },
                {
                  title: orders
                    ? orders.map(order => (
                        <LinkedText key={order} to={`orders/${order}`}>
                          #{order}
                        </LinkedText>
                      ))
                    : '-',
                  isTextEllipsis: true,
                  maxCellWidth: '192px',
                },
                {
                  title: salesAmount
                    ? formatCurrency(salesAmount, { withPrefix: true })
                    : 'N/A',
                  cellWidth: '20.5%',
                },
                {
                  title: fees
                    ? formatCurrency(fees, { withPrefix: true })
                    : 'N/A',
                  cellWidth: '20.5%',
                },
                {
                  title: netAmount
                    ? formatCurrency(netAmount, { withPrefix: true })
                    : 'N/A',
                  cellWidth: '20.5%',
                },
                {
                  title: awaitingMoney ? PayoutStatuses.clearingFunds : status,
                  isStatus: true,
                  textAlign: 'right',
                },
              ],
              id,
              linkTo: `/payouts/${id}`,
            })
          ),
        }
        setPayoutsData(currentData)
        setCount(Math.ceil(totalPayouts / perPage) || 0)
        setNotEmpty(isNotEmpty)
        setTotal(totalPayouts)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const tabs = [
    { label: 'All', value: 'all' },
    { label: 'In progress', value: 'inProgress' },
    { label: 'Paid', value: 'paid' },
  ]

  const fetchPayoutStatistic = async () => {
    try {
      const response = await getPayoutStatistic()
      if (response?.data) {
        setStatistics(response.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleStatusChange = (value: string) => {
    setFilter(value)
  }

  const handlePayoutsSearchChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = event => {
    setSearch(event.target.value)
  }

  const handleClearSearchClick = () => {
    setSearch('')
  }

  const debouncedSearch = useCallback(debounce(fetchPayoutsList, 500), [])

  const preparePayoutsQueryAndFetch = async () => {
    const query: GetPayoutListData = {
      page,
      perPage,
    }
    if (search?.length) {
      query.orderId = search
    }
    if (filter && filter !== 'all') {
      query.status = filter as Exclude<
        PayoutStatuses,
        PayoutStatuses.clearingFunds
      >
    }
    if (order) {
      query.orderType = order
    }
    if (orderBy) {
      query.orderBy = orderBy
    }
    if (finalDateValues[0] && finalDateValues[1]) {
      const startDateTime = finalDateValues[0]
      const endDateTime = finalDateValues[1]
      startDateTime?.setHours(0, 0, 0, 0)
      endDateTime?.setHours(23, 59, 59, 59)

      query.startDate = startDateTime?.getTime()
      query.endDate = endDateTime?.getTime()
    }
    await debouncedSearch(query)
  }

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handlePayoutsStatusChange = (value: string) => {
    setFilter(value)
    setPage(1)
  }

  const handleCloseDateRange = () => {
    setDateValues(finalDateValues)
  }

  const handleAcceptDateRange = () => {
    setFinalDateValues(dateValues)
  }

  const handleClearDateRange = () => {
    setDateValues([null, null])
    setFinalDateValues([null, null])
  }

  const isShowAllButtonActive =
    filter !== 'all' || !!search.length || (!!dateValues[0] && !!dateValues[1])

  const handleClearAllFilters = () => {
    setFilter('all')
    setSearch('')
    setFinalDateValues([null, null])
    setDateValues([null, null])
  }

  return {
    fetchPayoutsList,
    fetchPayoutStatistic,
    statistics,
    payouts,
    payoutsData,
    filter,
    tabs,
    search,
    page,
    count,
    notEmpty,
    total,
    order,
    orderBy,
    dateValues,
    finalDateValues,
    handleClearDateRange,
    handleAcceptDateRange,
    handleCloseDateRange,
    setDateValues,
    handleRequestSort,
    handlePayoutsSearchChange,
    handleStatusChange,
    handleClearSearchClick,
    handleChangePage,
    handlePayoutsStatusChange,
    handleClearAllFilters,
    preparePayoutsQueryAndFetch,
    isShowAllButtonActive,
  }
}
