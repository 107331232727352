import { axiosExtended } from 'api/axios'

import { getDeleteLotUrl } from '../urls'
import { DeleteLotData, DeleteLotResponse } from './deleteLot.types'

export const deleteLot = async (data: DeleteLotData) => {
  const { href } = getDeleteLotUrl(data)
  const response = await axiosExtended.delete<DeleteLotResponse>(href, {
    withAuth: true,
  })
  return response.data
}
