import { axiosExtended } from 'api/axios'

import { getAuctionLotSizeUrl } from '../urls'
import { GetAuctionLotSizeResponse } from './getAuctionLotSize.types'

export const getAuctionLotSize = async () => {
  const { href } = getAuctionLotSizeUrl()
  const response = await axiosExtended.get<GetAuctionLotSizeResponse>(href, {
    withAuth: true,
  })
  return response.data
}
