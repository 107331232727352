import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { GetNotificationsListData } from './getNotificationsList'
import { ReadNotificationsData } from './readNotifications'

export const getNotificationsRulesListUrl = (): URL => {
  const url = `${API_URL}/notifications/rules/list`
  return new URL(url)
}

export const getChangeNotificationRuleUrl = (): URL => {
  const url = `${API_URL}/notifications/rule`
  return new URL(url)
}

export const putReadNotifications = (params?: ReadNotificationsData): URL => {
  const url = `${API_URL}/notifications/read/`
  if (!params) {
    return new URL(url)
  }

  const { id } = params
  return new URL(`${url}${id}`)
}

export const putNotificationRuleEmailUrl = (): URL => {
  const url = `${API_URL}/notifications/rule/emails`
  return new URL(url)
}

export const getNotificationsListUrl = (
  query?: GetNotificationsListData
): URL => {
  const url = `${API_URL}/notifications/list`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}
