import { axiosExtended } from 'api/axios'

import { getConditionsListUrl } from '../urls'
import {
  GetConditionsListData,
  GetConditionsListResponse,
} from './getConditionsList.types'

export const getConditionsList = async (query: GetConditionsListData) => {
  const { href } = getConditionsListUrl(query)
  const response = await axiosExtended.get<GetConditionsListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
