import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const ArrowForwardIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='m14 4-1.41 1.41L18.17 11H3v2h15.17l-5.59 5.59L14 20l8-8-8-8Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
