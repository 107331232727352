import { axiosExtended } from 'api/axios'

import { getUserSettingsUrl } from '../urls'
import { GetUserSettingsResponse } from './getUserSettings.types'

export const getUserSettings = async () => {
  const { href } = getUserSettingsUrl()
  const response = await axiosExtended.get<GetUserSettingsResponse>(href, {
    withAuth: true,
  })
  return response.data
}
