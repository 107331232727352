import browserCookies from 'browser-cookies'
import {
  ACCESS_TOKEN,
  LOGGED_IN_AS_USER_ACCESS_TOKEN,
  LOGGED_IN_AS_USER_REFRESH_TOKEN,
  PAYMENT_ACCESS_TOKEN,
  REFRESH_TOKEN,
} from 'constants/cookieKeys'
import { getCookie, setCookie } from 'utils/cookie'
import { isBrowser } from 'utils/environment'

// TokenStorage is a singleton(https://patterns.dev/posts/singleton-pattern/)
// Needed to pass tokens between the server side and the client side.
export class TokenStorage {
  private static instance: TokenStorage

  private accessToken = ''

  private refreshToken = ''

  private loggedInAsUserAccessToken = ''

  private loggedInAsUserRefreshToken = ''

  private paymentAccessToken = ''

  public static getInstance(): TokenStorage {
    if (!TokenStorage.instance) {
      TokenStorage.instance = new TokenStorage()

      if (isBrowser()) {
        TokenStorage.instance.setAccessToken(getCookie(ACCESS_TOKEN))
        TokenStorage.instance.setRefreshToken(getCookie(REFRESH_TOKEN))
        TokenStorage.instance.setLoggedInAsUserAccessToken(
          getCookie(LOGGED_IN_AS_USER_ACCESS_TOKEN)
        )
        TokenStorage.instance.setLoggedInAsUserRefreshToken(
          getCookie(LOGGED_IN_AS_USER_REFRESH_TOKEN)
        )
        TokenStorage.instance.setPaymentAccessToken(
          getCookie(PAYMENT_ACCESS_TOKEN)
        )
      }
    }

    return TokenStorage.instance
  }

  public getAccessToken() {
    return (this.loggedInAsUserAccessToken || this.accessToken) ?? ''
  }

  public setAccessToken(token: string) {
    this.accessToken = token

    if (token && isBrowser()) {
      setCookie(ACCESS_TOKEN, token)
    }
  }

  public removeAccessToken() {
    this.accessToken = ''
    browserCookies.erase(ACCESS_TOKEN)
  }

  public getRefreshToken() {
    return (this.loggedInAsUserRefreshToken || this.refreshToken) ?? ''
  }

  public setRefreshToken(token: string) {
    this.refreshToken = token

    if (token && isBrowser()) {
      setCookie(REFRESH_TOKEN, token)
    }
  }

  public removeRefreshToken() {
    this.refreshToken = ''
    browserCookies.erase(REFRESH_TOKEN)
  }

  public getLoggedInAsUserAccessToken() {
    return this.loggedInAsUserAccessToken ?? ''
  }

  public setLoggedInAsUserAccessToken(token: string) {
    this.loggedInAsUserAccessToken = token

    if (token && isBrowser()) {
      setCookie(LOGGED_IN_AS_USER_ACCESS_TOKEN, token)
    }
  }

  public removeLoggedInAsUserAccessToken() {
    this.loggedInAsUserAccessToken = ''
    browserCookies.erase(LOGGED_IN_AS_USER_ACCESS_TOKEN)
  }

  public getLoggedInAsUserRefreshToken() {
    return this.loggedInAsUserRefreshToken ?? ''
  }

  public setLoggedInAsUserRefreshToken(token: string) {
    this.loggedInAsUserRefreshToken = token

    if (token && isBrowser()) {
      setCookie(LOGGED_IN_AS_USER_REFRESH_TOKEN, token)
    }
  }

  public removeLoggedInAsUserRefreshToken() {
    this.loggedInAsUserRefreshToken = ''
    browserCookies.erase(LOGGED_IN_AS_USER_REFRESH_TOKEN)
  }

  public getPaymentAccessToken() {
    return this.paymentAccessToken ?? ''
  }

  public setPaymentAccessToken(token: string) {
    this.paymentAccessToken = token

    if (token && isBrowser()) {
      setCookie(PAYMENT_ACCESS_TOKEN, token)
    }
  }

  public removePaymentAccessToken() {
    this.paymentAccessToken = ''
    browserCookies.erase(PAYMENT_ACCESS_TOKEN)
  }
}
