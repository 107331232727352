import { head } from 'lodash'
import { AuctionCreateTemplate } from 'types/auction'
import { areValuesInArraySame } from 'utils/array'
import { normalizeString } from 'utils/string'

import { getValuesByColumnName } from './getValuesByColumnName'

export const getTemplateType = (template: AuctionCreateTemplate[]) => {
  const types = getValuesByColumnName(template, 'type') ?? []
  const areAllTypesSame = areValuesInArraySame(types, normalizeString)

  // TODO: temporary solution until BE will have done an endpoint for getting all the template types
  const assetTypes = [
    'laptop',
    'desktop',
    'server',
    'tablet',
    'switch',
    'networking equipment',
    'storage array',
    'printer',
    'lcd monitor',
    'docking station',
    'cell phone',
  ]

  const templateMainType = normalizeString(head(types as string[]) ?? '')
  return areAllTypesSame
    ? assetTypes.includes(templateMainType)
      ? 'asset'
      : templateMainType
    : 'asset'
}
