import { axiosExtended } from 'api/axios'

import { getPayoutStatisticUrl } from '../urls'
import { GetPayoutStatisticResponse } from './getPayoutStatistic.types'

export const getPayoutStatistic = async () => {
  const { href } = getPayoutStatisticUrl()
  const response = await axiosExtended.get<GetPayoutStatisticResponse>(href, {
    withAuth: true,
  })
  return response.data
}
