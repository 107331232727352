import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const AddSubscribeIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <g fill='currentColor'>
      <path d='M1 4c.035 5.333 0 10.667 0 16h13v-2H3V8l8 5 8-5v5h2V4H1Zm10 7L3 6h16l-8 5Z' />
      <path d='M21 15h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </SvgWrapper>
)
