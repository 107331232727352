import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const ListIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M2 6h3v2H2V6ZM7 18h15v-2H7v2ZM7 13h15v-2H7v2ZM7 6v2h15V6H7ZM2 11h3v2H2v-2ZM5 16H2v2h3v-2Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
