import { axiosExtended } from 'api/axios'

import { getAuctionReminderListUrl } from '../urls'
import { GetAuctionReminderListResponse } from './getAuctionReminderList.types'

export const getAuctionReminderList = async () => {
  const { href } = getAuctionReminderListUrl()
  const response = await axiosExtended.get<GetAuctionReminderListResponse>(
    href,
    {
      withAuth: true,
    }
  )
  return response.data
}
