import { APP_URL } from 'constants/env'
import { UserRoles } from 'types/enums'

export const getUserHomeUrlByRole = (
  role: UserRoles | null | undefined
): URL => {
  switch (role) {
    case UserRoles.Admin:
      return new URL(`${APP_URL}/admin/users`)
    case UserRoles.Seller:
      return new URL(`${APP_URL}/dashboard`)
    case UserRoles.Buyer:
      return new URL(`${APP_URL}/auctions`)
    default:
      return new URL(`${APP_URL}/auctions`)
  }
}
