import { axiosExtended } from 'api/axios'

import { getGetOrderDefectReasonsUrl } from '../urls'
import { GetOrderDefectReasonsResponse } from './getOrderDefectReasons.types'

export const getOrderDefectReasons = async () => {
  const { href } = getGetOrderDefectReasonsUrl()
  const response = await axiosExtended.get<GetOrderDefectReasonsResponse>(
    href,
    {
      withAuth: true,
    }
  )
  return response.data
}
