import { axiosExtended } from 'api/axios'

import { getDownloadOrderFilesUrl } from '../urls'
import {
  DownloadOrderFilesData,
  DownloadOrderFilesResponse,
} from './downloadOrderFiles.types'

export const downloadOrderFiles = async (query: DownloadOrderFilesData) => {
  const { href } = getDownloadOrderFilesUrl(query)
  const response = await axiosExtended.get<DownloadOrderFilesResponse>(href, {
    withAuth: true,
    responseType: 'blob',
  })

  return response.data
}
