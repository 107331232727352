import { axiosExtended } from 'api/axios'

import { getShippingTypeListUrl } from '../urls'
import { GetShippingTypeListResponse } from './getShippingTypeList.types'

export const getShippingTypeList = async () => {
  const { href } = getShippingTypeListUrl()
  const response = await axiosExtended.get<GetShippingTypeListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
