import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { DeleteUserFileData } from './deleteUserFile'
import { GetUserListData } from './getUserList'

export const getCurrentUserInfoUrl = (): URL => {
  const url = `${API_URL}/user/me`
  return new URL(url)
}

export const getUserListUrl = (query?: GetUserListData): URL => {
  const url = `${API_URL}/admin/user/list`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getResetPasswordUrl = (): URL => {
  const url = `${API_URL}/user/reset-password/link`
  return new URL(url)
}

export const getSetNewPasswordUrl = (): URL => {
  const url = `${API_URL}/user/reset-password/set`
  return new URL(url)
}

export const getUserSettingsUrl = (): URL => {
  const url = `${API_URL}/user/settings`
  return new URL(url)
}

export const getUserPackageAndShippingSettingsUrl = (): URL => {
  const url = `${API_URL}/settings/get`
  return new URL(url)
}

export const getSetUserPackageAndShippingSettingsUrl = (): URL => {
  const url = `${API_URL}/settings/set`
  return new URL(url)
}

export const getDeleteUserFile = (query: DeleteUserFileData): URL => {
  const url = `${API_URL}/user/file`
  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getDeleteUserAccountUrl = (): URL => {
  const url = `${API_URL}/user/delete`
  return new URL(url)
}
