import { useEffect } from 'react'

export const usePreventUnload = (activatePreventUnload = true) => {
  const preventUnload = function (event: BeforeUnloadEvent) {
    if (!activatePreventUnload) {
      return
    }

    event.preventDefault()
    event.returnValue = ''
  }

  useEffect(() => {
    window.addEventListener('beforeunload', preventUnload)

    return () => {
      window.removeEventListener('beforeunload', preventUnload)
    }
  }, [activatePreventUnload])
}
