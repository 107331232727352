import { RefObject, useLayoutEffect, useRef, useState } from 'react'

export const useIsOverflow = (ref: RefObject<HTMLElement | null>) => {
  const [isOverflow, setIsOverflow] = useState(false)
  const observer = useRef<ResizeObserver | null>(null)

  useLayoutEffect(() => {
    const { current } = ref
    if (!current) {
      return
    }

    const trigger = () => {
      const hasOverflow =
        current.scrollWidth > current.clientWidth ||
        current.scrollHeight > current.clientHeight

      setIsOverflow(hasOverflow)
    }

    if ('ResizeObserver' in window) {
      observer.current = new ResizeObserver(trigger)
      observer.current?.observe(current)
    }

    trigger()

    return () => {
      if (observer.current) {
        observer.current.unobserve(current)
      }
    }
  }, [ref])

  return isOverflow
}
