import { StatusType } from 'components/Datatable'

export const renderStatusStyles = (type: StatusType) => {
  const postfix = '_bright'
  switch (type) {
    case 'pending':
    case 'awaitingPayment':
    case 'awaitingShipment':
    case 'clearingFunds':
      return {
        bgcolor: 'rgba(255, 92, 0, 0.08)',
        color: '#FF5C00',
      }
    case 'awaitingPayment' + postfix:
    case 'awaitingShipment' + postfix:
      return {
        bgcolor: 'rgba(255, 92, 0, 1)',
        color: '#ffffff',
        fontWeight: '600',
      }
    case 'accepted':
    case 'paid':
    case 'readyForPickup':
    case 'delivered':
    case 'pickedUp':
      return {
        bgcolor: 'rgba(0, 152, 22, 0.08)',
        color: '#009816',
      }
    case 'completed' + postfix:
    case 'paid' + postfix:
    case 'pickedUp' + postfix:
    case 'delivered' + postfix:
    case 'readyForPickup' + postfix:
      return {
        color: 'rgba(255, 255, 255, 1)',
        bgcolor: 'rgba(0, 152, 22, 1)',
        fontWeight: '600',
      }
    case 'failed':
    case 'rejected':
    case 'overdue':
    case 'voided':
    case 'cancelled':
    case 'canceled':
    case 'labelMissing':
    case 'pickupLate':
    case 'suspended':
    case 'deleted':
      return {
        bgcolor: 'rgba(245, 39, 26, 0.08)',
        color: '#F5271A',
      }
    case 'overdue' + postfix:
    case 'voided' + postfix:
    case 'labelMissing' + postfix:
    case 'pickupLate' + postfix:
    case 'failed' + postfix:
      return {
        bgcolor: 'rgba(245,39, 26, 1)',
        color: '#ffffff',
        fontWeight: '600',
      }
    case 'partiallyPaid':
    case 'invited':
      return {
        bgcolor: 'rgba(217, 156, 39, 0.08)',
        color: 'rgba(217, 156, 39, 1)',
      }
    case 'partiallyPaid' + postfix:
      return {
        bgcolor: 'rgba(217, 156, 39, 1)',
        color: '#ffffff',
        fontWeight: '600',
      }
    case 'action_required':
      return {
        bgcolor: '#F5271A',
        color: '#FFFFFF',
        fontWeight: '600',
      }
    case 'rp_did_not_meet':
      return {
        bgcolor: '#FF5C00',
        color: '#FFFFFF',
        fontWeight: '600',
      }
    case 'rp_meet':
      return {
        bgcolor: '#009816',
        color: '#FFFFFF',
        fontWeight: '600',
      }
    case 'active':
    case 'completed':
    case 'inStock':
    case 'auctioning':
    case 'posted':
      return {
        color: 'rgba(0, 152, 22, 1)',
        bgcolor: 'rgba(0, 152, 22, 0.08)',
      }
    case 'awaiting':
    case 'ready':
      return {
        color: 'rgba(255, 92, 0, 1)',
        bgcolor: 'rgba(255, 92, 0, 0.08)',
      }
    case 'open':
    case 'sold':
    case 'shipped':
    case 'lastBid':
    case 'assigned':
    case 'inProgress':
    case 'processing':
    case 'labelUploaded':
      return {
        color: 'rgba(0, 127, 244, 1)',
        bgcolor: 'rgba(0, 127, 244, 0.08)',
      }
    case 'labelUploaded' + postfix:
    case 'processing' + postfix:
    case 'inProgress' + postfix:
      return {
        color: '#ffffff',
        bgcolor: 'rgba(0, 127, 244, 1)',
        fontWeight: '600',
      }
    case 'closed':
      return {
        color: 'rgba(245, 39, 26, 1)',
        bgcolor: 'rgba(245, 39, 26, 0.08)',
      }
    case 'reposted':
    case 'scheduled':
      return {
        color: 'rgba(34, 34, 34, 0.72)',
        bgcolor: 'rgba(34, 34, 34, 0.04)',
      }
    case 'consolidated':
    case 'winning':
      return {
        bgcolor: 'rgba(166, 0, 244, 0.08)',
        color: '#A600F4',
      }
    case 'consolidated' + postfix:
      return {
        bgcolor: 'rgba(166, 0, 244, 1)',
        color: '#ffffff',
        fontWeight: '600',
      }
    case 'verification':
    case 'null_bright':
      return {
        bgcolor: 'rgba(34, 34, 34, 0.72)',
        color: '#ffffff',
      }
    case null:
      return {
        bgcolor: 'rgba(34, 34, 34, 0.04)',
        color: 'rgba(34, 34, 34, 0.72)',
      }
    default:
      return {
        bgcolor: 'transparent',
        color: '#000000',
      }
  }
}
