import { axiosExtended } from 'api/axios'
import { serialize } from 'object-to-formdata'

import { getRegisterUrl } from '../urls'
import { RegisterData, RegisterResponse } from './register.types'

export const register = async (data: RegisterData) => {
  const { href } = getRegisterUrl()
  const response = await axiosExtended.post<RegisterResponse>(
    href,
    serialize(data)
  )
  return response.data
}
