import { axiosExtended } from 'api/axios'

import { getSetUserPackageAndShippingSettingsUrl } from '../urls'
import { SetUserPackageAndShippingSettingsData } from './setUserPackageAndShippingSettings.types'

export const setUserPackageAndShippingSettings = async (
  data: SetUserPackageAndShippingSettingsData
) => {
  const { href } = getSetUserPackageAndShippingSettingsUrl()
  // TODO: add corresponding type for the response
  // eslint-disable-next-line
  const response =
    await axiosExtended.post<SetUserPackageAndShippingSettingsData>(
      href,
      data,
      {
        withAuth: true,
      }
    )
  return response.data
}
