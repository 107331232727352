export enum AttributeTypes {
  Plain = '1',
  PreDefined = '2',
  Asset = '3',
}

export enum AttributeSystemCodes {
  Category = 1,
  Manufacturer = 2,
  /**
   * Used for storing sub assets, for example, for storing RAM name
   */
  Value = 3,
  Quantity = 4,
}

export enum PackageType {
  Pallet = 'pallet',
  Box = 'box',
}

export enum AuctionStatus {
  Scheduled = 'scheduled',
  Active = 'active',
  Awaiting = 'awaiting',
  Sold = 'sold',
  Closed = 'closed',
  Reposted = 'reposted',
}
