import { ChangeEventHandler, MouseEvent, ReactNode } from 'react'
import { ActionMenuItem } from 'types/admin'

export interface Column {
  title: string
  id: number | string
  textAlign?: string
  cellWidth?: string
  lotId?: string | number
}

export enum Status {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  invited = 'invited',
  verification = 'verification',
  failed = 'failed',
  action_required = 'action_required',
  rp_did_not_meet = 'rp_did_not_meet',
  rp_meet = 'rp_meet',
  clearingFunds = 'clearingFunds',
  paid = 'paid',
  paid_bright = 'paid',
  awaitingPayment = 'awaiting payment',
  partiallyPaid = 'partiallyPaid',
  partiallyPaid_bright = 'partiallyPaid_bright',
  overdue = 'overdue',
  voided = 'voided',
  overdue_bright = 'overdue',
  voided_bright = 'voided',
  'labelMissing' = 'label missing',
  'labelMissing_bright' = 'label missing_bright',
  'awaitingShipment' = 'awaiting shipment',
  'awaitingShipment_bright' = 'awaiting shipment',
  'awaitingPayment_bright' = 'awaiting payment',
  'readyForPickup' = 'ready for pickup',
  'readyForPickup_bright' = 'ready for pickup',
  open = 'open',
  canceled = 'canceled',
  cancelled = 'cancelled',
  consolidated = 'consolidated',
  consolidated_bright = 'consolidated',
  completed = 'completed',
  completed_bright = 'completed',
  suspended = 'suspended',
  unsuspended = 'unsuspended',
  scheduled = 'scheduled',
  active = 'active',
  awaiting = 'awaiting',
  sold = 'sold',
  closed = 'closed',
  reposted = 'reposted',
  processing = 'processing',
  assigned = 'assigned',
  processing_bright = 'processing',
  'delivered' = 'delivered',
  delivered_bright = 'delivered',
  'pickedUp' = 'picked up',
  'pickedUp_bright' = 'picked up',
  'pickupLate' = 'pickup late',
  'pickupLate_bright' = 'pickup late',
  'labelUploaded' = 'label uploaded',
  'labelUploaded_bright' = 'label uploaded',
  shipped = 'shipped',
  auctioning = 'auctioning',
  inStock = 'in stock',
  null_bright = 'N/A',
  posted = 'posted',
  lastBid = 'lastBid',
  inProgress = 'inProgress',
  ready = 'ready',
  deleted = 'deleted',
  winning = 'winning',
}

export type StatusType = keyof typeof Status

export interface DataCell {
  title: StatusType | ReactNode | number | string | null
  tooltipText?: string
  isStatus?: boolean
  isLink?: boolean
  linkAddress?: string
  textAlign?: string
  isTextEllipsis?: boolean
  maxCellWidth?: string
  cellWidth?: string
  isConsolidatedOrder?: boolean
  consolidatedFrom?: number[]
  withLockIcon?: boolean
  isAction?: boolean
}

export interface DataRow {
  cells: DataCell[]
  isActionRequired?: boolean
  showActions?: boolean
  availableActions?: ActionType[]
  id: string | number
  linkTo?: string
  isDisabled?: boolean
  isCheckboxDisabled?: boolean
  isSuspended?: boolean
  lotId?: string | number
  isGreen?: boolean
}

export interface TableData {
  rows: DataRow[]
}

export type Order = 'asc' | 'desc'

export interface Pagination {
  rowsPerPageOptions: number[]
  count: number
  rowsPerPage: number
  page: number
  handleChangePage: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void
  handleChangeRowsPerPage?: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

export enum Action {
  accept = 'accept',
  reject = 'reject',
  edit = 'edit',
  close = 'close',
  adminEdit = 'adminEdit',
  delete = 'delete',
  editLot = 'editLot',
  deleteLot = 'deleteLot',
  publishLot = 'publishLot',
  repostAuction = 'repostAuction',
  selectWinner = 'selectWinner',
  verifyEmail = 'verifyEmail',
}

export type ActionType = keyof typeof Action

export interface Props {
  columns: Column[]
  data?: TableData
  size?: 'small' | 'medium'
  minWidth?: string
  maxWidth?: string
  maxHeight?: string
  pagination?: Pagination
  order?: Order
  orderBy?: string | number
  onRequestSort?: (event: MouseEvent<unknown>, property: string) => void
  onSelect?: (selectedItems: DataRow[]) => void
  onVerifyEmail?: (id: number | string) => void
  onAccept?: (id: number | string) => void
  onReject?: (id: number | string) => void
  onDelete?: (id: number | string) => void
  onRepost?: (id: number | string, lotId?: number | string) => void
  onClose?: (id: number | string) => void
  selectWinner?: (id: number | string) => void
  onEditLot?: (id: number | string) => void
  onActionLabel?: (id: number | string) => void
  dropdownItems?: ActionMenuItem[]
  stickyHeader?: boolean
  onRowClick?: (id: string | number) => void
  tdSx?: object
  isFooterAction?: () => void
  isFooterActionDisabled?: boolean
  isConsolidateButton?: boolean
  isCheckbox?: boolean
  loading?: boolean
  withSorting?: boolean
  isBidsheetTable?: boolean
}
