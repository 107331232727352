import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const ArrowBackwardIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='m10 4 1.41 1.41L5.83 11H21v2H5.83l5.59 5.59L10 20l-8-8 8-8Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
