import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { GetPayoutData } from './getPayout'
import { GetPayoutListData } from './getPayoutList'

export const getGetPayoutUrl = (params?: GetPayoutData): URL => {
  const url = `${API_URL}/payout/`
  if (!params) {
    return new URL(url)
  }

  const { id: payoutId } = params
  return new URL(url + String(payoutId))
}

export const getPayoutListUrl = (query?: GetPayoutListData): URL => {
  const url = `${API_URL}/payout/list`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getPayoutStatisticUrl = () => {
  const url = `${API_URL}/payout/statistic`
  return new URL(url)
}
