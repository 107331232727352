import { axiosExtended } from 'api/axios'

import { getAuctionBidsUrl } from '../urls'
import {
  GetAuctionBidsData,
  GetAuctionBidsResponse,
} from './getAuctionBids.types'

export const getAuctionBids = async (params: GetAuctionBidsData) => {
  const { href } = getAuctionBidsUrl(params)
  const response = await axiosExtended.get<GetAuctionBidsResponse>(href, {
    withAuth: true,
  })
  return response.data
}
