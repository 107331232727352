import { axiosExtended } from 'api/axios'

import { getRemoveStaffUserUrl } from '../urls'
import { RemoveStaffUserData } from './removeStaffUser.types'

export const removeStaffUser = async (query: RemoveStaffUserData) => {
  const { href } = getRemoveStaffUserUrl(query)
  const response = await axiosExtended.delete(href, {
    withAuth: true,
  })
  return response.data
}
