import { axiosExtended } from 'api/axios'

import { resendInviteUrl } from '../urls'
import { ResendInviteData, ResendInviteResponse } from './resendInvite.types'

export const resendInvite = async (data: ResendInviteData) => {
  const { href } = resendInviteUrl()
  const response = await axiosExtended.post<ResendInviteResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
