import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const AddIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path d='M0 0h24v24H0z' fill='transparent' />
    <path d='M13 5h-2v6H5v2h6v6h2v-6h6v-2h-6V5Z' fill='currentColor' />
  </SvgWrapper>
)
