import { Typography } from '@mui/material'
import React, { FC } from 'react'
import { renderStatusStyles, renderStatusTitle } from 'utils/statusField'

import { Props } from './StatusField.types'

export const StatusField: FC<Props> = ({ title }) => {
  return (
    <Typography
      sx={{
        borderRadius: '13px',
        height: '26px',
        p: '0 8px',
        width: 'fit-content',
        fontSize: '14px',
        lineHeight: '26px',
        ...renderStatusStyles(title),
      }}
    >
      {renderStatusTitle(title)}
    </Typography>
  )
}
