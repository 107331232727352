import { axiosExtended } from 'api/axios'

import { getInviteUserUrl } from '../urls'
import { InviteUserData, InviteUserResponse } from './inviteUser.types'

export const inviteUser = async (data: InviteUserData) => {
  const { href } = getInviteUserUrl()
  const response = await axiosExtended.post<InviteUserResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
