import { axiosExtended } from 'api/axios'

import { getMakeBidUrl } from '../urls'
import { MakeBidData, MakeBidResponse } from './makeBid.types'

export const makeBid = async (data: MakeBidData) => {
  const { href } = getMakeBidUrl()
  const response = await axiosExtended.post<MakeBidResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
