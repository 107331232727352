import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const TickIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='m9 15.2-3.6-3.6L4 13l5 5L20.007 6.994l-1.4-1.4L9 15.2Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
