import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const DinersClubLogo: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M18.6 28c6.56.031 12.547-5.35 12.547-11.896C31.147 8.946 25.16 3.998 18.6 4h-5.645C6.317 3.998.853 8.947.853 16.104c0 6.548 5.464 11.927 12.102 11.896H18.6Z'
      fill='#0079BE'
    />
    <path
      d='M12.982 4.992C6.916 4.994 2 9.911 1.999 15.98c.002 6.067 4.917 10.983 10.983 10.985 6.067-.002 10.983-4.918 10.984-10.985-.001-6.068-4.917-10.985-10.984-10.987ZM6.02 15.98a6.974 6.974 0 0 1 4.47-6.499v12.996a6.971 6.971 0 0 1-4.47-6.497Zm9.453 6.5v-13a6.97 6.97 0 0 1 4.472 6.5 6.973 6.973 0 0 1-4.472 6.5Z'
      fill='#fff'
    />
  </SvgWrapper>
)
