import { axiosExtended } from 'api/axios'

import { getApproveUserAsAdminUrl } from '../urls'
import { ApproveUserAsAdminData } from './approveUserAsAdmin.types'

export const approveUserAsAdmin = async (data: ApproveUserAsAdminData) => {
  const { href } = getApproveUserAsAdminUrl()
  // TODO: add corresponding type for the response
  // eslint-disable-next-line
  const response = await axiosExtended.put<any>(href, data, {
    withAuth: true,
  })
  return response.data
}
