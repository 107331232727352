import { axiosExtended } from 'api/axios'

import { getConsolidateShipmentUrl } from '../urls'
import {
  ConsolidateShipmentData,
  ConsolidateShipmentResponse,
} from './consolidateShipment.types'

export const consolidateShipment = async (data: ConsolidateShipmentData) => {
  const { href } = getConsolidateShipmentUrl()
  const response = await axiosExtended.post<ConsolidateShipmentResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
