import { axiosExtended } from 'api/axios'

import { getCreateAuctionFromLot } from '../urls'
import {
  CreateAuctionFromLotData,
  CreateAuctionFromLotResponse,
} from './createAuctionFromLot.types'

export const createAuctionFromLot = async (data: CreateAuctionFromLotData) => {
  const { href } = getCreateAuctionFromLot()
  const response = await axiosExtended.post<CreateAuctionFromLotResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
