import { axiosExtended } from 'api/axios'

import { getOrderListAsAdminUrl } from '../urls'
import {
  GetOrderListAsAdminData,
  GetOrderListAsAdminResponse,
} from './getOrderListAsAdmin.types'

export const getOrderListAsAdmin = async (query?: GetOrderListAsAdminData) => {
  const { href } = getOrderListAsAdminUrl(query ? query : undefined)
  const response = await axiosExtended.get<GetOrderListAsAdminResponse>(href, {
    withAuth: true,
  })
  return response.data
}
