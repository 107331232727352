import { getCurrentUserInfo } from 'api/requests/user'
import { USER } from 'constants/cookieKeys'
import { useLogout } from 'hooks'
import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import { UserDeletionStatuses } from 'types/enums'
import { UserSettings } from 'types/settings'
import { TokenStorage } from 'utils/auth'
import { deleteCookie, setCookie } from 'utils/cookie'

export const UserContext = createContext<{
  user: UserSettings | null
  setUser: (user: UserSettings | null) => void
  fetchUser: () => Promise<void>
}>({ user: null, setUser: () => {}, fetchUser: async () => {} })

export const UserProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const tokenStorage = TokenStorage.getInstance()
  const accessToken = tokenStorage.getAccessToken()
  const logout = useLogout()

  const [user, setUser] = useState<UserSettings | null>(null)

  const fetchUser = async () => {
    try {
      const result = await getCurrentUserInfo()
      if (!result.data) {
        return
      }

      setUser(result.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!accessToken) {
      deleteCookie(USER)
      setUser(null)
      return
    }

    void fetchUser()
  }, [accessToken])

  useEffect(() => {
    if (!user) {
      return
    }

    if (user.deletionStatus === UserDeletionStatuses.Deleted) {
      logout()
      return
    }

    // Setting user's data to the cookie to make middleware work correctly
    setCookie(USER, JSON.stringify(user))
  }, [user, logout])

  return (
    <UserContext.Provider value={{ user, setUser, fetchUser }}>
      {children}
    </UserContext.Provider>
  )
}
