import { axiosExtended } from 'api/axios'

import { getRateOrderUrl } from '../urls'
import { RateOrderData, RateOrderResponse } from './rateOrder.types'

export const rateOrder = async (data: RateOrderData) => {
  const { href } = getRateOrderUrl()
  const response = await axiosExtended.post<RateOrderResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
