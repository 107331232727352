import { axiosExtended } from 'api/axios'

import { getSubscriptionListUrl } from '../urls'
import { GetSubscriptionListResponse } from './getSubscriptionList.types'

export const getSubscriptionList = async () => {
  const { href } = getSubscriptionListUrl()
  const response = await axiosExtended.get<GetSubscriptionListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
