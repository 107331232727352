import browserCookies from 'browser-cookies'
import { DASHBOARD_FILTERS } from 'constants/cookieKeys'
import { getCookie, setCookie } from 'utils/cookie'
import { isBrowser } from 'utils/environment'

export class DashboardStorage {
  private static instance: DashboardStorage

  private dashboardFilters: number[] = []

  public static getInstance(): DashboardStorage {
    if (!DashboardStorage.instance) {
      DashboardStorage.instance = new DashboardStorage()

      if (isBrowser()) {
        const filters = getCookie(DASHBOARD_FILTERS)

        if (filters?.length) {
          DashboardStorage.instance.setDashboardFilters(
            JSON.parse(filters) as number[]
          )
        }
      }
    }

    return DashboardStorage.instance
  }

  public getDashboardFilters() {
    return this.dashboardFilters ?? ''
  }

  public setDashboardFilters(filters: number[]) {
    if (filters) {
      this.dashboardFilters = filters

      if (isBrowser()) {
        setCookie(DASHBOARD_FILTERS, JSON.stringify(filters))
      }
    }
  }

  public removeDashboardFilters() {
    this.dashboardFilters = []
    browserCookies.erase(DASHBOARD_FILTERS)
  }
}
