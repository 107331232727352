import { axiosExtended } from 'api/axios'

import { getRecordOrderDefectUrl } from '../urls'
import {
  RecordOrderDefectReasonData,
  RecordOrderDefectReasonResponse,
} from './recordOrderDefectReason.types'

export const recordOrderDefectReason = async (
  data: RecordOrderDefectReasonData
) => {
  const { href } = getRecordOrderDefectUrl()
  const response = await axiosExtended.post<RecordOrderDefectReasonResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
