import { axiosExtended } from 'api/axios'

import { getAuctionDetailsAsAdminUrl } from '../urls'
import {
  GetAuctionDetailsAsAdminData,
  GetAuctionDetailsAsAdminResponse,
} from './getAuctionDetailsAsAdmin.types'

export const getAuctionDetailsAsAdmin = async (
  params: GetAuctionDetailsAsAdminData
) => {
  const { href } = getAuctionDetailsAsAdminUrl(params)
  const response = await axiosExtended.get<GetAuctionDetailsAsAdminResponse>(
    href,
    {
      withAuth: true,
    }
  )
  return response.data
}
