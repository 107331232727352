import { axiosExtended } from 'api/axios'

import { getRefreshTokensUrl } from '../urls'
import { RefreshTokensData, RefreshTokensResponse } from './refreshToken.types'

export const refreshTokens = async (data: RefreshTokensData) => {
  const { href } = getRefreshTokensUrl()
  const response = await axiosExtended.post<RefreshTokensResponse>(href, data)
  return response.data
}
