import { Typography } from '@mui/material'
import { cancelOrderAsAdmin, getOrderAsAdmin } from 'api/requests/admin'
import { StatusType, TableData } from 'components/Datatable'
import { Image, Item } from 'components/DetailsSection'
import { StatusField } from 'components/StatusField'
import { FILE_SERVER_URL } from 'constants/urls'
import { addHours, set } from 'date-fns'
import { capitalize } from 'lodash'
import PlaceholderImage from 'public/images/image-placeholder.png'
import { useState } from 'react'
import { AdminOrderDetails } from 'types/admin'
import { createPackageRows } from 'utils/admin'
import { formatDate } from 'utils/format'

export const useAdminOrder = (orderId: string) => {
  const [data, setData] = useState<Partial<AdminOrderDetails>[]>([{}])
  const [shippingItemsData, setShippingItemsData] = useState<
    TableData | undefined
  >()
  const [pickupLocationList, setPickupLocationList] = useState<Item[]>([])
  const [contactInfoData, setContactInfoData] = useState<Item[]>([])
  const [pickupHoursData, setPickupHoursData] = useState<Item[]>([])
  const [shipmentData, setShipmentData] = useState<Item[]>([])
  const [historyData, setHistoryData] = useState<Item[]>([])
  const [buyerData, setBuyerData] = useState<Item[]>([])
  const [sellerData, setSellerData] = useState<Item[]>([])
  const [imageList, setImageList] = useState<Image[]>([])
  const [totalWeight, setTotalWeight] = useState<number>(0)
  const orderDetails = data[0]

  const fetchOrderData = async (id?: string) => {
    if (id) {
      try {
        const response = await getOrderAsAdmin(id)
        if (response?.data?.length) {
          const data = response.data
          setData(data)
          const consolidatedFrom = data?.[0]?.consolidatedFrom
          const isConsolidated = !!consolidatedFrom?.length
          const packages = isConsolidated
            ? data?.[0]?.consolidatedPackages
            : data?.[0]?.packages
          const location = isConsolidated
            ? consolidatedFrom[0].location
            : data?.[0]?.location
          const buyer = isConsolidated
            ? consolidatedFrom[0].buyer
            : data?.[0]?.buyer
          const seller = isConsolidated
            ? consolidatedFrom[0].seller
            : data?.[0]?.seller
          const shipmentDetails =
            data?.[0]?.shipmentDetails || consolidatedFrom?.[0]?.shipmentDetails

          const isPickedUp = data?.[0]?.history.find(
            item => item.status === 'pickedUp'
          )

          const isDelivered = data?.[0]?.history.find(
            item => item.status === 'delivered' && 'pickedUp'
          )

          const history =
            isConsolidated && data?.[0]?.order?.status === 'consolidated'
              ? consolidatedFrom[0].history
              : data?.[0]?.history

          if (packages) {
            const rows = createPackageRows(packages)
            const newTotalWeight = isConsolidated
              ? consolidatedFrom?.reduce((total, { packages }) => {
                  return (
                    total +
                    packages.reduce((total, order) => total + order.weight, 0)
                  )
                }, 0)
              : packages.reduce((total, order) => total + order.weight, 0)

            setShippingItemsData({ rows })
            setTotalWeight(newTotalWeight)
          }

          if (location) {
            const newPickupLocationList = [
              {
                title: 'Location address',
                content: `${location.address},${
                  location.apartment ? ` Suite ${location.apartment},` : ''
                } ${location.city},${
                  location.state ? ` ${location.state},` : ''
                } ${location.country}, ${location.zip}`,
              },
              { title: 'Location type', content: capitalize(location.type) },
              {
                title: 'Pickup available at',
                content:
                  [
                    'labelUploaded',
                    'pickupLate',
                    'readyForPickup',
                    'delivered',
                    'pickedUp',
                  ].includes(data?.[0]?.order?.shippingStatus as string) &&
                  data?.[0]?.shippingLabel?.pickupTime
                    ? `${formatDate(
                        addHours(
                          new Date(data[0].shippingLabel.pickupTime),
                          new Date().getTimezoneOffset() / 60
                        ),
                        'EEEE, h:mm aaa, MMMM d, yyyy'
                      )} (${data[0].shippingLabel.timeZoneAbbreviation})`
                    : `${location?.pickupTime} hours after shipping label is
                          uploaded`,
              },
              {
                title: 'Shipping reference number',
                content: `Order #${data?.[0]?.order?.id}`,
              },
              { title: 'Instructions', content: location.instruction },
              {
                title: 'Loading dock',
                content: location.hasLoadingDock
                  ? 'Available'
                  : 'Not Available',
              },
            ]
            const newContactInfoData = [
              { title: 'Contact person', content: location.contactFullname },
              { title: 'Phone number', content: location.contactPhoneNumber },
            ]
            if (location?.pickupSchedules?.length) {
              const newPickupHoursData = location.pickupSchedules.map(
                ({ startTime, endTime, day, dayOff }) => {
                  if (startTime && endTime) {
                    const startHours = startTime?.split(':')[0]
                    const startMinutes = startTime?.split(':')[1]
                    const startDate = set(new Date(), {
                      hours: Number(startHours),
                      minutes: Number(startMinutes),
                    })

                    const endHours = endTime?.split(':')[0]
                    const endMinutes = endTime?.split(':')[1]
                    const endDate = set(new Date(), {
                      hours: Number(endHours),
                      minutes: Number(endMinutes),
                    })

                    return {
                      title: capitalize(day),
                      content: dayOff
                        ? 'Closed'
                        : `${formatDate(startDate, 'h:mm aaa')} - ${formatDate(
                            endDate,
                            'h:mm aaa'
                          )}`,
                    }
                  } else {
                    return {
                      title: capitalize(day),
                      content: 'Closed',
                    }
                  }
                }
              )
              setPickupHoursData(newPickupHoursData)
            }

            setPickupLocationList(newPickupLocationList)
            setContactInfoData(newContactInfoData)
          }

          if (buyer) {
            const newBuyerData = [
              {
                title: 'Name',
                content: `${buyer.firstName}, ${buyer.lastName}`,
              },
              { title: 'Email', content: buyer.email },
              { title: 'Business name', content: buyer.businessName },
              {
                title: 'Address',
                content: `${buyer.address},${
                  buyer.apartment ? ` Suite ${buyer.apartment},` : ''
                } ${buyer.city},${buyer.state ? ` ${buyer.state},` : ''} ${
                  buyer.country
                }, ${buyer.zip}`,
              },
              { title: 'Phone', content: buyer.phoneNumber },
            ]
            setBuyerData(newBuyerData)
          }

          if (seller) {
            const newSellerData = [
              {
                title: 'Name',
                content: `${seller.firstName}, ${seller.lastName}`,
              },
              { title: 'Email', content: seller.email },
              { title: 'Business name', content: seller.businessName },
              {
                title: 'Address',
                content: `${seller.address},${
                  seller.apartment ? ` Suite ${seller.apartment},` : ''
                } ${seller.city},${seller.state ? ` ${seller.state},` : ''} ${
                  seller.country
                }, ${seller.zip}`,
              },
              { title: 'Phone', content: seller.phoneNumber },
            ]
            setSellerData(newSellerData)
          }

          if (shipmentDetails) {
            const newShipmentData = [
              { title: 'Shipment method', content: shipmentDetails.method },
              { title: 'Shipping label type', content: shipmentDetails.type },
              { title: 'Shipping carrier', content: shipmentDetails.carrier },
            ]
            if (isPickedUp) {
              newShipmentData.push({
                title: 'Picked up at',
                content: isPickedUp.createdAt
                  ? `${formatDate(
                      new Date(isPickedUp.createdAt),
                      'MM.d.yyyy h:mm aaa'
                    )}`
                  : '-',
              })
            }
            if (isDelivered) {
              newShipmentData.push({
                title: 'Delivered at',
                content: isDelivered.createdAt
                  ? `${formatDate(
                      new Date(isDelivered.createdAt),
                      'MM.d.yyyy h:mm aaa'
                    )}`
                  : '-',
              })
            }
            setShipmentData(newShipmentData)

            if (shipmentDetails.photos?.length) {
              const newImageList = shipmentDetails.photos.map(({ name }) => ({
                img: name ? `${FILE_SERVER_URL}${name}` : PlaceholderImage.src,
              }))
              setImageList(newImageList)
            }
          }

          if (history?.length) {
            const newHistoryData = history.map(
              ({ status, createdAt, method, message }) => ({
                title: createdAt
                  ? formatDate(new Date(createdAt), 'MM.d.yyyy h:mm aaa')
                  : '-',
                content: (
                  <>
                    <Typography sx={{ mr: '5px' }}>
                      {message
                        ? message
                        : `${capitalize(method)} status changed to`}
                    </Typography>
                    <StatusField title={status as StatusType} />
                  </>
                ),
              })
            )
            setHistoryData(newHistoryData)
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const cancelOrder = async () => {
    if (orderId) {
      try {
        await cancelOrderAsAdmin({ orderId: Number(orderId) })
        await fetchOrderData()
      } catch (e) {
        console.error(e)
      }
    }
  }

  return {
    data,
    orderDetails,
    shippingItemsData,
    shipmentData,
    historyData,
    buyerData,
    sellerData,
    imageList,
    pickupLocationList,
    pickupHoursData,
    contactInfoData,
    totalWeight,
    fetchOrderData,
    cancelOrder,
  }
}
