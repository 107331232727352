import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const SubscribedIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M1 4c.037 5.333 0 10.667 0 16h10v-2H3V8l8 5 8-5v5h2V4H1Zm10 7L3 6h16l-8 5Zm5.34 11L13 18.66l1.41-1.41 1.92 1.92L21 14.5l1.43 1.41L16.34 22Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
