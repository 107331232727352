export const clearTrashFiles = (files: FileList, idx: number) => {
  const dt = new DataTransfer()

  for (let i = 0; i < files.length; i++) {
    if (i !== idx) {
      dt.items.add(files[i])
    }
  }

  return dt.files
}
