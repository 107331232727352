import { axiosExtended } from 'api/axios'

import { getCloseAuctionAsAdminUrl } from '../urls'
import {
  CloseAuctionAsAdminData,
  CloseAuctionAsAdminResponse,
} from './closeAuctionAsAdmin.types'

export const closeAuctionAsAdmin = async (data: CloseAuctionAsAdminData) => {
  const { href } = getCloseAuctionAsAdminUrl()
  const response = await axiosExtended.post<CloseAuctionAsAdminResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
