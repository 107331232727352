import { yupResolver } from '@hookform/resolvers/yup'
import { getUserAsAdmin } from 'api/requests/admin'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AllowedCountriesForBuyer } from 'types/enums'
import { AdminUserSetting } from 'types/settings'
import { numbersLettersRegex, numbersLettersSpaceRegex } from 'utils/regex'
import * as Yup from 'yup'

export const useAdminSetUserSettings = (userId: string) => {
  const [userData, setUserData] = useState<Partial<AdminUserSetting>>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const validationUserSettingSchema = Yup.object().shape({
    id: Yup.number(),
    firstName: Yup.string().trim(),
    lastName: Yup.string().trim(),
    email: Yup.string().trim(),
    businessName: Yup.string().trim(),
    address: Yup.string().trim(),
    apartment: Yup.string().trim(),
    city: Yup.string().trim(),
    state: Yup.string()
      .trim()
      .test({
        name: 'isStateFilled',
        message: 'Field is required',
        test: (state, context) => {
          if (
            [
              AllowedCountriesForBuyer['United States'],
              AllowedCountriesForBuyer.Canada,
            ].includes(context.parent.country as AllowedCountriesForBuyer)
          ) {
            return Boolean(state)
          }

          return true
        },
      }),
    country: Yup.string().trim(),
    businessCountry: Yup.string().trim(),
    zip: Yup.string()
      .trim()
      .test({
        name: 'isZIPValid',
        test: async (zip, context) => {
          const isBusinessCountryCanada =
            context.parent.country === AllowedCountriesForBuyer.Canada
          const zipSchema = Yup.string()
            .trim()
            .matches(
              isBusinessCountryCanada
                ? numbersLettersSpaceRegex
                : numbersLettersRegex
            )

          const isZipValid = await zipSchema.isValid(zip)
          if (!isZipValid) {
            return new Yup.ValidationError(
              `ZIP code must contain only numbers and letters${
                isBusinessCountryCanada ? ' and space characters' : ''
              }`,
              false,
              'zip'
            )
          }

          return true
        },
      })
      .min(2, 'ZIP length must be at least 2 characters long')
      .max(8, 'Length must be lest than or equal to 8 characters long'),
    createdAt: Yup.string().trim(),
    phoneNumber: Yup.string().trim(),
  })

  const { control, handleSubmit, setValue, formState, trigger, watch } =
    useForm<AdminUserSetting>({
      mode: 'all',
      defaultValues: {
        firstName: '',
        lastName: '',
        email: '',
        businessName: '',
        address: '',
        apartment: '',
        city: '',
        state: '',
        country: '',
        businessCountry: '',
        zip: '',
        phoneNumber: '',
      },
      resolver: yupResolver(validationUserSettingSchema),
    })

  const watchCountry = watch('country')

  const fetchUserData = async () => {
    setIsLoading(true)
    const user = await getUserAsAdmin({ userId })
    setIsLoading(false)
    const data = user?.data
    if (data) {
      setUserData(data)
    }
    return data
  }

  const setFormStates = (data: AdminUserSetting) => {
    setValue('firstName', data.firstName)
    setValue('lastName', data.lastName)
    setValue('email', data.email)
    setValue('businessName', data.businessName)
    setValue('address', data.address)
    setValue('apartment', data.apartment)
    setValue('city', data.city)
    setValue('state', data.state)
    setValue('country', data.country)
    setValue('businessCountry', data.businessCountry)
    setValue('zip', data.zip)
    setValue('phoneNumber', data.phoneNumber)
    if (data.primaryContactPersonRole) {
      setValue('primaryContactPersonRole', data.primaryContactPersonRole)
    }
  }

  const resetForm = () => {
    if (userData) {
      setFormStates(userData as AdminUserSetting)
    }
  }

  useEffect(() => {
    if (userId) {
      void (async () => {
        const data = await fetchUserData()
        if (data) {
          setFormStates(data)
        }
      })()
    }
  }, [userId])

  useEffect(() => {
    void trigger('state')
  }, [watchCountry])

  return {
    control,
    handleSubmit,
    userData,
    isLoading,
    formState,
    setValue,
    fetchUserData,
    resetForm,
  }
}
