import { Button, Skeleton } from '@mui/material'
import { FC } from 'react'

import { Props } from './Button.types'

export const ButtonCustom: FC<Props> = ({
  children,
  height = '48px',
  width = '100%',
  minWidth = 'auto',
  variant = 'contained',
  fontSize = '18px',
  loading,
  ...props
}) => {
  const containedStyles = {
    '&:active': {
      backgroundColor: '#007153',
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.4)',
      backgroundColor: '#009970',
    },
  }
  const textStyles = {
    color: '#222',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: 'rgba(34, 34, 34, 0.04)',
      borderRadius: '24px',
    },
    '&:active': {
      backgroundColor: 'rgba(34, 34, 34, 0.08)',
    },
    '&.Mui-disabled': {
      color: 'rgba(34, 34, 34, 0.32)',
    },
  }

  return (
    <>
      {loading ? (
        <Skeleton
          variant={'rectangular'}
          sx={{ height, width: '180px', borderRadius: '24px', ...props.sx }}
        />
      ) : (
        <Button
          {...props}
          variant={variant}
          sx={{
            fontSize: fontSize,
            height,
            width,
            minWidth,
            textTransform: 'none',
            boxShadow: 'none',
            ...(variant === 'contained' && containedStyles),
            ...(variant === 'text' && textStyles),
            ...props.sx,
          }}
        >
          {children}
        </Button>
      )}
    </>
  )
}
