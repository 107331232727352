import { setAuctionAsAdminWinner } from 'api/requests/admin'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

// TODO All unused constants will be user later
export const useAdminBid = (bidId: string) => {
  const [bidData, setBidData] = useState({})
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { control, handleSubmit, setValue, formState } = useForm({})

  const resetForm = () => {}

  const handleSetWinner = async () => {
    if (bidId) {
      try {
        await setAuctionAsAdminWinner({ bidId: Number(bidId) })
      } catch (error) {
        console.error(error)
      }
    }
  }

  return {
    bidData,
    isLoading,
    control,
    handleSubmit,
    resetForm,
    handleSetWinner,
  }
}
