import { axiosExtended } from 'api/axios'

import { getDeleteUserAsAdminUrl } from '../urls'
import { DeleteUserAsAdminResponse } from './deleteUserAsAdmin.types'

export const deleteUserAsAdmin = async (id: number) => {
  const { href } = getDeleteUserAsAdminUrl(id)
  const response = await axiosExtended.delete<DeleteUserAsAdminResponse>(href, {
    withAuth: true,
  })
  return response.data
}
