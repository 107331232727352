import {
  getNotificationsList,
  GetNotificationsListData,
} from 'api/requests/notifications'
import { SOCKET_URL } from 'constants/urls'
import { useUserContext } from 'hooks'
import {
  ChangeEvent,
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import { io } from 'socket.io-client'
import { UserRoles } from 'types/enums'
import { Notification } from 'types/notification'
import { TokenStorage } from 'utils/auth'

export interface NotificationContextProps {
  notificationList: Notification[] | null
  unreadNotificationsTotal: number
  fetchNotificationsList?: () => void
  popNotification?: (notification: Notification) => void
  page: number
  count: number
  handleChangePage: (
    event: ChangeEvent<unknown> | undefined,
    value: number
  ) => void
}

export const NotificationsContext = createContext<NotificationContextProps>({
  notificationList: null,
  unreadNotificationsTotal: 0,
  page: 1,
  count: 1,
  handleChangePage: () => {},
})

const tokenStorage = TokenStorage.getInstance()
const accessToken = tokenStorage.getAccessToken()

const socket = io(SOCKET_URL, {
  transports: ['websocket'],
  query: {
    authorization: `Bearer ${accessToken}`,
  },
})

export const NotificationsProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { user } = useUserContext()
  const isSeller = Boolean(
    user?.roles.some(role => role.code === UserRoles.Seller)
  )
  const isBuyer = Boolean(
    user?.roles.some(role => role.code === UserRoles.Buyer)
  )
  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  const perPage = 25

  const query = { page, perPage }

  const [notificationList, setNotificationList] = useState<Notification[]>([])
  const [unreadNotificationsTotal, setUnreadNotificationsTotal] =
    useState<number>(0)

  const fetchNotificationsList = async (query?: GetNotificationsListData) => {
    if (isSeller || isBuyer) {
      try {
        const response = await getNotificationsList(query)
        const data = response?.data
        if (data?.items) {
          setNotificationList(data?.items)
          setUnreadNotificationsTotal(data?.isNotRead || 0)
        }
        if (data?.total && data?.total > 0) {
          setCount(Math.ceil(data?.total / perPage))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handlePopNotification = async (notification: Notification) => {
    if (notification) {
      await fetchNotificationsList(query)
    }
  }

  useEffect(() => {
    socket.emit('subscribe', {
      eventName: 'notification',
    })

    socket.on('notification', handlePopNotification)

    return () => {
      socket.off('notification', handlePopNotification)
    }
  }, [])

  useEffect(() => {
    void fetchNotificationsList(query)
  }, [page, perPage, isSeller, isBuyer])

  const handleChangePage = (
    event: ChangeEvent<unknown> | undefined,
    value: number
  ) => {
    setPage(value)
  }

  return (
    <NotificationsContext.Provider
      value={{
        notificationList,
        unreadNotificationsTotal,
        fetchNotificationsList,
        handleChangePage,
        page,
        count,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
