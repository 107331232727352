import { axiosExtended } from 'api/axios'

import { getDownloadInvoiceUrl } from '../urls'
import { DownloadInvoiceResponse } from './downloadInvoice.types'

export const downloadInvoice = async (id: number) => {
  const { href } = getDownloadInvoiceUrl(id)
  const response = await axiosExtended.get<DownloadInvoiceResponse>(href, {
    withAuth: true,
    responseType: 'blob',
  })

  return response.data
}
