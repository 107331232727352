import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const LockOpenIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M14 15c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2Z'
      fill='currentColor'
    />
    <path
      d='M17 6v2h3v14H4V8h11V6c0-1.66-1.34-3-3-3S9 4.34 9 6H7c0-2.76 2.24-5 5-5s5 2.24 5 5ZM6 10v10h12V10H6Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
