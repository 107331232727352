import { axiosExtended } from 'api/axios'

import { getUserListUrl } from '../urls'
import { GetUserListData, GetUserListResponse } from './getUserList.types'

export const getUserList = async (query?: GetUserListData) => {
  const { href } = getUserListUrl(query ? query : undefined)
  const response = await axiosExtended.get<GetUserListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
