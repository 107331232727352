import { downloadInvoice } from 'api/requests/order/downloadInvoice'
import { saveAs } from 'file-saver'

export const handleDownloadInvoice = async (orderId?: string) => {
  if (orderId) {
    try {
      const response = await downloadInvoice(Number(orderId))
      // TODO: Need to figure out what's wrong with axios types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      saveAs(response, `invoice-${orderId}`)
    } catch (e) {
      console.error(e)
    }
  }
}
