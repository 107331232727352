/* eslint-disable */
// @ts-nocheck
import { uniq } from 'lodash'
import { normalizeString } from 'utils/string'
import { AuctionTemplateMeta } from 'types/auction'
import * as Xlsx from 'xlsx'

import { getTemplateType } from './getTemplateType'

export const isAuctionTemplate = (file: any, predefinedTemplates: Entiry[]) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)
    fileReader.onload = async e => {
      const bufferArray = e.target.result
      const wb = Xlsx.read(bufferArray, { type: 'buffer' })
      const sheets = Object.values(wb.Sheets).map(sheet =>
        Xlsx.utils.sheet_to_json(sheet)
      )

      const template = sheets[0]
      const foundTemplateNames = uniq(
        template
          .map(row =>
            Object.entries(row)
              .filter(([column]) => normalizeString(column) === 'type')
              .map(([, value]) => value)
          )
          .flat()
      )

      if (!foundTemplateNames.length) {
        return reject(false)
      }

      const templateType = getTemplateType(template)
      const validTemplates: AuctionTemplateMeta[] = predefinedTemplates.filter(
        predefinedTemplate => {
          if (templateType === 'asset') {
            return predefinedTemplate.name === 'asset'
          }

          return foundTemplateNames.some(
            templateName =>
              normalizeString(templateName as string) ===
              normalizeString(predefinedTemplate.name)
          )
        }
      )

      if (validTemplates.length === 0) {
        return reject(false)
      }

      resolve(true)
    }
  })
}
