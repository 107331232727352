import { confirmEmail as confirmEmailRequest } from 'api/requests/email'
import { useEffect, useState } from 'react'
import { TokenStorage } from 'utils/auth'

export const useConfirmEmail = ({
  email,
  code,
}: {
  email: string
  code: string
}) => {
  const [confirmed, setConfirmed] = useState(false)
  const [expired, setExpired] = useState(false)

  const confirmEmail = async () => {
    if (!email || !code) {
      return
    }

    try {
      const response = await confirmEmailRequest({ email, code })
      if (!response.data) {
        return
      }

      const tokenStorage = TokenStorage.getInstance()
      tokenStorage.setPaymentAccessToken(response.data.accessToken)

      setConfirmed(true)
    } catch (error) {
      if (error?.response?.data?.error === 'Code was expired') {
        setExpired(true)
      }
    }
  }

  useEffect(() => {
    void confirmEmail()
  }, [email, code])

  return { confirmed, expired }
}
