import { axiosExtended } from 'api/axios'

import { getRemoveWatchlistUrl } from '../urls'
import { RemoveWatchlistResponse } from './removeWatchlist.types'

export const removeWatchlist = async (auctionId: number) => {
  const { href } = getRemoveWatchlistUrl(auctionId)
  const response = await axiosExtended.delete<RemoveWatchlistResponse>(href, {
    withAuth: true,
  })
  return response.data
}
