import { axiosExtended } from 'api/axios'

import { getOrderAsAdminUrl } from '../urls'
import { GetOrderAsAdminResponse } from './getOrderAsAdmin.types'

export const getOrderAsAdmin = async (id: string) => {
  const { href } = getOrderAsAdminUrl(id)
  const response = await axiosExtended.get<GetOrderAsAdminResponse>(href, {
    withAuth: true,
  })
  return response.data
}
