import {
  approveUserAsAdmin,
  deleteUserAsAdmin,
  rejectUserAsAdmin,
  setUserSettingsAsAdmin,
  verifyUserEmailAsAdmin as verifyUserEmailAsAdminApi,
} from 'api/requests/admin'
import { getUserList, GetUserListData } from 'api/requests/user'
import { Action, TableData } from 'components/Datatable'
import { debounce } from 'lodash'
import { useRouter } from 'next/router'
import { ChangeEvent, ChangeEventHandler, useCallback, useState } from 'react'
import {
  UserDeletionStatuses,
  UserRegistrationStatuses,
  UserStatuses,
} from 'types/enums'
import { renderUserStatus } from 'utils/admin'
import { formatDate } from 'utils/format'
import { smoothScroll } from 'utils/scroll'

import { useOrdering } from './useOrdering'

export const useAdminUsers = (setOpen?: (value: boolean) => void) => {
  const router = useRouter()

  const [filter, setFilter] = useState('all')
  const [data, setData] = useState<TableData | undefined>()

  const [total, setTotal] = useState<number>(0)
  const [currentUserId, setCurrentUserId] = useState<string | undefined>()
  const [reason, setReason] = useState<string>('')

  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  const [search, setSearch] = useState<string>('')

  const perPage = 25

  const fetchUserList = async (query?: GetUserListData) => {
    const userList = await getUserList(query)
    const listData = userList?.data
    const items = listData?.items

    if (items) {
      const currentData = {
        rows: items.map(item => {
          const {
            id,
            firstName,
            lastName,
            email,
            businessName,
            roleCode,
            createdAt,
            registrationStatus,
            deletionStatus,
            isEmailConfirmed,
            isEnabled,
          } = item
          return {
            cells: [
              { title: id },
              { title: firstName, isTextEllipsis: true, maxCellWidth: '212px' },
              { title: lastName, isTextEllipsis: true, maxCellWidth: '212px' },
              { title: email, isTextEllipsis: true, maxCellWidth: '212px' },
              {
                title: createdAt
                  ? formatDate(new Date(createdAt), 'MMMM dd, yyyy')
                  : '',
              },
              {
                title: businessName,
                isTextEllipsis: true,
                maxCellWidth: '212px',
              },
              {
                title: roleCode,
                isTextEllipsis: true,
                maxCellWidth: '80px',
              },
              {
                title: renderUserStatus(item),
                isStatus: true,
                textAlign: 'right',
              },
            ],
            isActionRequired:
              isEmailConfirmed &&
              (registrationStatus === UserRegistrationStatuses.Pending ||
                deletionStatus === UserDeletionStatuses.Pending),
            showActions: deletionStatus !== UserDeletionStatuses.Deleted,
            availableActions: !isEmailConfirmed
              ? [Action.verifyEmail]
              : [Action.accept, Action.reject, Action.adminEdit, Action.delete],
            isSuspended: !isEnabled,
            id,
          }
        }),
      }
      setData(currentData)
      if (listData?.total) {
        setTotal(listData?.total)
        setCount(Math.ceil(listData?.total / perPage))
      }
    }
  }

  const { orderBy, order, handleRequestSort } = useOrdering()

  const debouncedSearch = useCallback(debounce(fetchUserList, 500), [])

  const fetchUserListWithQuery = async () => {
    const query: GetUserListData = {
      perPage,
      page,
    }
    if (filter && filter !== 'all') {
      query.status = filter as keyof typeof UserStatuses
    }
    if (search?.length) {
      query.search = search
    }
    if (order) {
      query.orderType = order
    }
    if (orderBy) {
      query.orderBy = orderBy
    }
    await debouncedSearch(query)
  }

  const handleVerifyUserEmail = async (userId: number | string) => {
    try {
      await verifyUserEmailAsAdminApi({ userId })
      await fetchUserListWithQuery()
    } catch (e) {
      console.error(e)
    }
  }

  const handleAcceptUser = async (userId: number | string) => {
    await approveUserAsAdmin({ userId })
    await fetchUserListWithQuery()
  }

  const handleRejectUser = (userId: number | string) => {
    setOpen?.(true)
    setCurrentUserId(userId as string)
  }

  const deleteClickHandler = async (id?: number | string) => {
    if (id) {
      try {
        await deleteUserAsAdmin(id as number)
        await fetchUserListWithQuery()
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleStatusChange = (value: string) => {
    setPage(1)
    setFilter(value)
  }

  const rejectUserClickHandler = async () => {
    try {
      if (currentUserId) {
        await rejectUserAsAdmin({ userId: currentUserId, reason })
        await fetchUserListWithQuery()
        setOpen?.(false)
      }
    } catch (e) {}
  }

  const handleReasonChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setReason(event.target.value)
  }

  const handleRowClick = async (id: string | number) => {
    await router.push(`/admin/users/${id}`)
  }

  const query: GetUserListData = {
    perPage,
    page,
  }

  const handleSuspendAccount = async (
    isEnabled: boolean,
    id?: string | number
  ) => {
    if (id) {
      try {
        await setUserSettingsAsAdmin({
          userId: id,
          isEnabled,
        })

        await fetchUserList(query)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const actionMenuItems = [
    {
      title: 'Suspend',
      clickHandler: (id?: string | number) => handleSuspendAccount(false, id),
      isDisabledForUnverified: true,
    },
    {
      title: 'Unsuspend',
      clickHandler: (id?: string | number) => handleSuspendAccount(true, id),
      isDisabledForUnverified: true,
    },
    { title: 'Delete', clickHandler: deleteClickHandler },
  ]

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    smoothScroll()
  }

  const handleSearchChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    const value = e.target.value
    setSearch(value)
  }

  const handleClearSearchClick = () => {
    setSearch('')
  }

  return {
    filter,
    data,
    total,
    reason,
    perPage,
    order,
    orderBy,
    handleRequestSort,
    fetchUserList,
    fetchUserListWithQuery,
    handleRejectUser,
    handleStatusChange,
    rejectUserClickHandler,
    handleReasonChange,
    handleRowClick,
    handleVerifyUserEmail,
    handleAcceptUser,
    handleChangePage,
    handleSearchChange,
    handleClearSearchClick,
    page,
    count,
    actionMenuItems,
    search,
  }
}
