import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { GetLocationItemData } from './getLocation'
import { RemoveLocationData } from './removeLocation'

export const getCreateLocationUrl = () => {
  const url = `${API_URL}/user/location/create`
  return new URL(url)
}

export const getEditLocationUrl = () => {
  const url = `${API_URL}/user/location/update`
  return new URL(url)
}

export const getLocationListUrl = () => {
  const url = `${API_URL}/user/location/list`
  return new URL(url)
}

export const getLocationUrl = (query: GetLocationItemData) => {
  const url = `${API_URL}/user/location/get`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getRemoveLocationUrl = (query: RemoveLocationData) => {
  const url = `${API_URL}/user/location/delete`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getLocationTypesUrl = () => {
  const url = `${API_URL}/user/locationType/list`
  return new URL(url)
}
