import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const BigSpinnerIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M31.995 9.33C19.477 9.33 9.33 19.477 9.33 31.995c0 12.518 10.147 22.665 22.665 22.665 12.518 0 22.665-10.147 22.665-22.665h4c0 14.727-11.938 26.665-26.665 26.665-14.727 0-26.665-11.938-26.665-26.665C5.33 17.268 17.268 5.33 31.995 5.33c6 0 11.543 1.985 16 5.332l-2.402 3.198a22.555 22.555 0 0 0-13.598-4.53Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
