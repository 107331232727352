import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { DeleteLotData } from './deleteLot'
import { DownloadLotAssetsData } from './downloadLotAssets'
import { GetLotData } from './getLot'
import { GetLotListData } from './getLotList'

export const getCreateLotUrl = (): URL => {
  const url = `${API_URL}/auction/lot/create`
  return new URL(url)
}

export const getLotUrl = (params?: GetLotData): URL => {
  const url = `${API_URL}/auction/lot/`
  if (!params) {
    return new URL(url)
  }

  const { lotId } = params
  return new URL(url + String(lotId))
}

export const getDeleteLotUrl = (params?: DeleteLotData): URL => {
  const url = `${API_URL}/auction/lot/delete/`
  if (!params) {
    return new URL(url)
  }

  const { lotId } = params
  return new URL(url + String(lotId))
}

export const getEditLotDetailsUrl = (lotId: string): URL => {
  const url = `${API_URL}/auction/lot/${lotId}`
  return new URL(url)
}

export const getGetLotListUrl = (query?: GetLotListData) => {
  const url = `${API_URL}/auction/lot/list`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getCreateAuctionFromLot = () => {
  const url = `${API_URL}/auction/lot/publish`
  return new URL(url)
}

export const getDeleteLotFilesUrl = (lotId: string, photoId: string): URL => {
  const url = `${API_URL}/auction/lot/${lotId}/photo/delete/${photoId}`
  return new URL(url)
}

export const getUploadLotFilesUrl = (lotId: string) => {
  const url = `${API_URL}/auction/lot/${lotId}/photo`
  return new URL(url)
}

export const getLotPhotosListUrl = (lotId: string) => {
  const url = `${API_URL}/auction/lot/${lotId}/photos`
  return new URL(url)
}

export const getDownloadLotAssetsUrl = (query?: DownloadLotAssetsData) => {
  const url = `${API_URL}/auction/file`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}
