import { uniq } from 'lodash'
import { AuctionCreateTemplate, AuctionTemplateRule } from 'types/auction'
import { normalizeString } from 'utils/string'

const getUniqueColumnNamesOfTemplate = (template: AuctionCreateTemplate[]) => {
  return uniq(template.map(row => Object.keys(row)).flat())
}

const getRowValuesByColumnName = (
  row: AuctionCreateTemplate,
  columnName: string
) => {
  return Object.entries(row)
    .filter(([column]) => normalizeString(column) === columnName)
    .map(([, value]) => value)
}

const getTemplateValuesByColumnName = (
  template: AuctionCreateTemplate[],
  columnName: string
) => {
  return template.map(row => getRowValuesByColumnName(row, columnName)).flat()
}

const getDuplicateValuesByColumnName = (
  template: AuctionCreateTemplate[],
  columnName: string
) => {
  const values: string[] = getTemplateValuesByColumnName(
    template,
    columnName
  ) as string[]
  const columnNameValuesObject = values.reduce((acc, value) => {
    return {
      ...acc,
      [value]: !acc[value] ? 1 : (acc[value] as number) + 1,
    }
  }, {} as AuctionCreateTemplate)
  return Object.entries(columnNameValuesObject)
    .filter(([_, quantity]) => (quantity as number) > 1)
    .flat()
    .filter(value => typeof value === 'string')
}

export const getParsedAuctionTemplate = (
  template: AuctionCreateTemplate[],
  rules: AuctionTemplateRule[]
) => {
  let isTemplateValid = true

  const availableColumnNames = rules.map(rule => rule.name)
  const validColumnNames = getUniqueColumnNamesOfTemplate(template).filter(
    columnName => {
      return availableColumnNames.some(
        validColumnName =>
          normalizeString(validColumnName) === normalizeString(columnName)
      )
    }
  )
  const requiredColumnNames = rules
    .filter(rule => rule.isRequired)
    .map(rule => rule.name)
  const isAllRequiredColumnsExist = requiredColumnNames.every(
    requiredColumnName => {
      return validColumnNames.some(
        templateColumnName =>
          normalizeString(templateColumnName) ===
          normalizeString(requiredColumnName)
      )
    }
  )

  if (!isAllRequiredColumnsExist) {
    isTemplateValid = false
  }

  const dublicateSerials = getDuplicateValuesByColumnName(
    template,
    'serial number'
  )

  let unmatchedRows = 0
  let totalItems = 0

  const modifiedTemplate = template.reduce((acc, row) => {
    const columnNamesInRow = Object.keys(row)
    const isAllRequiredFieldsFilled = requiredColumnNames.every(
      requiredColumnName =>
        columnNamesInRow.some(
          column =>
            normalizeString(column) === normalizeString(requiredColumnName)
        )
    )
    if (!isAllRequiredFieldsFilled) {
      unmatchedRows += 1
      return acc
    }

    let isRowWithErrors = false
    const modifiedRow = validColumnNames.reduce((newRow, columnName) => {
      const value = row[columnName] ?? ''

      if (
        normalizeString(columnName) === 'serial number' &&
        dublicateSerials.includes(value)
      ) {
        isRowWithErrors = true
        unmatchedRows += 1
        return newRow
      }

      const rulesForColumn = rules.find(
        rule => normalizeString(rule.name) === normalizeString(columnName)
      )
      if (
        rulesForColumn?.allowedValues &&
        !rulesForColumn.allowedValues.some(
          allowedValue =>
            normalizeString(allowedValue) === normalizeString(value as string)
        )
      ) {
        isRowWithErrors = true
        unmatchedRows += 1
        return newRow
      }

      newRow[columnName] = value
      return newRow
    }, {} as AuctionCreateTemplate)

    if (isRowWithErrors) {
      return acc
    }

    const quantity =
      getRowValuesByColumnName(modifiedRow, 'quantity').reduce<number>(
        (acc, value) => {
          const quantity = Number(value) || 1
          return acc + quantity
        },
        0
      ) || 1
    totalItems += quantity

    acc.push(modifiedRow as never)

    return acc
  }, [])

  return {
    template: modifiedTemplate,
    columns: validColumnNames,
    totalItems,
    unmatchedRows,
    isTemplateValid,
  }
}
