import { PackageType } from 'types/enums'
import { Package } from 'types/lot'

export const getPackagesTitle = (packages: Package<number>[]) => {
  const total: Record<PackageType, number> = {
    pallet: 0,
    box: 0,
  }
  let mixedCounter = 0
  let currentPackage = ''

  packages?.forEach(({ type }) => total[type]++)

  Object.keys(total).forEach(key => {
    if (total[key as PackageType]) {
      mixedCounter++
      switch (key) {
        case 'pallet':
          currentPackage = total[key] > 1 ? 'Pallets' : 'Pallet'
          break
        case 'box':
          currentPackage = total[key] > 1 ? 'Boxes' : 'Box'
          break
      }
    }
  })

  return mixedCounter > 1 ? 'Mixed' : `${packages?.length} ${currentPackage}`
}
