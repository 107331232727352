import { Order } from 'components/Datatable'
import { MouseEvent, useState } from 'react'

export const useOrdering = (
  initialOrder: Order = 'desc',
  initialOrderBy = 'createdAt'
) => {
  const [order, setOrder] = useState<Order>(initialOrder)
  const [orderBy, setOrderBy] = useState(initialOrderBy)

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return { order, orderBy, handleRequestSort }
}
