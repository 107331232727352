export const formatTimeToAmPm = (time: string) => {
  const [hours, minutes] = time.split(':')
  let currentHours = `${Number(hours)}`
  let postfix = 'am'

  if (Number(hours) >= 12) {
    currentHours = `${Number(hours) - 12}`
    postfix = 'pm'
  }

  return `${[currentHours, minutes].join(':')} ${postfix}`
}
