export const pickupTimesMock = [
  { label: '0 hours', value: 0 },
  { label: '1 hours', value: 1 },
  { label: '2 hours', value: 2 },
  { label: '3 hours', value: 3 },
  { label: '4 hours', value: 4 },
  { label: '5 hours', value: 5 },
  { label: '6 hours', value: 6 },
  { label: '7 hours', value: 7 },
  { label: '8 hours', value: 8 },
]
