import { CheckboxCustom } from 'components/Checkbox'
import React, { Dispatch, SetStateAction } from 'react'
import { Manufacturer } from 'types/lot'

export const renderManufacturer = (
  manufacturer: Manufacturer,
  selectedManufacturers: Manufacturer[],
  setSelectedManufacturers: Dispatch<SetStateAction<Manufacturer[]>>
) => {
  return (
    <CheckboxCustom
      key={manufacturer.id}
      label={manufacturer.value}
      sx={{
        ml: 0,
        '& .MuiTypography-root': {
          fontSize: '16px',
        },
      }}
      checked={selectedManufacturers.some(({ id }) => id === manufacturer.id)}
      onChange={(_, checked) => {
        setSelectedManufacturers(oldSelectedManufacturers => {
          return checked
            ? [...oldSelectedManufacturers, manufacturer]
            : oldSelectedManufacturers.filter(
                oldManufacturer => oldManufacturer.id !== manufacturer.id
              )
        })
      }}
    />
  )
}
