import { axiosExtended } from 'api/axios'

import { getManufacturersListUrl } from '../urls'
import {
  GetManufacturersListData,
  GetManufacturersListResponse,
} from './getManufacturersList.types'

export const getManufacturersList = async (
  query?: GetManufacturersListData
) => {
  const { href } = getManufacturersListUrl(query)
  const response = await axiosExtended.get<GetManufacturersListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
