import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const PatternIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper
    width={808}
    height={1431}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g fill='currentColor' fillRule='nonzero'>
      <path
        fillOpacity={0.32}
        d='M0 0h196v72H0zM204 0h196v72H204zM408 0h196v72H408zM612 0h196v72H612zM0 80h196v72H0zM204 80h196v72H204zM408 80h196v72H408zM612 80h196v72H612zM0 160h196v72H0z'
      />
      <path d='M204 160h196v72H204zM408 160h196v72H408z' />
      <path fillOpacity={0.32} d='M612 160h196v72H612zM0 240h196v72H0z' />
      <path d='M204 240h196v72H204zM408 240h196v72H408z' />
      <path
        fillOpacity={0.32}
        d='M612 240h196v72H612zM0 320h196v72H0zM204 320h196v72H204zM408 320h196v72H408zM612 320h196v72H612zM0 400h196v72H0zM204 400h196v72H204zM408 400h196v72H408zM612 400h196v72H612zM0 480h196v72H0zM204 480h196v72H204zM408 480h196v72H408zM612 480h196v72H612z'
      />
      <path d='M0 560h196v72H0z' />
      <path
        fillOpacity={0.32}
        d='M204 560h196v72H204zM408 560h196v72H408zM612 560h196v72H612zM0 640h196v72H0zM204 640h196v72H204zM408 640h196v72H408z'
      />
      <path d='M612 640h196v72H612z' />
      <path fillOpacity={0.32} d='M0 720h196v72H0zM204 720h196v72H204z' />
      <path d='M408 720h196v72H408z' />
      <path
        fillOpacity={0.32}
        d='M612 720h196v72H612zM0 800h196v72H0zM204 800h196v72H204zM408 800h196v72H408zM612 800h196v72H612zM0 880h196v72H0zM204 880h196v72H204zM408 880h196v72H408zM612 880h196v72H612zM0 960h196v72H0zM204 960h196v72H204zM408 960h196v72H408zM612 960h196v72H612z'
      />
      <path d='M0 1040h196v72H0zM204 1040h196v72H204z' />
      <path
        fillOpacity={0.32}
        d='M408 1040h196v72H408zM612 1040h196v72H612zM0 1120h196v72H0zM204 1120h196v72H204zM408 1120h196v72H408zM612 1120h196v72H612zM0 1200h196v72H0zM204 1200h196v72H204zM408 1200h196v72H408zM612 1200h196v72H612zM0 1279h196v72H0zM204 1279h196v72H204zM408 1279h196v72H408zM612 1279h196v72H612zM0 1359h196v72H0zM204 1359h196v72H204zM408 1359h196v72H408zM612 1359h196v72H612z'
      />
    </g>
  </SvgWrapper>
)
