import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { createLocation } from '../api/requests/location'
import { validationLocationSchema } from '../constants/settings'
import { Location } from '../types/location'
import { ScheduleByDay } from '../types/shared'
import { formatAmPmTo24 } from '../utils/locations'

export const useLocationForm = () => {
  const {
    control: LocationControl,
    formState: LocationState,
    setValue: LocationSetValue,
    getValues: LocationGetValues,
    reset: LocationReset,
  } = useForm<Location>({
    mode: 'all',
    defaultValues: {
      locationTypeId: 2,
      name: '',
      address: '',
      apartment: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      contactFullname: '',
      contactPhoneNumber: '',
      hasLoadingDock: false,
      instruction: '',
      isDefault: false,
    },
    resolver: yupResolver(validationLocationSchema),
  })

  const {
    control: SchedulesControl,
    formState: SchedulesState,
    setValue: SchedulesSetValue,
    getValues: SchedulesGetValues,
    reset: SchedulesReset,
    watch: SchedulesWatch,
  } = useForm<ScheduleByDay>({
    mode: 'all',
    defaultValues: {
      sunday: {
        day: 'sunday',
        startTime: '7:00 am',
        endTime: '5:00 pm',
        dayOff: true,
      },
      monday: {
        day: 'monday',
        startTime: '7:00 am',
        endTime: '5:00 pm',
        dayOff: false,
      },
      tuesday: {
        day: 'tuesday',
        startTime: '7:00 am',
        endTime: '5:00 pm',
        dayOff: false,
      },
      wednesday: {
        day: 'wednesday',
        startTime: '7:00 am',
        endTime: '5:00 pm',
        dayOff: false,
      },
      thursday: {
        day: 'thursday',
        startTime: '7:00 am',
        endTime: '5:00 pm',
        dayOff: false,
      },
      friday: {
        day: 'friday',
        startTime: '7:00 am',
        endTime: '5:00 pm',
        dayOff: false,
      },
      saturday: {
        day: 'saturday',
        startTime: '7:00 am',
        endTime: '5:00 pm',
        dayOff: true,
      },
    },
  })

  const prepareLocationRequestData = () => {
    const locationData = LocationGetValues()
    const schedulesData = SchedulesGetValues()
    return {
      location: locationData,
      schedules: [
        {
          ...schedulesData.sunday,
          startTime: formatAmPmTo24(schedulesData.sunday.startTime),
          endTime: formatAmPmTo24(schedulesData.sunday.endTime),
        },
        {
          ...schedulesData.monday,
          startTime: formatAmPmTo24(schedulesData.monday.startTime),
          endTime: formatAmPmTo24(schedulesData.monday.endTime),
        },
        {
          ...schedulesData.tuesday,
          startTime: formatAmPmTo24(schedulesData.tuesday.startTime),
          endTime: formatAmPmTo24(schedulesData.tuesday.endTime),
        },
        {
          ...schedulesData.wednesday,
          startTime: formatAmPmTo24(schedulesData.wednesday.startTime),
          endTime: formatAmPmTo24(schedulesData.wednesday.endTime),
        },
        {
          ...schedulesData.thursday,
          startTime: formatAmPmTo24(schedulesData.thursday.startTime),
          endTime: formatAmPmTo24(schedulesData.thursday.endTime),
        },
        {
          ...schedulesData.friday,
          startTime: formatAmPmTo24(schedulesData.friday.startTime),
          endTime: formatAmPmTo24(schedulesData.friday.endTime),
        },
        {
          ...schedulesData.saturday,
          startTime: formatAmPmTo24(schedulesData.saturday.startTime),
          endTime: formatAmPmTo24(schedulesData.saturday.endTime),
        },
      ],
    }
  }

  const handleLocationSubmit = async () => {
    const requestData = prepareLocationRequestData()
    await createLocation(requestData)
    LocationReset()
    SchedulesReset()
  }

  return {
    LocationControl,
    LocationState,
    LocationReset,
    LocationSetValue,
    LocationGetValues,
    SchedulesControl,
    SchedulesState,
    SchedulesReset,
    SchedulesSetValue,
    SchedulesGetValues,
    SchedulesWatch,
    prepareLocationRequestData,
    handleLocationSubmit,
  }
}
