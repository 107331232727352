import { APP_URL } from 'constants/env'
import { isBrowser } from 'utils/environment'

export const getCurrentUrl = (): URL => {
  if (isBrowser()) {
    return new URL(window.location.href)
  }

  return new URL(APP_URL)
}
