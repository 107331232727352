export const getSearchParamsFromObject = (object: object) => {
  const params = new URLSearchParams()

  Object.entries(object).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        params.append(key, String(item))
      })
    } else {
      params.append(key, String(value))
    }
  })

  return params.toString()
}
