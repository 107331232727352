import { axiosExtended } from 'api/axios'

import { getGetLotListUrl } from '../urls'
import { GetLotListData, GetLotListResponse } from './getLotList.types'

export const getLotList = async (data?: GetLotListData) => {
  const { href } = getGetLotListUrl(data)
  const response = await axiosExtended.get<GetLotListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
