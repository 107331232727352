import { axiosExtended } from 'api/axios'

import { getFetchTransactionsListUrl } from '../urls'
import {
  FetchTransactionsListData,
  FetchTransactionsListResponse,
} from './fetchTransactionsList.types'

export const fetchTransactionsList = async (
  query?: FetchTransactionsListData
) => {
  const { href } = getFetchTransactionsListUrl(query)
  const response = await axiosExtended.get<FetchTransactionsListResponse>(
    href,
    {
      withAuth: true,
    }
  )
  return response.data
}
