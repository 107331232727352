import { axiosExtended } from 'api/axios'

import { getSetNewPasswordUrl } from '../urls'
import {
  SetNewPasswordData,
  SetNewPasswordResponse,
} from './setNewPassword.types'

export const setNewPassword = async (data: SetNewPasswordData) => {
  const { href } = getSetNewPasswordUrl()
  const response = await axiosExtended.post<SetNewPasswordResponse>(href, data)
  return response.data
}
