import { axiosExtended } from 'api/axios'

import { getUserAsAdminUrl } from '../urls'
import {
  GetUserAsAdminData,
  GetUserAsAdminResponse,
} from './getUserAsAdmin.types'

export const getUserAsAdmin = async (data: GetUserAsAdminData) => {
  const { href } = getUserAsAdminUrl(data)
  const response = await axiosExtended.get<GetUserAsAdminResponse>(href, {
    withAuth: true,
  })
  return response.data
}
