import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const VisaLogo: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='m17.172 9.201-5.723 13.19H7.704L4.878 11.866c-.141-.615-.353-.888-.848-1.161-.847-.41-2.26-.889-3.462-1.094l.071-.41h6.006c.777 0 1.483.479 1.625 1.367l1.483 7.654 3.674-8.953h3.745v-.068Zm14.625 8.884c0-3.485-4.946-3.69-4.946-5.193 0-.479.495-.957 1.484-1.094.494-.068 1.907-.137 3.462.615l.636-2.802c-.848-.273-1.908-.615-3.321-.615-3.462 0-5.935 1.777-5.935 4.374 0 1.914 1.767 2.939 3.109 3.554 1.342.615 1.837 1.025 1.837 1.64 0 .888-1.06 1.298-2.12 1.298-1.766 0-2.826-.478-3.603-.82l-.636 2.87c.848.342 2.332.684 3.886.684 3.674 0 6.076-1.777 6.147-4.51Zm9.185 4.306h3.25l-2.826-13.19h-3.039c-.706 0-1.271.41-1.483.957l-5.3 12.233h3.675l.706-1.982h4.522l.495 1.982Zm-3.957-4.647 1.837-4.92 1.06 4.92h-2.897ZM22.188 9.2l-2.897 13.19H15.76l2.896-13.19h3.533Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
