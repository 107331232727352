import { Tooltip, Typography } from '@mui/material'
import { useIsOverflow } from 'hooks'
import * as React from 'react'
import { FC } from 'react'

import { Props } from './TooltipForEllipsis.types'

export const TooltipForEllipsis: FC<Props> = ({
  title,
  tooltipText = title,
  forEllipsis = true,
  ...restProps
}) => {
  const textRef = React.useRef<HTMLElement>(null)
  const isOverflow = useIsOverflow(textRef)

  return (
    <Tooltip
      title={tooltipText}
      disableHoverListener={forEllipsis && !isOverflow}
      placement='bottom-start'
    >
      <Typography
        ref={textRef}
        textTransform='capitalize'
        whiteSpace='nowrap'
        overflow='hidden'
        textOverflow='ellipsis'
        {...restProps}
      >
        {title}
      </Typography>
    </Tooltip>
  )
}
