import { axiosExtended } from 'api/axios'
import { serialize } from 'object-to-formdata'

import { getCreateAuctionUrl } from '../urls'
import { CreateAuctionResponse } from './createAuction.types'

// TODO: add corresponding type for the request data
// eslint-disable-next-line
export const createAuction = async (data: any) => {
  const { href } = getCreateAuctionUrl()
  const response = await axiosExtended.post<CreateAuctionResponse>(
    href,
    serialize(data),
    {
      withAuth: true,
    }
  )
  return response.data
}
