import { downloadPackingList } from 'api/requests/order/downloadPackingList'
import { saveAs } from 'file-saver'

export const handleDownloadPackingList = async (orderId?: string) => {
  if (orderId) {
    try {
      const response = await downloadPackingList(Number(orderId))
      // TODO: Need to figure out what's wrong with axios types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      saveAs(response, `packing-list-${orderId}`)
    } catch (e) {
      console.error(e)
    }
  }
}
