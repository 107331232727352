import { yupResolver } from '@hookform/resolvers/yup'
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker'
import {
  closeAuctionAsAdmin,
  setAuctionSettingsAsAdmin,
  SetAuctionSettingsAsAdminData,
} from 'api/requests/admin'
import {
  getAuctionDetailsAsAdmin,
  GetAuctionDetailsAsAdminResponse,
} from 'api/requests/admin/getAuctionDetailsAsAdmin'
import { TableData } from 'components/Datatable'
import { validationAuctionSettingSchema } from 'constants/adminAuctionSetting'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AdminAuctionBid } from 'types/admin'
import { formatCurrency, formatDate } from 'utils/format'

export const useAdminSetAuctionSettings = (auctionId: string) => {
  const [dateValues, setDateValues] = useState<DateRange<Date>>([null, null])
  const [auctionData, setAuctionData] = useState<
    Partial<GetAuctionDetailsAsAdminResponse>
  >({})
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [bids, setBids] = useState<AdminAuctionBid[]>([])
  const [ordersData, setOrdersData] = useState<TableData | undefined>()

  const status = auctionData?.status
  const isScheduled = status === 'scheduled'
  const isActive = status === 'active'
  const isAwaiting = status === 'awaiting'

  const isEditable =
    (isActive && auctionData?.bids?.length === 0) || isScheduled
  const isClosable = isEditable || isAwaiting

  const {
    control,
    setValue,
    getValues,
    watch: watchValue,
    formState,
  } = useForm<SetAuctionSettingsAsAdminData>({
    mode: 'all',
    resolver: yupResolver(validationAuctionSettingSchema),
  })

  const setValuesToForm = (data: GetAuctionDetailsAsAdminResponse) => {
    const start = new Date(data.startDate)
    const end = new Date(data.endDate)
    const startTime = formatDate(start, 'h:mm aaa')
    const endTime = formatDate(end, 'h:mm aaa')

    const options = { shouldValidate: true }
    setDateValues([start, end])
    setValue('startTime', startTime, options)
    setValue('endTime', endTime, options)
    setValue('startingBidPrice', data.startingBidPrice, options)
    setValue('reservePrice', data.reservePrice, options)
    setValue('minBidIncrement', data.minBidIncrement, options)
  }

  const fetchAuctionData = async () => {
    try {
      setIsLoading(true)
      const auction = await getAuctionDetailsAsAdmin({ auctionId })
      setIsLoading(false)

      const data = auction?.data

      if (data) {
        setAuctionData(data)
        setValuesToForm(data)

        if (data?.bids) {
          setBids(data.bids)
        }

        if (data?.order) {
          const { id, createdAt, createdBy, status, customer, totalAmount } =
            data.order
          const rows = [
            {
              cells: [
                {
                  title: createdAt
                    ? formatDate(new Date(createdAt), 'M/dd/yyyy, p')
                    : 'N/A',
                },
                {
                  title: customer,
                },
                { title: formatCurrency(totalAmount, { withPrefix: true }) },
                { title: createdBy },
                {
                  title: status,
                  isStatus: true,
                  textAlign: 'right',
                },
              ],
              id,
              linkTo: `/admin/orders/${id}`,
            },
          ]
          setOrdersData({ rows })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const resetForm = () => {}

  useEffect(() => {
    if (auctionId) {
      void fetchAuctionData()
    }
  }, [auctionId])

  const handleEditAuction = async () => {
    const {
      startTime,
      endTime,
      startingBidPrice,
      reservePrice,
      minBidIncrement,
    } = getValues()

    const requestData: Partial<SetAuctionSettingsAsAdminData> = {
      id: Number(auctionId),
      startingBidPrice,
      reservePrice,
      minBidIncrement,
    }

    if (dateValues[0]) {
      const startDateValue: Date = dateValues[0]
      requestData.startDate = new Date(
        new Date(
          `${
            startDateValue.getMonth() + 1
          } ${startDateValue.getDate()} ${startDateValue.getFullYear()} ${startTime}`
        ).toUTCString()
      ).getTime()
    }

    if (dateValues[1]) {
      const endDateValue: Date = dateValues[1]
      requestData.endDate = new Date(
        new Date(
          `${
            endDateValue.getMonth() + 1
          } ${endDateValue.getDate()} ${endDateValue.getFullYear()} ${endTime}`
        ).toUTCString()
      ).getTime()
    }

    await setAuctionSettingsAsAdmin(requestData)
    await fetchAuctionData()
  }

  const handleCancelAuctionClick = async () => {
    try {
      await closeAuctionAsAdmin({ auctionId: Number(auctionId) })
      await fetchAuctionData()
    } catch (e) {
      console.error(e)
    }
  }

  return {
    control,
    watchValue,
    auctionData,
    bids,
    ordersData,
    isLoading,
    formState,
    isScheduled,
    isEditable,
    isClosable,
    handleEditAuction,
    handleCancelAuctionClick,
    fetchAuctionData,
    resetForm,
    dateValues,
    setDateValues,
  }
}
