import { axiosExtended } from 'api/axios'

import { getLocationUrl } from '../urls'
import {
  GetLocationItemData,
  GetLocationItemResponse,
} from './getLocation.types'

export const getLocation = async (data: GetLocationItemData) => {
  const { href } = getLocationUrl(data)
  const response = await axiosExtended.get<GetLocationItemResponse>(href, {
    withAuth: true,
  })
  return response.data
}
