import { integerNumbersRegex } from 'utils/regex'
import * as Yup from 'yup'

export const validationAuctionSettingSchema = Yup.object().shape({
  startDate: Yup.number(),
  endDate: Yup.number(),
  startingBidPrice: Yup.string()
    .trim()
    .matches(integerNumbersRegex, 'Only numbers accepted'),
  minBidIncrement: Yup.string()
    .trim()
    .matches(integerNumbersRegex, 'Only numbers accepted'),
  reservePrice: Yup.string()
    .trim()
    .matches(integerNumbersRegex, 'Only numbers accepted'),
})
