import { axiosExtended } from 'api/axios'

import { getSetAuctionAsAdminWinnerUrl } from '../urls'
import {
  SetAuctionAsAdminWinnerData,
  SetAuctionAsAdminWinnerResponse,
} from './setAuctionAsAdminWinner.types'

export const setAuctionAsAdminWinner = async (
  data: SetAuctionAsAdminWinnerData
) => {
  const { href } = getSetAuctionAsAdminWinnerUrl()
  const response = await axiosExtended.put<SetAuctionAsAdminWinnerResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
