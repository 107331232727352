import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const NewWindowIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path d='M3 5.002h8v2H5v12h12v-6h2v8H3v-16Z' fill='currentColor' />
    <path
      d='M17.586 5.002H13v-2h8v8h-2V6.416l-7.293 7.293-1.414-1.414 7.293-7.293Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
