import { UserDeletionStatuses, UserRegistrationStatuses } from 'types/enums'
import { AdminUserSetting } from 'types/settings'
import { User } from 'types/user'

export const renderUserStatus = ({
  deletionStatus,
  registrationStatus,
  isEmailConfirmed,
  isEnabled,
}: User | Partial<AdminUserSetting>) => {
  if (!isEmailConfirmed) {
    if (registrationStatus === UserRegistrationStatuses.Invited) {
      return 'invited'
    } else {
      return 'verification'
    }
  }
  
  if (!isEnabled) {
    return 'suspended'
  }

  if (deletionStatus === UserDeletionStatuses.Deleted) {
    return 'deleted'
  }

  if (
    registrationStatus === UserRegistrationStatuses.Approved &&
    deletionStatus !== UserDeletionStatuses.Pending
  ) {
    return 'accepted'
  }

  if (registrationStatus === UserRegistrationStatuses.Rejected) {
    return 'rejected'
  }

  return 'action_required'
}
