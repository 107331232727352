import { axiosExtended } from 'api/axios'

import { getResetPasswordAsAdminUrl } from '../urls'

export const resetPasswordAsAdmin = async (id: string | number) => {
  const { href } = getResetPasswordAsAdminUrl(id)
  const response = await axiosExtended.get(href, {
    withAuth: true,
  })
  return response.data
}
