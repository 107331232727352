import { Button, Skeleton } from '@mui/material'
import React, { FC } from 'react'

import { Props } from './ButtonWithIcon.types'

export const ButtonWithIcon: FC<Props> = ({
  Icon,
  title,
  children,
  variant = 'outlined',
  size = 'medium',
  isLinkButton = false,
  loading,
  ...props
}) => {
  const outlinedStyles = {
    border: '1px solid rgba(34, 34, 34, 0.12)',
    height: '48px',
    padding: '18px 22px',
    fontWeight: 400,
    backgroundColor: 'white',
    '&:hover': {
      borderColor: 'grey.300',
      backgroundColor: 'white',
    },
    '&:active': {
      backgroundColor: 'grey.50',
    },
  }
  const textStyles = {
    '&:hover': {
      backgroundColor: 'grey.50',
    },
    '&:active': {
      backgroundColor: 'grey.100',
    },
  }
  const textSmall = {
    height: isLinkButton ? 'auto' : '40px',
    padding: '8px 16px',
  }

  return (
    <>
      {loading ? (
        <Skeleton
          sx={{
            '.MuiButton-startIcon': {
              mr: isLinkButton ? '8px' : '12px',
            },
            fontSize: '16px',
            flexShrink: 0,
          }}
        >
          <Button
            {...props}
            variant={variant}
            sx={{
              '.MuiButton-startIcon': {
                mr: isLinkButton ? '8px' : '12px',
              },
              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                color: 'grey.400',
                svg: {
                  color: 'grey.400',
                },
              },
              color: 'text.primary',
              fontSize: '16px',
              flexShrink: 0,
              ...(variant === 'outlined' && outlinedStyles),
              ...(variant === 'text' && textStyles),
              ...(size === 'small' && textSmall),
              ...props.sx,
              ...(Boolean(isLinkButton) && {
                p: 0,
                fontSize: '14px',
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
                '&:active': {
                  backgroundColor: 'transparent',
                },
              }),
            }}
            startIcon={Icon}
          >
            {children || title}
          </Button>
        </Skeleton>
      ) : (
        <Button
          {...props}
          variant={variant}
          sx={{
            '.MuiButton-startIcon': {
              mr: isLinkButton ? '8px' : '12px',
            },
            '&.Mui-disabled': {
              backgroundColor: 'transparent',
              color: 'grey.400',
              svg: {
                color: 'grey.400',
              },
            },
            color: 'text.primary',
            fontSize: '16px',
            flexShrink: 0,
            ...(variant === 'outlined' && outlinedStyles),
            ...(variant === 'text' && textStyles),
            ...(size === 'small' && textSmall),
            ...props.sx,
            ...(Boolean(isLinkButton) && {
              p: 0,
              fontSize: '14px',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
              '&:active': {
                backgroundColor: 'transparent',
              },
            }),
          }}
          startIcon={Icon}
        >
          {children || title}
        </Button>
      )}
    </>
  )
}
