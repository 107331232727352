import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const ListViewIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 11V4H7V11H0ZM2 6H5V9H2V6Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 11V4H24V11H9ZM11 6H22V9H11V6Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 13V20H24V13H9ZM22 15H11V18H22V15Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 20V13H7V20H0ZM2 15H5V18H2V15Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
