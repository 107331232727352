import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const UploadDocumentIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M13 11h-2v3H8v2h3v3h2v-3h3v-2h-3v-3Zm1-9H4v20h16V8l-6-6Zm4 18H6V4h7v5h5v11Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
