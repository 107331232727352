import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const SellerIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M3.025 21v-9.95c-.383-.35-.679-.8-.887-1.35-.209-.55-.213-1.15-.013-1.8l1.05-3.4c.133-.433.371-.792.713-1.075A1.803 1.803 0 0 1 5.075 3h13.9c.45 0 .842.137 1.175.412.333.275.575.638.725 1.088l1.05 3.4c.2.65.196 1.242-.012 1.775a3.958 3.958 0 0 1-.888 1.375V21h-18Zm11.2-11c.45 0 .792-.154 1.025-.463.233-.308.325-.654.275-1.037l-.55-3.5h-1.95v3.7c0 .35.117.654.35.913.233.258.517.387.85.387Zm-4.5 0c.383 0 .696-.129.938-.387.241-.259.362-.563.362-.913V5h-1.95l-.55 3.5c-.067.4.021.75.263 1.05.241.3.554.45.937.45Zm-4.45 0c.3 0 .562-.108.787-.325.225-.217.363-.492.413-.825L7.025 5h-1.95l-1 3.35c-.1.333-.046.692.162 1.075.209.383.555.575 1.038.575Zm13.5 0c.483 0 .833-.192 1.05-.575.217-.383.267-.742.15-1.075L18.925 5h-1.9l.55 3.85c.05.333.188.608.413.825.225.217.487.325.787.325Zm-13.75 9h14v-7.05a.606.606 0 0 1-.162.05h-.088c-.45 0-.846-.075-1.188-.225-.341-.15-.679-.392-1.012-.725a3.3 3.3 0 0 1-1.025.7c-.383.167-.792.25-1.225.25a3.193 3.193 0 0 1-2.3-.95 3.05 3.05 0 0 1-.987.7c-.375.167-.78.25-1.213.25-.483 0-.921-.083-1.313-.25a3.26 3.26 0 0 1-1.037-.7c-.35.35-.696.596-1.037.737-.342.142-.73.213-1.163.213h-.112a.254.254 0 0 1-.138-.05V19Zm0 0h14-14Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
