import { getStatusesList, GetStatusesListResponse } from 'api/requests/auction'
import { useEffect, useState } from 'react'
import { useQueryParam } from 'use-query-params'
import { CommaArrayParam } from 'utils/use-query-params'

export const useStatuses = (syncWithUrl = false) => {
  const [statusesQuery, setStatusesQuery] = useQueryParam(
    'statuses',
    CommaArrayParam
  )

  const [statuses, setStatuses] = useState<GetStatusesListResponse>()
  const [favoriteStatuses, setFavoriteStatuses] = useState<string[]>(() =>
    syncWithUrl ? statusesQuery?.map(status => String(status)) ?? [] : []
  )

  useEffect(() => {
    if (!syncWithUrl) {
      return
    }

    if (favoriteStatuses.length === 0) {
      setStatusesQuery(null)
      return
    }

    setStatusesQuery(favoriteStatuses)
  }, [favoriteStatuses, syncWithUrl])

  const fetchStatuses = async () => {
    try {
      const response = await getStatusesList()
      if (response?.data) {
        setStatuses(response.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleStatusSelect = (currentStatus: string) => {
    let nextFavoriteStatuses = [...favoriteStatuses]

    if (currentStatus === 'open') {
      if (
        nextFavoriteStatuses?.includes('active') ||
        nextFavoriteStatuses?.includes('awaiting')
      ) {
        nextFavoriteStatuses = nextFavoriteStatuses.filter(
          status => status !== 'active' && status !== 'awaiting'
        )
      } else {
        nextFavoriteStatuses.push('active')
        nextFavoriteStatuses.push('awaiting')
      }
    } else {
      if (nextFavoriteStatuses?.includes(currentStatus)) {
        nextFavoriteStatuses = nextFavoriteStatuses.filter(
          status => status !== currentStatus
        )
      } else {
        nextFavoriteStatuses.push(currentStatus)
      }
    }
    setFavoriteStatuses(nextFavoriteStatuses)
  }

  return {
    statuses,
    fetchStatuses,
    favoriteStatuses,
    setFavoriteStatuses,
    handleStatusSelect,
  }
}
