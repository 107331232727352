import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { RemoveSubscriptionData } from './removeSubscription'

export const getAddSubscriptionUrl = (): URL => {
  const url = `${API_URL}/auction/subscriptions/add`
  return new URL(url)
}

export const getRemoveSubscriptionUrl = (data: RemoveSubscriptionData): URL => {
  const url = `${API_URL}/auction/subscriptions/remove`
  const searchParams = getSearchParamsWithSeparatorFromObject(data)
  return new URL(url + searchParams)
}

export const getSubscriptionListUrl = (): URL => {
  const url = `${API_URL}/auction/subscriptions/list`
  return new URL(url)
}
