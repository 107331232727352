import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker'
import {
  getOrderListAsAdmin,
  GetOrderListAsAdminData,
} from 'api/requests/admin'
import { getLocationList } from 'api/requests/location'
import { TableData } from 'components/Datatable'
import { debounce } from 'lodash'
import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useMount } from 'react-use'
import {
  OrderPaymentStatus,
  OrderShipmentStatus,
  OrderStatus,
} from 'types/enums/order'
import { Location } from 'types/location'
import { OrderFilters } from 'types/order'
import { formatCurrency, formatDate } from 'utils/format'

import { useOrdering } from './useOrdering'

export const useAdminOrders = () => {
  const [data, setData] = useState<TableData | undefined>()
  const [total, setTotal] = useState<number>(0)
  const [filter, setFilter] = useState('all')

  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [searchError, setSearchError] = useState<boolean>(false)

  const [locations, setLocations] = useState<Location[]>([])
  const [activeLocation, setActiveLocation] = useState<number | undefined>()

  const [paymentStatus, setPaymentStatus] = useState<OrderPaymentStatus[]>([])
  const [shippingStatus, setShippingStatus] = useState<OrderShipmentStatus[]>(
    []
  )
  const [orderStatus, setOrderStatus] = useState<OrderStatus[]>([])
  const [filters, setFilters] = useState<OrderFilters>({})
  const [dateValues, setDateValues] = useState<DateRange<Date>>([null, null])
  const [finalDateValues, setFinalDateValues] = useState<DateRange<Date>>([
    null,
    null,
  ])

  const perPage = 25

  const fetchOrdersList = async (query?: GetOrderListAsAdminData) => {
    const ordersList = await getOrderListAsAdmin(query)
    const ordersData = ordersList?.data
    const ordersItems = ordersData?.items

    if (ordersItems) {
      const currentData = {
        rows: ordersItems.map(
          ({
            id,
            lotId,
            createdAt,
            auctionIds,
            totalAmount,
            status,
            paymentStatus: statusOfPayment,
            shippingStatus: statusOfShipping,
            fullName,
            location,
            seller,
          }) => {
            const isActionRequired =
              (statusOfShipping === 'labelMissing' ||
                statusOfShipping === 'pickupLate' ||
                statusOfPayment === 'overdue') &&
              !(status === 'completed' || status === 'canceled')
                ? 'action_required'
                : status
            return {
              cells: [
                { title: `${fullName}` },
                {
                  title: seller.businessName,
                  cellWidth: '200px',
                  isTextEllipsis: true,
                },
                { title: id, isConsolidatedOrder: auctionIds.length > 1 },
                {
                  title: createdAt
                    ? formatDate(new Date(createdAt), 'MMMM dd, yyyy')
                    : '',
                },
                { title: auctionIds.join(', ') },
                { title: lotId },
                {
                  title: formatCurrency(totalAmount, {
                    withPrefix: true,
                  }),
                },
                { title: `${location.city}, ${location.state}` },
                { title: statusOfPayment, isStatus: true, cellWidth: '120px' },
                { title: statusOfShipping, isStatus: true, cellWidth: '142px' },
                {
                  title: isActionRequired,
                  isStatus: true,
                  textAlign: 'right',
                  cellWidth: '182px',
                },
              ],
              id,
              isActionRequired: isActionRequired === 'action_required',
              isDisabled: status === 'consolidated',
              linkTo: `/admin/orders/${id}`,
            }
          }
        ),
      }
      setData(currentData)
      setTotal(ordersData?.total || 0)
      setCount(Math.ceil(ordersData?.total / 25) || 0)
    }
  }

  const debouncedSearch = useCallback(debounce(fetchOrdersList, 500), [])

  const { orderBy, order, handleRequestSort } = useOrdering()

  const handleSearchChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    const value = e.target.value.trim()
    setSearch(value)
    setSearchError(!!(value && isNaN(Number(value))))
  }

  const handleClearSearchClick = () => {
    setSearch('')
  }

  useEffect(() => {
    if (searchError) {
      return
    }

    const query: GetOrderListAsAdminData = {
      page,
      perPage,
    }

    const [startDate, endDate] = finalDateValues

    if (startDate && endDate) {
      const startDateTime = startDate
      const endDateTime = endDate
      startDateTime?.setHours(0, 0, 0, 0)
      endDateTime?.setHours(23, 59, 59, 59)

      query.startDate = startDateTime?.getTime()
      query.endDate = endDateTime?.getTime()
    }

    if (search) {
      query.id = search
    }

    if (order) {
      query.orderType = order
    }

    if (orderBy) {
      query.orderBy = orderBy
    }

    void debouncedSearch({ ...query, ...filters })
  }, [search, page, filters, finalDateValues, order, orderBy])

  useEffect(() => {
    void fetchOrdersList({ page })
  }, [])

  useMount(async () => {
    const userLocationsResponse = await getLocationList()

    if (userLocationsResponse.data) {
      setLocations(userLocationsResponse.data)
    }
  })

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleChangeFilters = () => {
    const newFilters: OrderFilters = {}

    if (orderStatus.length > 1 || orderStatus.length === 0) {
      setFilter('all')
    } else {
      setFilter(orderStatus[0])
    }

    if (paymentStatus?.length) {
      newFilters.paymentStatus = paymentStatus
    }
    if (shippingStatus?.length) {
      newFilters.shippingStatus = shippingStatus
    }
    if (orderStatus?.length) {
      newFilters.status = orderStatus
    }
    if (activeLocation) {
      newFilters.locationId = activeLocation
    }

    setFilters(newFilters)
  }

  const prepareFiltersState = (
    status: (OrderPaymentStatus | OrderShipmentStatus | OrderStatus)[],
    value: OrderPaymentStatus | OrderShipmentStatus | OrderStatus
  ) => {
    let statusCopy = [...status]

    if (statusCopy.includes(value)) {
      statusCopy = statusCopy.filter(item => item !== value)
    } else {
      statusCopy.push(value)
    }

    return statusCopy
  }

  const handleCheckboxChange = (
    value: OrderPaymentStatus | OrderStatus | OrderShipmentStatus,
    type: 'payment' | 'shipping' | 'order'
  ) => {
    if (type === 'payment') {
      const newStatus = prepareFiltersState(paymentStatus, value)
      setPaymentStatus(newStatus as OrderPaymentStatus[])
    }
    if (type === 'shipping') {
      const newStatus = prepareFiltersState(shippingStatus, value)
      setShippingStatus(newStatus as OrderShipmentStatus[])
    }
    if (type === 'order') {
      const newStatus = prepareFiltersState(orderStatus, value)
      setOrderStatus(newStatus as OrderStatus[])
    }
  }

  const handleChangeActiveLocation = (locationId: number) => {
    setActiveLocation(locationId)
  }

  const handleSetSelectedFilers = () => {
    setShippingStatus(filters.shippingStatus || [])
    setPaymentStatus(filters.paymentStatus || [])
    setOrderStatus(filters.status || [])
    setActiveLocation(filters.locationId)
  }

  const handleClearDateRanges = () => {
    setDateValues([null, null])
    setFinalDateValues([null, null])
  }

  const handleClearFilersAndLocation = () => {
    handleClearDateRanges()
    setShippingStatus([])
    setPaymentStatus([])
    setOrderStatus([])
    setActiveLocation(undefined)
  }

  const handleClearAllFilters = () => {
    handleClearFilersAndLocation()
    setFilters({})
  }

  const handleStatusChange = (value: string) => {
    if (value !== 'all') {
      const newFilters = { ...filters }
      const newStatus = [value as OrderStatus]
      newFilters.status = newStatus
      setOrderStatus(newStatus)

      setFilters(newFilters)
    } else {
      handleClearAllFilters()
    }
    setFilter(value)
  }

  const handleCloseDateRange = () => {
    setDateValues(finalDateValues)
  }
  const handleAcceptDateRange = () => {
    setFinalDateValues(dateValues)
  }
  const handleClearDateRange = () => {
    setDateValues([null, null])
    setFinalDateValues([null, null])
  }

  return {
    data,
    total,
    filter,
    page,
    count,
    search,
    searchError,
    order,
    orderBy,
    handleRequestSort,
    handleClearSearchClick,
    handleSearchChange,
    handleStatusChange,
    handleChangePage,
    handleCheckboxChange,
    handleChangeActiveLocation,
    handleChangeFilters,
    handleSetSelectedFilers,
    handleClearFilersAndLocation,
    handleClearAllFilters,
    handleAcceptDateRange,
    paymentStatus,
    shippingStatus,
    orderStatus,
    locations,
    activeLocation,
    setFilter,
    dateValues,
    setDateValues,
    handleCloseDateRange,
    handleClearDateRange,
  }
}
