import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const BigCheckEmailIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path d='M64 13a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z' fill='currentColor' />
    <path
      d='M59 23.819V53H5V11h41.181a11.057 11.057 0 0 0 0 4H9v.002L32 29.63l16.063-10.216a11.062 11.062 0 0 0 2.988 2.84L32 34.37 9 19.742V49h46V23.819a11.057 11.057 0 0 0 4 0Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
