import { axiosExtended } from 'api/axios'
import { serialize } from 'object-to-formdata'

import { getVerifyUserEmailAsAdminUrl } from '../urls'
import {
  VerifyUserEmailAsAdminData,
  VerifyUserEmailAsAdminResponse,
} from './verifyUserEmailAsAdmin.types'

export const verifyUserEmailAsAdmin = async (
  data: VerifyUserEmailAsAdminData
) => {
  const { href } = getVerifyUserEmailAsAdminUrl()
  const response = await axiosExtended.put<VerifyUserEmailAsAdminResponse>(
    href,
    serialize(data),
    {
      withAuth: true,
    }
  )
  return response.data
}
