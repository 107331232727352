import { axiosExtended } from 'api/axios'

import { getOrderUrl } from '../urls'
import { GetOrderData, GetOrderResponse } from './getOrder.types'

export const getOrder = async (query: GetOrderData) => {
  const { href } = getOrderUrl(query)
  const response = await axiosExtended.get<GetOrderResponse>(href, {
    withAuth: true,
  })
  return response.data
}
