import { axiosExtended } from 'api/axios'

import { getOrdersToConsolidateUrl } from '../urls'
import { GetOrdersToConsolidateResponse } from './getOrdersToConsolidate.types'

export const getOrdersToConsolidate = async () => {
  const { href } = getOrdersToConsolidateUrl()
  const response = await axiosExtended.get<GetOrdersToConsolidateResponse>(
    href,
    {
      withAuth: true,
    }
  )
  return response.data
}
