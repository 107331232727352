import { axiosExtended } from 'api/axios'

import { getRegisterInvitedUserUrl } from '../urls'
import {
  RegisterInvitedUserData,
  RegisterInvitedUserResponse,
} from './registerInvitedUser.types'

export const registerInvitedUser = async (data: RegisterInvitedUserData) => {
  const { href } = getRegisterInvitedUserUrl()
  const response = await axiosExtended.post<RegisterInvitedUserResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
