export enum OrderStatus {
  Open = 'open',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Consolidated = 'consolidated',
}

export enum OrderShipmentStatus {
  AwaitingShipment = 'awaitingShipment',
  LabelMissing = 'labelMissing',
  LabelUploaded = 'labelUploaded',
  ReadyForPickup = 'readyForPickup',
  PickupLate = 'pickupLate',
  PickedUp = 'pickedUp',
  Delivered = 'delivered',
  Voided = 'voided',
}

export enum OrderPaymentStatus {
  AwaitingPayment = 'awaitingPayment',
  Overdue = 'overdue',
  Processing = 'processing',
  Paid = 'paid',
  PartiallyPaid = 'partiallyPaid',
  Voided = 'voided',
}

export enum OrderDocumentTypes {
  Original = 'original',
  Signed = 'signed',
  Photo = 'photo',
}
