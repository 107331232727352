import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { DownloadShippingLabelAsAdminData } from './downloadShippingLabelAsAdmin'
import { FetchTransactionsListData } from './fetchTransactionsList'
import { GetAuctionBidsData } from './getAuctionBids'
import { GetAuctionDetailsAsAdminData } from './getAuctionDetailsAsAdmin'
import { GetAuctionListAsAdminData } from './getAuctionListAsAdmin'
import { GetOrderListAsAdminData } from './getOrderListAsAdmin'
import { GetTransactionUserListData } from './getTransactionUserList'
import { GetUserAsAdminData } from './getUserAsAdmin'

export const getLoginAsUserAsAdminUrl = (): URL => {
  const url = `${API_URL}/admin/user/login`
  return new URL(url)
}

export const getApproveUserAsAdminUrl = (): URL => {
  const url = `${API_URL}/admin/user/approve`
  return new URL(url)
}

export const getRejectUserAsAdminUrl = (): URL => {
  const url = `${API_URL}/admin/user/reject`
  return new URL(url)
}

export const getSetUserSettingsAsAdminUrl = (): URL => {
  const url = `${API_URL}/admin/user/settings`
  return new URL(url)
}

export const getUserAsAdminUrl = (params?: GetUserAsAdminData): URL => {
  const url = `${API_URL}/admin/user/`
  if (!params) {
    return new URL(url)
  }

  const { userId } = params
  return new URL(url + userId)
}

export const getVerifyUserEmailAsAdminUrl = (): URL => {
  const url = `${API_URL}/admin/user/confirmEmail`
  return new URL(url)
}

export const getOrderListAsAdminUrl = (
  query?: GetOrderListAsAdminData
): URL => {
  const url = `${API_URL}/admin/order/list`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getOrderAsAdminUrl = (id: string) => {
  const url = `${API_URL}/admin/order/${id}`
  return new URL(url)
}

export const getAuctionListAsAdminUrl = (
  query?: GetAuctionListAsAdminData
): URL => {
  const url = `${API_URL}/admin/auction/list`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getResetPasswordAsAdminUrl = (id: string | number) => {
  const url = `${API_URL}/admin/user/reset-password/${id}`
  return new URL(url)
}

export const getAuctionDetailsAsAdminUrl = (
  params: GetAuctionDetailsAsAdminData
): URL => {
  const url = `${API_URL}/admin/auction/`
  if (!params) {
    return new URL(url)
  }

  const { auctionId } = params
  return new URL(url + auctionId)
}

export const getSetAuctionSettingsAsAdminUrl = () => {
  const url = `${API_URL}/admin/auction/update`
  return new URL(url)
}

export const getCloseAuctionAsAdminUrl = () => {
  const url = `${API_URL}/admin/auction/close`
  return new URL(url)
}

export const getSetAuctionAsAdminWinnerUrl = () => {
  const url = `${API_URL}/admin/auction/bid/winner`
  return new URL(url)
}

export const getDeleteUserAsAdminUrl = (userId: number) => {
  const url = `${API_URL}/admin/user/delete`
  if (!userId) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject({ userId })
  return new URL(url + searchParams)
}

export const cancelOrderAsAdminUrl = () => {
  const url = `${API_URL}/admin/order/close`
  return new URL(url)
}

export const getDownloadInvoiceAsAdminUrl = (id: number): URL => {
  const url = `${API_URL}/admin/order/invoice`
  const searchParams = getSearchParamsWithSeparatorFromObject({ id })
  return new URL(url + searchParams)
}

export const getDownloadPackingListAsAdminUrl = (id: number): URL => {
  const url = `${API_URL}/admin/order/packingList`
  const searchParams = getSearchParamsWithSeparatorFromObject({ id })
  return new URL(url + searchParams)
}

export const getCancelBidAsAdminUrl = () => {
  const url = `${API_URL}/admin/bid/cancel`
  return new URL(url)
}

export const getDownloadUserDocument = (userId: number): URL => {
  const url = `${API_URL}/user/file`
  const searchParams = getSearchParamsWithSeparatorFromObject({ userId })
  return new URL(url + searchParams)
}

export const getDownloadShippingLabelAsAdminUrl = (
  query: DownloadShippingLabelAsAdminData
) => {
  const url = `${API_URL}/admin/shipping/labels/file`
  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getAdminLocationListUrl = () => {
  const url = `${API_URL}/admin/location/list`
  return new URL(url)
}

export const getAdminBankAccountSessionUrl = () => {
  const url = `${API_URL}/admin/stripe/bank/account/session`
  return new URL(url)
}

export const getCreateAdminBankAccountUrl = () => {
  const url = `${API_URL}/admin/stripe/bank/account/create`
  return new URL(url)
}

export const getFetchTransactionsListUrl = (
  query?: FetchTransactionsListData
) => {
  const url = `${API_URL}/admin/stripe/transaction/list`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getTransactionUserListUrl = (
  query?: GetTransactionUserListData
) => {
  const url = `${API_URL}/admin/stripe/transaction/user/list`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getAssignTransactionUrl = () => {
  const url = `${API_URL}/admin/stripe/transaction/assign`
  return new URL(url)
}

export const getAuctionBidsUrl = (params: GetAuctionBidsData) => {
  const url = `${API_URL}/admin/auction/bid/list/`
  if (!params) {
    return new URL(url)
  }

  const { auctionId } = params
  const searchParams = getSearchParamsWithSeparatorFromObject(params)
  return new URL(url + auctionId + searchParams)
}

export const getGetOrderDefectReasonsUrl = () => {
  const url = `${API_URL}/admin/defectPoint/reasons`
  return new URL(url)
}

export const getRecordOrderDefectUrl = () => {
  const url = `${API_URL}/admin/defectPoint`
  return new URL(url)
}
