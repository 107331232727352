interface CurrencyFormatOptions {
  localeMatcher?: string
  style?: string
  currencyDisplay?: string
  currencySign?: string
  useGrouping?: boolean
  minimumIntegerDigits?: number
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  minimumSignificantDigits?: number
  maximumSignificantDigits?: number
  withPrefix?: boolean
}

export const formatCurrency = (
  value: number,
  options?: CurrencyFormatOptions
): string => {
  const prefix = 'USD'
  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    ...options,
  }).format(value)

  return options?.withPrefix ? `${prefix} ${formattedValue}` : formattedValue
}
