import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const AuctionIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M10.778 2.414 12.192 1l7.071 7.071-1.414 1.414-.707-.707-2.475 2.475 7.347 7.346-1.414 1.415-7.347-7.347-2.475 2.475.707.707-1.414 1.415L3 10.193l1.414-1.415.707.707 6.364-6.364-.707-.707ZM12.9 4.536 6.535 10.9l2.829 2.828 6.364-6.364-2.829-2.828Z'
      fill='currentColor'
    />
    <path d='M14 20H2v2h12v-2Z' fill='currentColor' />
  </SvgWrapper>
)
