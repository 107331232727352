import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const BankAccountIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M7 10H5v8h2v-8Zm6 0h-2v8h2v-8Zm9 10H2v2h20v-2Zm-3-10h-2v8h2v-8Zm-7-6.74L17.71 6H6.29L12 3.26ZM12 1 2 6v2h20V6L12 1Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
