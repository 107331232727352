import {
  closeAuction,
  getAuction,
  getAuctionListSimple,
  GetAuctionListSimpleData,
} from 'api/requests/auction'
import { Action, Order, TableData } from 'components/Datatable'
import { debounce } from 'lodash'
import { useRouter } from 'next/router'
import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { AuctionStatus } from 'types/enums'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { formatCurrency, formatDate } from 'utils/format'
import { smoothScroll } from 'utils/scroll'

import { useOrdering } from './useOrdering'

export const useSellerAuctions = (syncWithUrl = false) => {
  const [queryParams, setQueryParams] = useQueryParams({
    status: withDefault(StringParam, 'all'),
    search: withDefault(StringParam, ''),
    order: withDefault(StringParam, 'desc'),
    orderBy: withDefault(StringParam, 'endDate'),
  })

  const router = useRouter()

  const [data, setData] = useState<TableData | undefined>()
  const [filter, setFilter] = useState(() =>
    syncWithUrl ? queryParams.status : 'all'
  )
  const [count, setCount] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [search, setSearch] = useState<string>(() =>
    syncWithUrl ? queryParams.search : ''
  )
  const [notEmpty, setNotEmpty] = useState<boolean | undefined>(false)
  const [openWinnerModal, setOpenWinnerModal] = useState<boolean>(false)
  const [openWinnerModalSecond, setOpenWinnerModalSecond] =
    useState<boolean>(false)
  const [currentWinner, setCurrentWinner] = useState<
    string | number | undefined
  >()
  const [openAuctionCloseModal, setOpenAuctionCloseModal] =
    useState<boolean>(false)
  const [currentId, setCurrentId] = useState<string | number | undefined>()

  const perPage = 25

  const fetchAuctionList = async (query?: GetAuctionListSimpleData) => {
    try {
      const auctionListResponse = await getAuctionListSimple(query)
      const currentUserAuctionsData = auctionListResponse?.data

      if (currentUserAuctionsData) {
        const totalItems = currentUserAuctionsData?.total
        const isNotEmpty = currentUserAuctionsData?.isNotEmpty
        const rows = currentUserAuctionsData?.items?.map(auction => {
          const availableActions = [
            Action.edit,
            Action.close,
            Action.repostAuction,
            Action.selectWinner,
            Action.delete,
          ].reduce((actions: Action[], action) => {
            const auctionStatus = auction.status
            const hasBids = auction.bids.length > 0

            const canEditAuction =
              auctionStatus === AuctionStatus.Scheduled ||
              (auctionStatus === AuctionStatus.Active && !hasBids)
            if (action === Action.edit && canEditAuction) {
              actions.push(Action.edit)
            }

            const canSelectWinnerAuction =
              auctionStatus === AuctionStatus.Awaiting
            if (action === Action.selectWinner && canSelectWinnerAuction) {
              actions.push(Action.selectWinner)
            }

            const canCloseAuction =
              [AuctionStatus.Awaiting, AuctionStatus.Scheduled].includes(
                auctionStatus
              ) ||
              (auctionStatus === AuctionStatus.Active && !hasBids)
            if (action === Action.close && canCloseAuction) {
              actions.push(Action.close)
            }

            const canRepostAuction = auctionStatus === AuctionStatus.Closed
            if (action === Action.repostAuction && canRepostAuction) {
              actions.push(Action.repostAuction)
            }

            const canDeleteAuction = auctionStatus === AuctionStatus.Closed
            if (action === Action.delete && canDeleteAuction) {
              actions.push(Action.delete)
            }

            return actions
          }, [])

          return {
            cells: [
              {
                title: auction.id,
                isTextEllipsis: true,
                maxCellWidth: '75px',
              },
              {
                title: auction.title,
                isTextEllipsis: true,
                maxCellWidth: '225px',
              },
              {
                title: auction.lotId,
                tooltipText: auction.referenceId,
                isTextEllipsis: true,
                maxCellWidth: '75px',
              },
              {
                title: auction.quantity,
                textAlign: 'center',
                isTextEllipsis: true,
                maxCellWidth: '90px',
              },
              {
                title: auction.location,
                isTextEllipsis: true,
                maxCellWidth: '140px',
              },
              {
                title: auction.status,
                isStatus: true,
                isTextEllipsis: true,
                maxCellWidth: '108px',
              },
              {
                title: auction.totalBids,
                textAlign: 'center',
                isTextEllipsis: true,
                maxCellWidth: '80px',
              },
              {
                title: auction.reservePrice
                  ? formatCurrency(auction.reservePrice, { withPrefix: true })
                  : '﹣',
                isTextEllipsis: true,
                maxCellWidth: '147px',
                withLockIcon:
                  Boolean(auction.reservePrice) && auction.isReservePriceMet,
              },
              {
                title: formatCurrency(
                  auction.status === AuctionStatus.Sold
                    ? auction.winningPrice ?? 0
                    : auction.currentBidPrice,
                  { withPrefix: true }
                ),
                isTextEllipsis: true,
                maxCellWidth: '137px',
              },
              {
                title: formatDate(new Date(auction.startDate), 'MMM d, h:mm a'),
                isTextEllipsis: true,
                maxCellWidth: '140px',
              },
              {
                title: formatDate(new Date(auction.endDate), 'MMM d, h:mm a'),
                isTextEllipsis: true,
                maxCellWidth: '140px',
              },
            ],
            id: auction.id,
            lotId: auction.lotId,
            showActions: Boolean(availableActions.length),
            availableActions,
          }
        })

        setData({ rows })
        setTotal(totalItems || 0)
        setNotEmpty(isNotEmpty)

        if (totalItems && totalItems >= 0) {
          setCount(Math.ceil(totalItems / perPage))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleCloseAuction = async (auctionId?: number | string) => {
    if (auctionId) {
      try {
        await closeAuction({ auctionId: Number(auctionId) })
        await fetchAuctionList()
        setOpenAuctionCloseModal(false)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleStatusChange = (value: string) => {
    setFilter(value)
    setPage(1)
  }

  const handleRowClick = async (auctionId: string | number) => {
    await router.push(`/auctions/${auctionId}`)
  }

  const handleEditLotDetails = async (id?: string | number) => {
    await router.push(`/auctions/${id}/edit/lot`)
  }

  const handleEditShippingDetails = async (id?: string | number) => {
    await router.push(`/auctions/${id}/edit/shipping`)
  }

  const handleEditAuctionDetails = async (id?: string | number) => {
    await router.push(`/auctions/${id}/repost`)
  }

  const handleSearchChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    const value = e.target.value
    setSearch(value)
  }

  const handleClearSearchClick = () => {
    setSearch('')
  }

  const { order, orderBy, handleRequestSort } = useOrdering(
    queryParams.order as Order,
    queryParams.orderBy
  )

  const debouncedSearching = useCallback(debounce(fetchAuctionList, 500), [])

  const prepareQueryAndFetch = async (
    extraQuery?: GetAuctionListSimpleData
  ) => {
    const query: GetAuctionListSimpleData = {
      page,
      perPage,
      ...(extraQuery && extraQuery),
    }
    if (extraQuery) {
      query.page = 1
      setPage(1)
    }
    if (search?.length) {
      query.search = search.trim()
    }
    if (filter && filter !== 'all') {
      query.status = filter as Lowercase<keyof typeof AuctionStatus>
    }
    if (order) {
      query.orderType = order
    }
    if (orderBy) {
      query.orderBy = orderBy
    }
    await debouncedSearching(query)
  }

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    smoothScroll()
  }

  const handleShowAllClick = () => {
    setFilter('all')
    setSearch('')
  }

  const onAcceptWinnerClick = async () => {
    setOpenWinnerModal(false)
    await fetchAuctionList()
    setOpenWinnerModalSecond(true)
  }

  const goToOrder = async () => {
    try {
      const response = await getAuction({ auctionId: currentWinner as string })
      const orderId = response.data?.order?.id
      if (orderId) {
        return await router.push(`/orders/${orderId}`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!syncWithUrl) {
      return
    }

    setQueryParams({
      status: filter || undefined,
      search: search || undefined,
      order,
      orderBy,
    })
  }, [filter, search, order, orderBy])

  return {
    page,
    data,
    filter,
    total,
    count,
    perPage,
    order,
    orderBy,
    notEmpty,
    goToOrder,
    currentWinner,
    setCurrentWinner,
    handleRequestSort,
    fetchAuctionList,
    handleCloseAuction,
    handleStatusChange,
    handleRowClick,
    handleEditLotDetails,
    handleEditShippingDetails,
    handleEditAuctionDetails,
    handleSearchChange,
    prepareQueryAndFetch,
    handleChangePage,
    search,
    handleClearSearchClick,
    handleShowAllClick,
    openWinnerModal,
    setOpenWinnerModal,
    onAcceptWinnerClick,
    openWinnerModalSecond,
    setOpenWinnerModalSecond,
    openAuctionCloseModal,
    setOpenAuctionCloseModal,
    currentId,
    setCurrentId,
  }
}
