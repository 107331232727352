import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const ArrowDownwardIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='m4 14 1.41-1.41L11 18.17V3h2v15.17l5.59-5.59L20 14l-8 8-8-8Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
