import { debounce } from 'lodash'
import { checkIsEmailRegistered } from 'api/requests/email'

export const isEmailAlreadyRegisteredDebounced = debounce(
  async (
    email: string,
    resolve: (value: boolean | string) => void,
    reject: (reason: string) => void
  ): Promise<void> => {
    try {
      await checkIsEmailRegistered({ email })
      resolve(true)
    } catch (error) {
      const errorMessage = (error?.response?.data?.error ??
        "Can't validate the email, the problem is on our side.") as string
      reject(errorMessage)
    }
  },
  100
)