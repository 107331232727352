import { axiosExtended } from 'api/axios'

import { getDownloadPackingListAsAdminUrl } from '../urls'
import { DownloadPackingListAsAdminResponse } from './downloadPackingListAsAdmin.types'

export const downloadPackingListAsAdmin = async (id: number) => {
  const { href } = getDownloadPackingListAsAdminUrl(id)
  const response = await axiosExtended.get<DownloadPackingListAsAdminResponse>(
    href,
    {
      withAuth: true,
      responseType: 'blob',
    }
  )

  return response.data
}
