import { axiosExtended } from 'api/axios'

import { getEditLotDetailsUrl } from '../urls'
import {
  EditLotDetailsData,
  EditLotDetailsResponse,
} from './editLotDetails.types'

export const editLotDetails = async (
  data: EditLotDetailsData,
  lotId: string
) => {
  const { href } = getEditLotDetailsUrl(lotId)
  const response = await axiosExtended.put<EditLotDetailsResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
