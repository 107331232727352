import { getAuctionListSimple } from 'api/requests/auction'
import { getLotList } from 'api/requests/lot'
import {
  getOrderGraph,
  GetOrderGraphResponse,
  getOrderList,
} from 'api/requests/order'
import { TableData } from 'components/Datatable'
import { usePayouts } from 'hooks/usePayouts'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { DashboardStorage } from 'utils/dashboard'
import { formatCurrency, formatDate } from 'utils/format'

export const useDashboard = () => {
  const dashboardStorage = DashboardStorage.getInstance()
  const dashboardFilters = dashboardStorage.getDashboardFilters()

  const [lastAuctionsData, setLastAuctionsData] = useState<
    TableData | undefined
  >()
  const [lastOrdersData, setLastOrdersData] = useState<TableData | undefined>()
  const [inventoryData, setInventoryData] = useState<TableData | undefined>()
  const [activeDashboardList, setActiveDashboardList] = useState<number[]>([])
  const [graphData, setGraphData] = useState<
    GetOrderGraphResponse | undefined
  >()
  const [finalActiveDashboardList, setFinalActiveDashboardList] = useState<
    number[]
  >([])

  const router = useRouter()

  useEffect(() => {
    if (dashboardFilters) {
      setFinalActiveDashboardList(dashboardFilters)
    }
  }, [dashboardFilters])

  const { payoutsData, fetchPayoutsList } = usePayouts()

  const fetchLastAuctions = async () => {
    try {
      const auctionListResponse = await getAuctionListSimple({
        perPage: 5,
      })
      const auctions = auctionListResponse?.data?.items
      if (!auctions) {
        return
      }

      const rows = auctions.map(auction => {
        return {
          cells: [
            {
              title: auction.title,
              isTextEllipsis: true,
              maxCellWidth: '252px',
            },
            { title: auction.status, isStatus: true },
            {
              title: auction.reservePrice
                ? formatCurrency(auction.reservePrice, { withPrefix: true })
                : '﹣',
              withLockIcon:
                Boolean(auction.reservePrice) && auction.isReservePriceMet,
            },
            {
              title: auction.endDate?.length
                ? formatDate(new Date(auction.endDate), 'MMM d, h:mm a')
                : '﹣',
            },
          ],
          id: auction.id,
        }
      })

      setLastAuctionsData({ rows })
    } catch (e) {
      console.error(e)
    }
  }

  const fetchLastOrders = async () => {
    try {
      const ordersList = await getOrderList({ perPage: 5 })
      const ordersItems = ordersList?.data?.items

      if (ordersItems) {
        const currentData = {
          rows: ordersItems.map(({ id, createdAt, totalAmount, status }) => ({
            cells: [
              {
                title: id,
                isTextEllipsis: true,
                maxCellWidth: '162px',
              },
              {
                title: createdAt?.length
                  ? formatDate(new Date(createdAt), 'MMMM dd, yyyy')
                  : '﹣',
              },
              {
                title: totalAmount
                  ? formatCurrency(totalAmount, { withPrefix: true })
                  : '﹣',
              },
              { title: status, isStatus: true, textAlign: 'right' },
            ],
            id,
          })),
        }

        setLastOrdersData(currentData)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchLastLots = async () => {
    try {
      const ordersList = await getLotList({ perPage: 5 })

      const rows =
        ordersList?.data?.items.map(
          ({ title, status, auctions, createdAt, id }) => {
            return {
              cells: [
                {
                  title: title,
                  isTextEllipsis: true,
                  maxCellWidth: '266px',
                },
                {
                  title: auctions,
                  isLink: true,
                  linkAddress: `/auctions/my?search=${id}`,
                },
                { title: status, isStatus: true },
                {
                  title: createdAt?.length
                    ? formatDate(new Date(createdAt), 'MMM d, h:mm a')
                    : '﹣',
                },
              ],
              id,
            }
          }
        ) || []

      setInventoryData({ rows })
    } catch (e) {
      console.error(e)
    }
  }

  const fetchOrderGraph = async () => {
    try {
      const response = await getOrderGraph(new Date().getTime())
      if (response?.data) {
        setGraphData(response?.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleDashboardFilterChange = (id: number) => {
    if (activeDashboardList.includes(id)) {
      setActiveDashboardList(list => list.filter(item => item !== id))
    } else {
      setActiveDashboardList(list => [...list, id])
    }
  }

  const handleResetFilters = () => {
    setActiveDashboardList([])
  }

  const handleSetActiveFilters = () => {
    setActiveDashboardList(finalActiveDashboardList)
  }

  const handleAcceptFilters = () => {
    setFinalActiveDashboardList(activeDashboardList)
    if (activeDashboardList?.length) {
      dashboardStorage.setDashboardFilters(activeDashboardList)
    } else {
      dashboardStorage.removeDashboardFilters()
    }
  }

  const handleRowAuctionClick = async (auctionId: string | number) => {
    await router.push(`/auctions/${auctionId}`)
  }

  const handleRowOrderClick = async (orderId: string | number) => {
    await router.push(`/orders/${orderId}`)
  }

  const handleRowInventoryClick = async (lotId: string | number) => {
    await router.push(`/lots/${lotId}`)
  }

  return {
    lastAuctionsData,
    lastOrdersData,
    inventoryData,
    payoutsData,
    graphData,
    activeDashboardList,
    finalActiveDashboardList,
    fetchLastAuctions,
    fetchLastOrders,
    fetchLastLots,
    fetchPayoutsList,
    fetchOrderGraph,
    handleDashboardFilterChange,
    handleResetFilters,
    handleSetActiveFilters,
    handleAcceptFilters,
    handleRowAuctionClick,
    handleRowOrderClick,
    handleRowInventoryClick,
  }
}
