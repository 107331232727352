import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const ReturnIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z'
      fill='currentColor'
    />
  </SvgWrapper>
)
