import { axiosExtended } from 'api/axios'

import { getCreateAuctionCategoryUrl } from '../urls'
import {
  CreateAuctionCategoryData,
  CreateAuctionCategoryResponse,
} from './createAuctionCategory.types'

export const createAuctionCategory = async (
  data: CreateAuctionCategoryData
) => {
  const { href } = getCreateAuctionCategoryUrl()
  const response = await axiosExtended.post<CreateAuctionCategoryResponse>(
    href,
    data,
    { withAuth: true }
  )
  return response.data
}
