export const APP_URL = process.env.NEXT_PUBLIC_APP_URL as string
if (!APP_URL) {
  throw new Error('Environment variable "NEXT_PUBLIC_APP_URL" is not provided')
}

export const API_URL = process.env.NEXT_PUBLIC_API_URL as string
if (!API_URL) {
  throw new Error('Environment variable "NEXT_PUBLIC_API_URL" is not provided')
}

export const AWS_S3_REGION = process.env.NEXT_PUBLIC_AWS_S3_REGION as string
if (!AWS_S3_REGION) {
  throw new Error(
    'Environment variable "NEXT_PUBLIC_AWS_S3_REGION" is not provided'
  )
}

export const AWS_S3_BUCKET_NAME = process.env
  .NEXT_PUBLIC_AWS_S3_BUCKET_NAME as string
if (!AWS_S3_BUCKET_NAME) {
  throw new Error(
    'Environment variable "NEXT_PUBLIC_AWS_S3_BUCKET_NAME" is not provided'
  )
}

export const STRIPE_PUBLISHABLE_KEY = process.env
  .NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string

if (!STRIPE_PUBLISHABLE_KEY) {
  throw new Error(
    'Environment variable "NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY" is not provided'
  )
}

// Environment variables that don't necessary for the app to work correctly
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
export const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT
