import { axiosExtended } from 'api/axios'

import { getRatingInfoUrl } from '../urls'
import { GetRatingInfoResponse } from './getRatingInfo.types'

export const getRatingInfo = async () => {
  const { href } = getRatingInfoUrl()
  const response = await axiosExtended.get<GetRatingInfoResponse>(href, {
    withAuth: true,
  })
  return response.data
}
