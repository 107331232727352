import { axiosExtended } from 'api/axios'

import { getSetAuctionSettingsAsAdminUrl } from '../urls'
import {
  SetAuctionSettingsAsAdminData,
  SetAuctionSettingsAsAdminResponse,
} from './setAuctionSettingsAsAdmin.types'

export const setAuctionSettingsAsAdmin = async (
  data: Partial<SetAuctionSettingsAsAdminData>
) => {
  const { href } = getSetAuctionSettingsAsAdminUrl()
  const response = await axiosExtended.put<SetAuctionSettingsAsAdminResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
