import { axiosExtended } from 'api/axios'

import { GetAuctionData } from '../getAuction'
import { getAuctionReminderUrl } from '../urls'
import { GetAuctionReminderResponse } from './getAuctionReminder.types'

export const getAuctionReminder = async (params?: GetAuctionData) => {
  const { href } = getAuctionReminderUrl(params)
  const response = await axiosExtended.get<GetAuctionReminderResponse>(href, {
    withAuth: true,
  })
  return response.data
}
