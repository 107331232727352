import { axiosExtended } from 'api/axios'

import { getDeleteAuctionUrl } from '../urls'
import { DeleteAuctionResponse } from './deleteAuction.types'

export const deleteAuction = async (id: string) => {
  const { href } = getDeleteAuctionUrl(id)
  const response = await axiosExtended.delete<DeleteAuctionResponse>(href, {
    withAuth: true,
  })
  return response.data
}
