import { axiosExtended } from 'api/axios'

import { cancelOrderAsAdminUrl } from '../urls'
import {
  CancelOrderAsAdminData,
  CancelOrderAsAdminResponse,
} from './cancelOrderAsAdmin.types'

export const cancelOrderAsAdmin = async (data: CancelOrderAsAdminData) => {
  const { href } = cancelOrderAsAdminUrl()
  const response = await axiosExtended.post<CancelOrderAsAdminResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
