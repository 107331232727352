import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const GridViewIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 11V3H11V11H3ZM5 5H9V9H5V5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 11V3H21V11H13ZM15 5H19V9H15V5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 13V21H11V13H3ZM9 15H5V19H9V15Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 21V13H21V21H13ZM15 15H19V19H15V15Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
