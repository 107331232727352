import { SelectChangeEvent } from '@mui/material'
import {
  getAuctionListAsAdmin,
  GetAuctionListAsAdminData,
} from 'api/requests/admin'
import { TableData } from 'components/Datatable'
import { debounce } from 'lodash'
import { useRouter } from 'next/router'
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useState,
} from 'react'
import { AuctionStatus } from 'types/enums'
import { formatCurrency, formatDate } from 'utils/format'
import { smoothScroll } from 'utils/scroll'

import { useOrdering } from './useOrdering'

export const useAdminAuctions = () => {
  const router = useRouter()

  const [data, setData] = useState<TableData | undefined>()
  const [total, setTotal] = useState<number>(0)

  const [search, setSearch] = useState<string>('')
  const [filter, setFilter] = React.useState('all')

  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  const [activeLocation, setActiveLocation] = useState<string>('all')

  const handleChangeActiveLocation = (event: SelectChangeEvent<unknown>) => {
    setActiveLocation(event.target.value as string)
  }

  const perPage = 25

  const fetchAuctionList = async (query?: GetAuctionListAsAdminData) => {
    try {
      const auctionListResponse = await getAuctionListAsAdmin(query)
      const items = auctionListResponse?.data?.items
      if (!items) {
        return
      }

      const newData = {
        rows: items.map(
          ({
            id,
            lotId,
            endDate,
            location,
            quantity,
            startDate,
            bids,
            reservePrice,
            isReservePriceMet,
            status,
            title,
            currentBidPrice,
            winningPrice,
          }) => ({
            cells: [
              { title, isTextEllipsis: true, maxCellWidth: '242px' },
              { title: lotId, cellWidth: '100px' },
              { title: id, cellWidth: '100px' },
              { title: quantity, textAlign: 'center', cellWidth: '86px' },
              { title: location, isTextEllipsis: true, maxCellWidth: '160px' },
              { title: status, isStatus: true, cellWidth: '120px' },
              { title: bids.length, textAlign: 'center' },
              {
                title: reservePrice
                  ? formatCurrency(reservePrice, { withPrefix: true })
                  : '﹣',
                withLockIcon: Boolean(reservePrice) && isReservePriceMet,
              },
              {
                title: formatCurrency(
                  status === AuctionStatus.Sold
                    ? winningPrice ?? 0
                    : currentBidPrice,
                  { withPrefix: true }
                ),
              },
              {
                title: formatDate(new Date(startDate), 'MMM d, h:mm a'),
              },
              {
                title: formatDate(new Date(endDate), 'MMM d, h:mm a'),
              },
            ],
            id,
          })
        ),
      }

      setData(newData)
      const currentTotal = auctionListResponse?.data?.total
      setTotal(currentTotal || 0)
      setCount(currentTotal ? Math.ceil(currentTotal / perPage) : 1)
    } catch (e) {
      console.error(e)
    }
  }

  const { order, orderBy, handleRequestSort } = useOrdering('desc', 'createdAt')

  const handleSearchChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    const value = e.target.value
    setSearch(value)
  }

  const handleStatusChange = (value: string) => {
    setFilter(value)
    setPage(1)
  }

  const handleClearSearchClick = () => {
    setSearch('')
  }

  const debouncedSearch = useCallback(debounce(fetchAuctionList, 500), [])

  const prepareQueryAndFetch = async () => {
    const query: GetAuctionListAsAdminData = {
      page,
      perPage,
    }
    if (search?.length) {
      query.search = search
    }
    if (filter && filter !== 'all') {
      query.status = filter as Lowercase<keyof typeof AuctionStatus>
    }
    if (activeLocation !== 'all') {
      query.locationId = Number(activeLocation)
    }
    if (order) {
      query.orderType = order
    }
    if (orderBy) {
      query.orderBy = orderBy
    }
    await debouncedSearch(query)
  }

  const handleRowClick = async (id: string | number) => {
    await router.push(`/admin/auctions/${id}`)
  }

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    smoothScroll()
  }

  return {
    data,
    total,
    search,
    filter,
    page,
    count,
    perPage,
    activeLocation,
    order,
    orderBy,
    handleRequestSort,
    handleChangeActiveLocation,
    fetchAuctionList,
    handleSearchChange,
    handleStatusChange,
    handleClearSearchClick,
    handleRowClick,
    prepareQueryAndFetch,
    handleChangePage,
  }
}
