import { UserContext } from 'context'
import { useContext } from 'react'

export const useUserContext = () => {
  const data = useContext(UserContext)

  if (data === undefined) {
    throw new Error('useUser must be used within UserProvider')
  }

  return data
}
