import { API_URL } from 'constants/env'

import { DeletePaymentMethodData } from './deletePaymentMethod'
import { GetFeeData } from './getFee'

export const getSetStripeAccountIdUrl = (): URL => {
  const url = `${API_URL}/stripe/account/set`
  return new URL(url)
}

export const getGetStripeSecretUrl = () => {
  const url = `${API_URL}/stripe/intent/start`
  return new URL(url)
}

export const getPaymentMethodsUrl = () => {
  const url = `${API_URL}/stripe/methods`
  return new URL(url)
}

export const getMakePaymentMethodDefaultUrl = () => {
  const url = `${API_URL}/stripe/methods/default`
  return new URL(url)
}

export const getPayOrderUrl = () => {
  const url = `${API_URL}/stripe/intent/payment`
  return new URL(url)
}

export const getFeeUrl = (query?: GetFeeData) => {
  const url = `${API_URL}/stripe/fee`
  if (!query) {
    return new URL(url)
  }

  const queryParams = new URLSearchParams()
  if (query.orderIds) {
    queryParams.append('orderIds', query.orderIds.join(','))
  }

  if (query.amount !== undefined) {
    queryParams.append('amount', String(query.amount))
  }

  return new URL(`${url}?${queryParams.toString()}`)
}

export const deletePaymentMethodUrl = (params: DeletePaymentMethodData) => {
  const url = `${API_URL}/stripe/intent/delete/`
  if (!params) {
    return new URL(url)
  }

  const { id } = params
  return new URL(url + id)
}

export const createExpressDashboardLoginLinkUrl = (): URL => {
  const url = `${API_URL}/stripe/login/links`
  return new URL(url)
}
