import { axiosExtended } from 'api/axios'

import { getDownloadShippingLabelAsAdminUrl } from '../urls'
import {
  DownloadShippingLabelAsAdminData,
  DownloadShippingLabelAsAdminResponse,
} from './downloadShippingLabelAsAdmin.types'

export const downloadShippingLabelAsAdmin = async (
  query: DownloadShippingLabelAsAdminData
) => {
  const { href } = getDownloadShippingLabelAsAdminUrl(query)
  const response =
    await axiosExtended.get<DownloadShippingLabelAsAdminResponse>(href, {
      withAuth: true,
      responseType: 'blob',
    })

  return response.data
}
