import { axiosExtended } from 'api/axios'

import { getEditLocationUrl } from '../urls'
import { EditLocationData, EditLocationResponse } from './editLocation.types'

export const editLocation = async (data: EditLocationData) => {
  const { href } = getEditLocationUrl()
  const response = await axiosExtended.put<EditLocationResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
