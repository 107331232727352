import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const DownloadIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path d='M19 15v3H5v-3H3v5h18v-5h-2Z' fill='currentColor' />
    <path
      d='m18 10-1.41-1.41L13 12.17V3h-2v9.17L7.41 8.58 6 10l6 6 6-6Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
