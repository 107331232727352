import { axiosExtended } from 'api/axios'

import { getOrderGraphUrl } from '../urls'
import { GetOrderGraphResponse } from './getOrderGraph.types'

export const getOrderGraph = async (today: number) => {
  const { href } = getOrderGraphUrl(today)
  const response = await axiosExtended.get<GetOrderGraphResponse>(href, {
    withAuth: true,
  })
  return response.data
}
