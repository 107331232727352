import { downloadOrderFiles } from 'api/requests/order'
import { saveAs } from 'file-saver'
import { OrderDocumentTypes } from 'types/enums'

export const downloadOrderFile = async (
  orderId: string | number,
  fileType: OrderDocumentTypes,
  fileName: string
) => {
  try {
    const response = (await downloadOrderFiles({
      orderId,
      type: fileType,
    })) as unknown as Blob

    saveAs(response, fileName)
  } catch (error) {
    console.error(error)
  }
}
