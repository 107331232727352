import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const AddWatchlistIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M19.82 10.5A9.77 9.77 0 0 0 11 5a9.77 9.77 0 0 0-8.82 5.5A9.77 9.77 0 0 0 11 16c.682 0 1.352-.07 2-.202v2.033c-.65.111-1.318.169-2 .169-5 0-9.27-3.11-11-7.5C1.73 6.11 6 3 11 3s9.27 3.11 11 7.5c-.204.519-.445 1.02-.717 1.5h-2.369c.342-.467.645-.968.906-1.5Z'
      fill='currentColor'
    />
    <path d='M20 13H18V16H15V18H18V21H20V18H23V16H20V13Z' fill='currentColor' />
    <path
      d='M20 13h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3ZM11 14.5A4 4 0 0 1 7.03 11H11L8.556 7.333A4 4 0 1 1 11 14.5Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
