import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-input-2/lib/material.css'

import { CacheProvider, EmotionCache } from '@emotion/react'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_PUBLISHABLE_KEY } from 'constants/env'
import { NotificationsProvider, UserProvider } from 'context'
import { NextPageWithLayout } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { NextAdapter } from 'next-query-params'
import * as React from 'react'
import { ReactNode, useEffect } from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import { createEmotionCache, lightThemeOptions } from 'theme'
import { QueryParamProvider } from 'use-query-params'
import { QueryParamAdapter } from 'use-query-params/src/types'

function Adapter(props: {
  children: (adapter: QueryParamAdapter) => React.ReactElement | null
}) {
  return <NextAdapter {...props} />
}

const clientSideEmotionCache = createEmotionCache()
const theme = createTheme(lightThemeOptions)

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  Component: NextPageWithLayout
}

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  useEffect(() => {
    // Workaround to fix FOUC(https://en.wikipedia.org/wiki/Flash_of_unstyled_content)
    document.documentElement.style.visibility = 'visible'
  }, [])

  const now = Date.now()

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <title key='title'>
            Bidsheet.io: Online Auctions for Wholesale Electronics
          </title>
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1, viewport-fit=cover, maximum-scale=1, user-scalable=0'
          />
          <meta
            name='keywords'
            content='Auctions, Used, Laptops, Desktops, Phones, Wholesale, Electronics, Bulk, Refurbished, Buy, Sell, Suppliers'
          />
          <meta
            name='description'
            content='Buy wholesale Laptops, Desktops, Servers, Phones, Computer Parts and more on bidsheet.io - Bid on Live Auctions'
          />
          <meta name='robots' content='index, follow' />
          <meta property='og:type' content='website' />
          <meta property='og:url' content='https://bidsheet.io/' />
          <meta
            property='og:site_name'
            content='Bidsheet.io: Online Auctions for Wholesale Electronics'
          />
          <meta
            property='og:title'
            content='Bidsheet.io: Online Auctions for Wholesale Electronics'
          />
          <meta
            property='og:description'
            content='Buy wholesale Laptops, Desktops, Servers, Phones, Computer Parts and more on bidsheet.io - Bid on Live Auctions'
          />
          <meta property='og:image:width' content='1000' />
          <meta property='og:image:height' content='1000' />
          <meta
            property='og:image'
            content='https://bidsheet-marketing-assets.s3.us-west-1.amazonaws.com/bidsheet-og.jpg'
          />
          <meta
            property='og:see_also'
            content='https://www.linkedin.com/company/bidsheet/'
          />
          <meta property='twitter:card' content='summary_large_image' />
          <meta property='twitter:url' content='https://bidsheet.io/' />
          <meta
            property='twitter:title'
            content='Bidsheet.io: Online Auctions for Wholesale Electronics'
          />
          <meta
            property='twitter:description'
            content='Buy wholesale Laptops, Desktops, Servers, Phones, Computer Parts and more on bidsheet.io - Bid on Live Auctions'
          />
          <meta
            property='twitter:image'
            content='https://bidsheet-marketing-assets.s3.us-west-1.amazonaws.com/bidsheet-og.jpg'
          />
        </Head>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <UserProvider>
            <NotificationsProvider>
              <QueryParamProvider
                adapter={Adapter}
                options={{ updateType: 'replaceIn' }}
              >
                <Elements
                  stripe={stripePromise}
                  options={{
                    locale: 'en',
                  }}
                >
                  {getLayout(<Component {...pageProps} />)}
                </Elements>
              </QueryParamProvider>
            </NotificationsProvider>
          </UserProvider>
          <ToastContainer
            position='top-center'
            autoClose={5000}
            theme='dark'
            icon={false}
            closeButton={false}
            hideProgressBar
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
            transition={Slide}
          />
        </ThemeProvider>
      </CacheProvider>

      <div
        data-name='termly-embed'
        data-id='732504e4-461e-4ffa-a936-8be41ad7db02'
        data-type='iframe'
      />
      <Script
        type='text/javascript'
        src={`https://app.termly.io/embed.min.js?v=${now}`}
        data-auto-block='off'
        data-website-uuid='8e067bdf-319a-46b3-83f5-ee130a223876'
      />
      <Script id='termly' strategy='beforeInteractive'>
        {`(function(d, s, id) { var js, tjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = \"https://app.termly.io/embed-policy.min.js?v=${now}\"; tjs.parentNode.insertBefore(js, tjs); }(document, 'script', 'termly-jssdk'));`}
      </Script>
      <Script id='jsonschema' type='application/ld+json'>
        {`
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Bidsheet.io: Online Auctions for Wholesale Electronics",
          "description": "Buy wholesale Laptops, Desktops, Servers, Phones, Computer Parts and more on bidsheet.io - Bid on Live Auctions",
          "url": "https://bidsheet.io/",
          "image": "https://bidsheet-marketing-assets.s3.us-west-1.amazonaws.com/bidsheet-logo.png",
          "publisher": {
              "@type": "Organization",
              "name": "Bidsheet",
              "legalName": "Bidsheet",
              "url": "https://bidsheet.io/",
              "@id": "https://bidsheet.io/#organization",
              "logo": {
                  "@type": "ImageObject",
                  "url": "https://bidsheet-marketing-assets.s3.us-west-1.amazonaws.com/bidsheet-logo.png"
              },
              "sameAs": [
                          "https://www.linkedin.com/company/bidsheet/",
                        ],
              "founder": {
                  "@type": "Person",
                  "name": "Ferhat Gulbahar"
              }
          }
          `}
      </Script>
      <Script
        id='google-analytics'
        strategy='afterInteractive'
        src='https://www.googletagmanager.com/gtag/js?id=G-RJTJB5XG61'
      />
      <Script id='google-analytics-init' strategy='afterInteractive'>
        {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-RJTJB5XG61');`}
      </Script>

      <Script id='helpscout' strategy='afterInteractive'>
        {`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net?v=${now}",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`}
      </Script>
      <Script id='helpscout-init' strategy='afterInteractive'>
        {`window.Beacon('config', { color: '#009970'}); window.Beacon('init', '8f8731dd-1de0-4367-9a97-61c1c3d36262')`}
      </Script>
    </>
  )
}

export default MyApp
