import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const InWatchlistIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M11 18C6 18 1.73 14.89 0 10.5C1.73 6.11 6 3 11 3C16 3 20.27 6.11 22 10.5C21.7955 11.0189 21.5555 11.5199 21.2831 12H18.9143C19.2558 11.5332 19.5595 11.0321 19.82 10.5C18.17 7.13 14.79 5 11 5C7.21 5 3.83 7.13 2.18 10.5C3.83 13.87 7.21 16 11 16C11.3369 16 11.6705 15.9832 12 15.9501V17.9582C11.6703 17.9859 11.3368 18 11 18Z'
      fill='currentColor'
    />
    <path
      d='M10.9999 14.5C8.96013 14.5 7.27693 12.9732 7.03088 11H11L8.55564 7.33346C9.23159 6.81092 10.0795 6.5 10.9999 6.5C13.2091 6.5 14.9999 8.29086 14.9999 10.5C14.9999 12.7091 13.2091 14.5 10.9999 14.5Z'
      fill='currentColor'
    />
    <path
      d='M17.91 21L14.57 17.66L15.98 16.25L17.9 18.17L22.57 13.5L24 14.91L17.91 21Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
