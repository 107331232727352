import { axiosExtended } from 'api/axios'

import { getAdminBankAccountSessionUrl } from '../urls'
import { GetAdminBankAccountSessionResponse } from './getAdminBankAccountSession.types'

export const getAdminBankAccountSession = async () => {
  const { href } = getAdminBankAccountSessionUrl()
  const response = await axiosExtended.get<GetAdminBankAccountSessionResponse>(
    href,
    {
      withAuth: true,
    }
  )

  return response.data
}
