import { axiosExtended } from 'api/axios'
import { serialize } from 'object-to-formdata'

import { getSetUserSettingsAsAdminUrl } from '../urls'
import {
  SetUserSettingsAsAdminData,
  SetUserSettingsAsAdminResponse,
} from './setUserSettingsAsAdmin.types'

export const setUserSettingsAsAdmin = async (
  data: SetUserSettingsAsAdminData
) => {
  const { href } = getSetUserSettingsAsAdminUrl()
  const response = await axiosExtended.post<SetUserSettingsAsAdminResponse>(
    href,
    serialize(data),
    {
      withAuth: true,
    }
  )
  return response.data
}
