import { axiosExtended } from 'api/axios'

import { getAuctionCategoryListUrl } from '../urls'
import {
  GetAuctionCategoryListData,
  GetAuctionCategoryListResponse,
} from './getAuctionCategoryList.types'

export const getAuctionCategoryList = async (
  query?: GetAuctionCategoryListData
) => {
  const { href } = getAuctionCategoryListUrl(query)
  const response = await axiosExtended.get<GetAuctionCategoryListResponse>(
    href,
    { withAuth: true }
  )
  return response.data
}
