import { FC, SVGProps } from 'react'

export const SvgWrapper: FC<SVGProps<SVGSVGElement>> = ({
  color = '#222',
  width = 24,
  height = 24,
  fillOpacity = 1,
  ...props
}) => (
  <svg
    color={color}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fillOpacity={fillOpacity}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    {props.children}
  </svg>
)
