import { axiosExtended } from 'api/axios'

import { getDownloadLotFileUrl } from '../urls'
import { DownloadLotFileResponse } from './downloadLotFile.types'

export const downloadLotFile = async (id: string) => {
  const { href } = getDownloadLotFileUrl(id)
  const response = await axiosExtended.get<DownloadLotFileResponse>(href, {
    withAuth: true,
    responseType: 'blob',
  })

  return response.data
}
