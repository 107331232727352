import { SelectChangeEvent } from '@mui/material'
import {
  getAuctionList,
  GetAuctionListData,
  GetAuctionListResponse,
} from 'api/requests/auction'
import { useRouter } from 'next/router'
import { ChangeEventHandler, useEffect, useState } from 'react'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

export const useBuyerAuctions = (perPage?: number, syncWithUrl = false) => {
  const [buyerData, setBuyerData] = useState<
    GetAuctionListResponse | undefined
  >()
  const router = useRouter()
  const [total, setTotal] = useState<number>(0)

  const [count, setCount] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [notEmpty, setNotEmpty] = useState<boolean | undefined>(false)

  const [sortQuery, setSortQuery] = useQueryParam(
    'sort',
    withDefault(StringParam, 'newest')
  )
  const [sort, setSort] = useState<string>(() =>
    syncWithUrl ? sortQuery : 'newest'
  )

  useEffect(() => {
    if (!syncWithUrl) {
      return
    }

    setSortQuery(sort)
  }, [sort])

  const fetchAuctionList = async (requestData?: GetAuctionListData) => {
    try {
      const response = await getAuctionList(requestData)
      const totalItems = response?.data?.total || 0
      const isNotEmpty = response?.data?.isNotEmpty
      setBuyerData(response?.data)

      setNotEmpty(isNotEmpty)
      setTotal(totalItems)
      if (perPage) {
        setCount(Math.ceil(totalItems > 0 ? totalItems / perPage : 1))
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleSearchChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    const value = e.target.value
    setSearch(value)
    if (value.length) {
      void router.push('/auctions', {
        query: {
          searchParam: value,
        },
      })
    } else {
      void router.push('/auctions')
    }
  }

  const handleClearSearchClick = () => {
    setSearch('')
    void router.push('/auctions')
  }

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setSort(event.target.value as string)
  }

  return {
    buyerData,
    total,
    count,
    search,
    fetchAuctionList,
    handleSearchChange,
    handleClearSearchClick,
    sort,
    notEmpty,
    handleChange,
    setSearch,
  }
}
