import { axiosExtended } from 'api/axios'
import { serialize } from 'object-to-formdata'

import { getUploadSignedShippingLabelUrl } from '../urls'
import { UploadSignedShippingLabelData } from './uploadSignedShippingLabel.types'

export const uploadSignedShippingLabel = async (
  data: UploadSignedShippingLabelData
) => {
  const { href } = getUploadSignedShippingLabelUrl()
  // TODO: replace "any" with real response' type
  const response = await axiosExtended.post<any>(href, serialize(data), {
    withAuth: true,
  })
  return response.data
}
