import { Box, IconButton, Modal, SvgIcon, Typography } from '@mui/material'
import { ButtonCustom } from 'components/Button'
import { ButtonWithIcon } from 'components/ButtonWIthIcon'
import { CircledClearIcon, ClearIcon, RepostIcon } from 'icons'
import React, { FC, PropsWithChildren } from 'react'

import { ModalCustomProps } from './ModalCustom.types'

export const ModalCustom: FC<PropsWithChildren<ModalCustomProps>> = ({
  open,
  onCloseClick,
  children,
  title,
  isCloseIcon = false,
  width = '480px',
  maxWidth = '95vw',
  cancelText = 'Cancel',
  acceptText = 'Accept',
  isLeftSideControl = false,
  leftSideCheckbox = null,
  onAcceptClick,
  onClearClick,
  isReset = false,
  isCancelButton = true,
  isAcceptButton = true,
  isAcceptDisabled = false,
  isClearDisabled = false,
  onKeyDown,
}) => {
  const handleClose = () => onCloseClick()

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onKeyDown={onKeyDown}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width,
          maxWidth,
          bgcolor: '#FFFFFF',
          boxShadow:
            '0px 0px 2px rgba(34, 34, 34, 0.1), 0px 4px 9px rgba(34, 34, 34, 0.06)',
          borderRadius: '12px',
          padding: '21px 24px 24px',
          outline: 'none',
        }}
      >
        {title && (
          <Box
            sx={{ mb: '18px' }}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            {typeof title === 'string' ? (
              <Typography variant='h2'>{title}</Typography>
            ) : (
              title
            )}

            {isCloseIcon && (
              <IconButton onClick={handleClose} sx={{ m: '-9px' }}>
                <SvgIcon>
                  <ClearIcon
                    width={24}
                    height={24}
                    color='rgba(34, 34, 34, 1)'
                  />
                </SvgIcon>
              </IconButton>
            )}
          </Box>
        )}
        <Box
          sx={{
            mb: '26px',
            maxHeight: 'calc(100vh - 158px)',
            overflowY: 'overlay',
          }}
          id='modal-modal-description'
        >
          {children}
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            {isLeftSideControl && (
              <>
                {leftSideCheckbox ? (
                  leftSideCheckbox
                ) : (
                  <ButtonWithIcon
                    onClick={onClearClick}
                    Icon={
                      isReset ? (
                        <RepostIcon
                          width={24}
                          height={24}
                          color='rgba(34, 34, 34, 1)'
                        />
                      ) : (
                        <CircledClearIcon
                          width={24}
                          height={24}
                          color='rgba(34, 34, 34, 1)'
                        />
                      )
                    }
                    title={isReset ? 'Reset to default' : 'Clear'}
                    size={'small'}
                    variant={'text'}
                    disabled={isClearDisabled}
                  />
                )}
              </>
            )}
          </Box>
          <Box>
            {isCancelButton && (
              <ButtonCustom
                height='40px'
                fontSize={'16px'}
                onClick={handleClose}
                sx={{ mr: isAcceptButton ? '14px' : '0' }}
                width='auto'
                variant='text'
              >
                {cancelText}
              </ButtonCustom>
            )}
            {isAcceptButton && (
              <ButtonCustom
                disabled={isAcceptDisabled}
                onClick={onAcceptClick}
                width='auto'
                height='40px'
                fontSize={'16px'}
              >
                {acceptText}
              </ButtonCustom>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
