import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const FilterIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 7V4h7v8h-7V9H2V7h13Zm2-1h3v4h-3V6ZM9 16v-3H2v8h7v-3h13v-2H9Zm-5 3h3v-4H4v4Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
