import {
  downloadAuctionTemplates,
  getAuctionManufacturerList,
  getAuctionTemplateList,
  getAuctionTemplateRules,
} from 'api/requests/auction'
import { capitalize, head, uniq } from 'lodash'
import { ChangeEvent, useRef, useState } from 'react'
import {
  AuctionAssetList,
  AuctionCategory,
  AuctionCreateTable,
  AuctionCreateTemplate,
  AuctionRules,
  AuctionTemplateMeta,
  AuctionTemplateRule,
} from 'types/auction'
import { AttributeTypes } from 'types/enums'
import { Manufacturer } from 'types/lot'
import {
  getParsedAuctionTemplate,
  getTemplateType,
  getValuesByColumnName,
  isAuctionTemplate,
} from 'utils/auction'
import { hasExtension, validateFiles } from 'utils/file'
import { normalizeString } from 'utils/string'
import * as Xlsx from 'xlsx'

export const useAssetsUpload = (
  setActiveStep: (step: number) => void,
  manufacturersTotal: number
) => {
  const firstUploaderRef = useRef<HTMLInputElement>(null)
  const thirdPartyUploaderRef = useRef<HTMLInputElement>(null)

  const [firstUploaderError, setFirstUploaderError] = useState(false)
  const [thirdPartyUploaderError, setThirdPartyUploaderError] = useState(false)

  const [selectedTemplates, setSelectedTemplates] = useState<
    AuctionTemplateMeta[]
  >([])
  const [predefinedTemplates, setPreDefinedTemplates] = useState<
    AuctionTemplateMeta[]
  >([])

  const [isAlternativeFlow, setIsAlternativeFlow] = useState(false)
  const [thirdPartyFiles, setThirdPartyFiles] = useState<File[]>([])
  const [rules, setRules] = useState<AuctionRules>({})
  const [selectedManufacturers, setSelectedManufacturers] = useState<
    Manufacturer[]
  >([])

  const [tabs, setTabs] = useState<{ label: string }[]>([])
  const [validRows, setValidRows] = useState(0)
  const [tables, setTables] = useState<AuctionCreateTable[]>([])
  const [assets, setAssets] = useState<AuctionAssetList>([])

  const fetchAuctionTemplateList = async () => {
    const auctionTemplatesResponse = await getAuctionTemplateList()
    if (auctionTemplatesResponse.data) {
      setPreDefinedTemplates(auctionTemplatesResponse.data)
    }
  }

  const handleAuctionTemplateDownload = async () => {
    if (!selectedTemplates.length) {
      return
    }

    const response = await downloadAuctionTemplates({
      types: selectedTemplates.map(selectedTemplate => selectedTemplate.id),
    })

    const fileName =
      selectedTemplates.length > 1
        ? 'Auction_templates'
        : head(selectedTemplates)?.name
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    saveAs(response, fileName)
  }

  const handleAuctionTemplateChange = (
    checked: boolean,
    predefinedTemplate: AuctionTemplateMeta
  ) => {
    setSelectedTemplates(oldSelectedTemplates => {
      return checked
        ? [...oldSelectedTemplates, predefinedTemplate]
        : oldSelectedTemplates.filter(
            template => template.id !== predefinedTemplate.id
          )
    })
  }

  const handleAddAuctionTemplate = (files: File[]) => {
    files.forEach(file => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = async e => {
        const bufferArray = e?.target?.result
        const wb = Xlsx.read(bufferArray, { type: 'buffer' })
        const sheets: AuctionCreateTemplate[][] = Object.values(wb.Sheets).map(
          sheet => Xlsx.utils.sheet_to_json(sheet)
        )
        const template: AuctionCreateTemplate[] = sheets[0]
        const foundTemplateNames = uniq(
          template
            .map(row =>
              Object.entries(row)
                .filter(([column]) => normalizeString(column) === 'type')
                .map(([, value]) => value)
            )
            .flat()
        )

        const templateType = getTemplateType(template)
        const validTemplates: AuctionTemplateMeta[] =
          predefinedTemplates.filter(predefinedTemplate => {
            if (templateType === 'asset') {
              return predefinedTemplate.name === 'asset'
            }

            return foundTemplateNames.some(
              templateName =>
                normalizeString(templateName as string) ===
                normalizeString(predefinedTemplate.name)
            )
          })

        const rulesResponse = await getAuctionTemplateRules({
          types: validTemplates.map(validTemplate => validTemplate.id),
        })

        const rules = rulesResponse.data
        if (!rules) {
          return
        }

        const rulesByTemplateName: AuctionCreateTemplate = rules.reduce(
          (acc, rule) => {
            return {
              ...acc,
              [rule.name]: rule.rules,
            }
          },
          {}
        )

        const templateRules = rulesByTemplateName[templateType]

        setRules(oldRules => ({
          ...oldRules,
          [templateType]: templateRules as AuctionTemplateRule[],
        }))

        const {
          columns,
          template: parsedTemplate,
          unmatchedRows: newUnmatchedRows,
          totalItems,
        } = getParsedAuctionTemplate(
          template,
          templateRules as AuctionTemplateRule[]
        )

        const rows = parsedTemplate.map((item, index) => ({
          cells: [
            ...Object.values(item).map(value => ({ title: String(value) })),
          ],
          id: index,
        }))

        const manufacturersFromTemplate = getValuesByColumnName(
          parsedTemplate,
          'manufacturer'
        )
        const allManufacturersResponse = await getAuctionManufacturerList({
          perPage: manufacturersTotal,
        })
        const foundManufacturers = uniq(
          (manufacturersFromTemplate as string[]).reduce(
            (acc, manufacturerFromTemplate) => {
              const foundManufacturer =
                allManufacturersResponse.data?.items.find(
                  manufacturer =>
                    manufacturer.value === manufacturerFromTemplate
                )

              if (foundManufacturer) {
                acc.push(foundManufacturer)
              }

              return acc
            },
            [] as Manufacturer[]
          )
        )
        setSelectedManufacturers(foundManufacturers)

        const isDuplicate = tables.some(table => table.type === templateType)
        setTables(oldTables => {
          if (isDuplicate) {
            return oldTables.map(table => {
              return table.type === templateType
                ? {
                    ...table,
                    data: { rows: [...table.data.rows, ...rows] },
                    unmatchedRows: table.unmatchedRows + newUnmatchedRows,
                    quantity: Number(table.quantity) + totalItems,
                  }
                : table
            })
          }

          return [
            ...oldTables,
            {
              type: templateType,
              unmatchedRows: newUnmatchedRows,
              columns: columns.map((item, index) => {
                const attribute = (templateRules as AuctionTemplateRule[]).find(
                  rule => normalizeString(rule.name) === normalizeString(item)
                )
                return { title: item, id: attribute?.id ?? index }
              }),
              data: { rows },
              quantity: totalItems,
            },
          ]
        })
      }
    })
  }

  const resetFirstUploader = () => {
    if (firstUploaderRef?.current) {
      firstUploaderRef.current.files = null
    }
  }

  const handleAuctionFilesUpload = async (files: File[]) => {
    if (!files.length) {
      return
    }

    const { status } = validateFiles(files, [], 15, 10485760)
    if (status === 'error') {
      setFirstUploaderError(true)
      resetFirstUploader()
      return
    }

    setFirstUploaderError(false)

    if (isAlternativeFlow) {
      setThirdPartyFiles(files)
      return
    }

    try {
      const allFilesExcels = files.every(file => hasExtension(file, ['xlsx']))
      if (!allFilesExcels) {
        if (tables.length === 0) {
          setIsAlternativeFlow(true)
          setThirdPartyFiles(files)
          setActiveStep(1)
        }
        return
      }

      const isAuctionTemplatesResponse = await Promise.allSettled(
        files.map(file => isAuctionTemplate(file, predefinedTemplates))
      )
      const allFilesValidTemplates = isAuctionTemplatesResponse.every(
        ({ status }) => status === 'fulfilled'
      )
      if (!allFilesValidTemplates) {
        if (tables.length === 0) {
          setIsAlternativeFlow(true)
          setThirdPartyFiles(files)
          setActiveStep(1)
        }
        return
      }

      handleAddAuctionTemplate(files)
    } catch (error) {
      console.error(error)
    }
  }

  const resetThirdParty = () => {
    if (thirdPartyUploaderRef?.current?.files) {
      thirdPartyUploaderRef.current.files = null
    }
  }

  const handleThirdPartyUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ?? []
    if (!files.length) {
      return
    }

    const { status, data } = validateFiles(files, thirdPartyFiles, 15, 10485760)
    if (status === 'success') {
      setThirdPartyFiles(data)
      setActiveStep(1)
      setThirdPartyUploaderError(false)
    } else {
      setThirdPartyUploaderError(true)
      resetThirdParty()
    }
  }

  const handleRemoveThirdPartyFiles = (index: number) => {
    setThirdPartyFiles(oldFiles => {
      return oldFiles.filter((file, indexToCompare) => indexToCompare !== index)
    })
  }

  const generateTabs = (tables: AuctionCreateTable[]) => {
    const newTabs = tables.map(table => {
      return {
        label: `${capitalize(normalizeString(table.type))} (${table.quantity})`,
      }
    })
    setTabs(newTabs)
  }

  const generateValidRows = (tables: AuctionCreateTable[]) => {
    const newValidRows = tables.reduce((acc, table) => {
      acc += table.data.rows.length
      return acc
    }, 0)

    setValidRows(newValidRows)
    return newValidRows
  }

  const generateSelectedCategories = (
    tables: AuctionCreateTable[],
    categories: AuctionCategory[]
  ) => {
    return tables
      .map(table => {
        if (table.type === 'asset') {
          const categoryIndex = table.columns.findIndex(
            column => normalizeString(column.title) === 'type'
          )
          const templateCategories = uniq(
            table.data.rows.map(row => row.cells[categoryIndex].title)
          )
          return categories
            .filter(category =>
              templateCategories.some(
                templateCategory =>
                  normalizeString(templateCategory) ===
                  normalizeString(category.value)
              )
            )
            .map(category => category.id)
        }

        return categories
          .filter(
            category =>
              normalizeString(category.value) === normalizeString(table.type)
          )
          .map(category => category.id)
      })
      .flat()
  }

  const generateAssets = (
    tables: AuctionCreateTable[],
    rules: AuctionRules
  ) => {
    const newAssets = tables
      .map(table => {
        return table.data.rows.map(row => {
          return row.cells
            .map(({ title }) => title)
            .map((value, cellIndex) => {
              const attributeId = table.columns[cellIndex].id
              const attribute = rules[table.type].find(
                desktopAttribute => desktopAttribute.id === attributeId
              )

              if (
                attribute?.type &&
                [AttributeTypes.Plain, AttributeTypes.PreDefined].includes(
                  attribute.type as unknown as AttributeTypes
                )
              ) {
                return [{ type: attribute?.type, attributeId, value }]
              }

              if (
                (attribute?.type as unknown as AttributeTypes) ===
                AttributeTypes.Asset
              ) {
                return [value].map(value => {
                  const newValue = value.match(/\(\d+\)$/)
                  const quantity = newValue
                    ? Number(newValue[0].replace(/[()]/g, ''))
                    : 1

                  return {
                    type: attribute?.type,
                    attributeId,
                    subAsset: {
                      value,
                      quantity,
                    },
                  }
                })
              }
            })
            .flat()
        })
      })
      .flat()

    setAssets(newAssets as unknown as AuctionAssetList)
  }

  return {
    firstUploaderRef,
    thirdPartyUploaderRef,
    firstUploaderError,
    thirdPartyUploaderError,
    setFirstUploaderError,
    setThirdPartyUploaderError,
    selectedTemplates,
    setSelectedTemplates,
    predefinedTemplates,
    isAlternativeFlow,
    thirdPartyFiles,
    setThirdPartyFiles,
    rules,
    selectedManufacturers,
    setSelectedManufacturers,
    tables,
    tabs,
    validRows,
    assets,
    fetchAuctionTemplateList,
    resetFirstUploader,
    handleAuctionTemplateDownload,
    handleAuctionTemplateChange,
    handleAuctionFilesUpload,
    resetThirdParty,
    handleThirdPartyUpload,
    handleRemoveThirdPartyFiles,
    generateTabs,
    generateValidRows,
    generateSelectedCategories,
    generateAssets,
  }
}
