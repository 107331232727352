import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const ShippingIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M21 8h-3V4H0v14h3c0 1.66 1.34 3 3 3s3-1.34 3-3h7c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-6l-3-4Zm-.5 1.5 1.96 2.5H18V9.5h2.5ZM6 19c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Zm2.22-3c-.55-.61-1.33-1-2.22-1-.89 0-1.67.39-2.22 1H2V6h14v10H8.22ZM19 19c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
