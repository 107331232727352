import { API_URL } from 'constants/env'

type RedirectParams = {
  email?: string
  userId?: number
}

export const getPaymentRedirectUrl = (
  redirectUrl: URL,
  { email, userId }: RedirectParams
): URL => {
  return new URL(
    `${API_URL}/stripe/redirect?redirect=${redirectUrl.origin}${
      redirectUrl.pathname
    }${email ? `&email=${email}` : ''}${userId ? `&userId=${userId}` : ''}`
  )
}
