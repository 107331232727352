import { Typography } from '@mui/material'
import Link from 'next/link'
import React, { FC } from 'react'

import { Props } from './LinkedText.types'

export const LinkedText: FC<React.PropsWithChildren<Props>> = ({
  to,
  children,
  fontSize = 16,
  fontWeight = 600,
  wordBreak = 'normal',
  marginLeft = '4px',
  isExternal,
  className = '',
}) => {
  return isExternal ? (
    <Typography
      component={'a'}
      target={'_blank'}
      href={to}
      color={'primary.main'}
      fontWeight={fontWeight}
      fontSize={fontSize}
      sx={{
        wordBreak,
        cursor: 'pointer',
        ml: { marginLeft },
        textDecoration: 'none',
      }}
      className={className}
    >
      {children}
    </Typography>
  ) : (
    <Link href={to} passHref>
      <Typography
        component={'span'}
        color={'primary.main'}
        fontWeight={fontWeight}
        fontSize={fontSize}
        sx={{
          wordBreak,
          cursor: 'pointer',
          ml: { marginLeft },
        }}
        className={className}
      >
        {children}
      </Typography>
    </Link>
  )
}
