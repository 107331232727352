import { axiosExtended } from 'api/axios'

import { getRejectUserAsAdminUrl } from '../urls'
import { RejectUserAsAdminData } from './rejectUserAsAdmin.types'

export const rejectUserAsAdmin = async (data: RejectUserAsAdminData) => {
  const { href } = getRejectUserAsAdminUrl()
  // TODO: add corresponding type for the response
  // eslint-disable-next-line
  const response = await axiosExtended.put<any>(href, data, {
    withAuth: true,
  })
  return response.data
}
