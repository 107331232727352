import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const MergeIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='m3 7.387 1.4-1.4 5 5h8.75l-3.575-3.575L16 5.987l6 6-6.025 6.025-1.425-1.425 3.6-3.6H8.6L3 7.387Zm-.025 9.2 3.175-3.2 1.425 1.425-3.2 3.175-1.4-1.4Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
