import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const StripeLogo: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M74.427 20.387c0-4.602-2.23-8.234-6.49-8.234-4.278 0-6.867 3.632-6.867 8.198 0 5.412 3.056 8.144 7.443 8.144 2.139 0 3.757-.485 4.98-1.169v-3.595c-1.223.611-2.625.989-4.405.989-1.744 0-3.29-.611-3.488-2.733h8.791c0-.233.036-1.168.036-1.6Zm-8.88-1.708c0-2.031 1.24-2.876 2.372-2.876 1.097 0 2.265.845 2.265 2.876h-4.638ZM54.13 12.153c-1.761 0-2.894.827-3.523 1.403l-.234-1.115h-3.955v20.962l4.495-.953.018-5.088c.647.468 1.6 1.133 3.181 1.133 3.218 0 6.149-2.589 6.149-8.288-.018-5.213-2.984-8.054-6.13-8.054ZM53.052 24.54c-1.06 0-1.69-.377-2.121-.845l-.018-6.67c.467-.521 1.114-.88 2.139-.88 1.636 0 2.768 1.833 2.768 4.188 0 2.41-1.114 4.207-2.768 4.207ZM40.234 11.093l4.512-.97v-3.65l-4.512.952v3.668Z'
      fill='currentColor'
    />
    <path d='M44.746 12.46h-4.512v15.73h4.512V12.46Z' fill='currentColor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m35.398 13.79-.288-1.331h-3.883v15.73h4.494V17.53c1.061-1.385 2.859-1.133 3.416-.935v-4.135c-.575-.216-2.678-.611-3.74 1.33ZM26.41 8.558l-4.387.935-.018 14.4c0 2.66 1.995 4.62 4.656 4.62 1.474 0 2.553-.27 3.146-.593v-3.65c-.575.234-3.416 1.061-3.416-1.6v-6.382h3.416V12.46H26.39l.018-3.901ZM14.256 17.025c0-.7.576-.97 1.528-.97 1.367 0 3.092.413 4.459 1.15V12.98c-1.492-.593-2.966-.827-4.459-.827-3.649 0-6.076 1.906-6.076 5.088 0 4.962 6.832 4.17 6.832 6.31 0 .827-.72 1.097-1.726 1.097-1.492 0-3.398-.611-4.908-1.438v4.278a12.462 12.462 0 0 0 4.908 1.025c3.739 0 6.31-1.852 6.31-5.07-.018-5.357-6.868-4.404-6.868-6.418Z'
      fill='#0A2540'
    />
  </SvgWrapper>
)
