import { axiosExtended } from 'api/axios'

import { getAssignTransactionUrl } from '../urls'
import {
  AssignTransactionData,
  AssignTransactionResponse,
} from './assignTransaction.types'

export const assignTransaction = async (data: AssignTransactionData) => {
  const { href } = getAssignTransactionUrl()
  const response = await axiosExtended.post<AssignTransactionResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
