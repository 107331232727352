import { format as formatDateFn } from 'date-fns'

interface DateFormatOptions {
  locale?: Locale
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
  firstWeekContainsDate?: number
  useAdditionalWeekYearTokens?: boolean
  useAdditionalDayOfYearTokens?: boolean
}

type AllowedDateFormats =
  | 'HH:mm'
  | 'MMM d'
  | 'HH:mm:ss'
  | 'h:mm aaa'
  | 'M/dd/yyyy, p'
  | 'MMMM, yyyy'
  | 'MMM dd, yyyy'
  | 'MMMM dd, yyyy'
  | 'MMM d, h:mm a'
  | 'MMM d, h:mm aaa'
  | 'h:mm aaa, MMMM d, yyyy'
  | "EEEE, do 'at' h:mm aaa"
  | "MMM d, yyyy 'at' h:mm aa"
  | "MMM d, yyyy 'at' h:mm aaa"
  | "MMMM d, yyyy 'at' h:mm aaa"
  | 'EEEE, h:mm aaa, MMMM d, yyyy'
  | 'MM.d.yyyy h:mm aaa'

export const formatDate = (
  date: Date | number,
  format: AllowedDateFormats,
  options?: DateFormatOptions
) => formatDateFn(date, format, options)
