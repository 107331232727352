import { axiosExtended } from 'api/axios'

import { getGetAuctionListUrl } from '../urls'
import {
  GetAuctionListData,
  GetAuctionListResponse,
} from './getAuctionList.types'

export const getAuctionList = async (query?: GetAuctionListData) => {
  const { href } = getGetAuctionListUrl(query)
  const response = await axiosExtended.get<GetAuctionListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
