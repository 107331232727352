import { axiosExtended } from 'api/axios'

import { getLocationTypesUrl } from '../urls'
import { GetLocationTypesResponse } from './getLocationTypes.types'

export const getLocationTypes = async () => {
  const { href } = getLocationTypesUrl()
  const response = await axiosExtended.get<GetLocationTypesResponse>(href, {
    withAuth: true,
  })
  return response.data
}
