import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { DownloadAuctionTemplatesData } from './downloadAuctionTemplates'
import { GetAuctionData } from './getAuction'
import { GetAuctionBidListData } from './getAuctionBidList'
import { GetAuctionCategoryListData } from './getAuctionCategoryList'
import { GetAuctionListData } from './getAuctionList'
import { GetAuctionListSimpleData } from './getAuctionListSimple'
import { GetAuctionManufacturerListData } from './getAuctionManufacturerList'
import { GetAuctionTemplateRulesData } from './getAuctionTemplateRules'
import { GetConditionsListData } from './getConditionsList'
import { GetManufacturersListData } from './getManufacturersList'
import { SetAuctionWinnerData } from './setAuctionWinner'

export const getAuctionTemplateListUrl = (): URL => {
  const url = `${API_URL}/auction/template/list`
  return new URL(url)
}

export const getAuctionTemplateRulesUrl = (
  query?: GetAuctionTemplateRulesData
): URL => {
  const url = `${API_URL}/auction/template/rules`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getDownloadAuctionTemplatesUrl = (
  query?: DownloadAuctionTemplatesData
): URL => {
  const url = `${API_URL}/auction/template/download`
  if (!query) {
    return new URL(url)
  }

  return new URL(`${url}?types=${query.types.join(',')}`)
}

export const getAuctionCategoryListUrl = (
  query?: GetAuctionCategoryListData
): URL => {
  const url = `${API_URL}/auction/categories`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getAuctionManufacturerListUrl = (
  query?: Partial<GetAuctionManufacturerListData>
): URL => {
  const url = `${API_URL}/auction/manufacturers`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getCreateAuctionUrl = (): URL => {
  const url = `${API_URL}/auction/create`
  return new URL(url)
}

export const getUpdateAuctionUrl = (): URL => {
  const url = `${API_URL}/auction/update`
  return new URL(url)
}

export const getRepostAuctionUrl = (): URL => {
  const url = `${API_URL}/auction/repost`
  return new URL(url)
}

export const getCloseAuctionUrl = (): URL => {
  const url = `${API_URL}/auction/close`
  return new URL(url)
}

export const getEditShippingDetailsUrl = (): URL => {
  const url = `${API_URL}/auction/shipping`
  return new URL(url)
}

export const getDeleteAuctionUrl = (auctionId: string): URL => {
  const url = `${API_URL}/auction/delete`
  const searchParams = getSearchParamsWithSeparatorFromObject({ auctionId })
  return new URL(url + searchParams)
}

export const getGetAuctionListUrl = (query?: GetAuctionListData): URL => {
  const url = `${API_URL}/auction/list`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getGetAuctionListSimpleUrl = (
  query?: GetAuctionListSimpleData
): URL => {
  const url = `${API_URL}/auction/list/simple`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getAuctionUrl = (params?: GetAuctionData): URL => {
  const url = `${API_URL}/auction/`
  if (!params) {
    return new URL(url)
  }

  const { auctionId } = params
  return new URL(url + auctionId)
}

export const getGetAuctionConditionListUrl = (): URL => {
  const url = `${API_URL}/auction/conditions`
  return new URL(url)
}

export const getMakeBidUrl = (): URL => {
  const url = `${API_URL}/auction/bid/make`
  return new URL(url)
}

export const getGetAuctionBidListUrl = (
  params?: GetAuctionBidListData
): URL => {
  const url = `${API_URL}/auction/bid/list/`
  if (!params) {
    return new URL(url)
  }

  const { auctionId } = params
  return new URL(url + String(auctionId))
}

export const getSetAuctionWinnerUrl = (query?: SetAuctionWinnerData) => {
  const url = `${API_URL}/auction/bid/winner`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getDownloadLotFileUrl = (auctionId: string): URL => {
  const url = `${API_URL}/auction/lot/file`
  const searchParams = getSearchParamsWithSeparatorFromObject({ auctionId })
  return new URL(url + searchParams)
}

export const getCreateAuctionCategoryUrl = (): URL => {
  const url = `${API_URL}/admin/auction/categories/create`
  return new URL(url)
}

export const getAddWatchlistUrl = (): URL => {
  const url = `${API_URL}/auction/watchlist/add`
  return new URL(url)
}

export const getRemoveWatchlistUrl = (auctionId: number): URL => {
  const url = `${API_URL}/auction/watchlist/remove`
  const searchParams = getSearchParamsWithSeparatorFromObject({ auctionId })
  return new URL(url + searchParams)
}

export const getRemoveWatchlistAllUrl = (): URL => {
  const url = `${API_URL}/auction/watchlist/remove/all`
  return new URL(url)
}

export const getAuctionCategoriesFavoritesUrl = (): URL => {
  const url = `${API_URL}/auction/categories/favourites`
  return new URL(url)
}

export const getAuctionReminderListUrl = (): URL => {
  const url = `${API_URL}/auction/reminder/list`
  return new URL(url)
}

export const getAuctionCreateReminderUrl = (): URL => {
  const url = `${API_URL}/auction/reminder/create`
  return new URL(url)
}

export const getAuctionReminderUrl = (params?: GetAuctionData): URL => {
  const url = `${API_URL}/auction/reminder/`
  if (!params) {
    return new URL(url)
  }

  const { auctionId } = params
  return new URL(url + auctionId)
}

export const getAuctionLocationListUrl = (): URL => {
  const url = `${API_URL}/auction/locations`
  return new URL(url)
}

export const getManufacturersListUrl = (
  query?: GetManufacturersListData
): URL => {
  const url = `${API_URL}/auction/manufacturers`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getStatusesListUrl = (): URL => {
  const url = `${API_URL}/auction/statuses`
  return new URL(url)
}
export const getConditionsListUrl = (query: GetConditionsListData): URL => {
  const url = `${API_URL}/auction/conditions`
  if (!query) {
    return new URL(url)
  }
  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getAuctionLotSizeUrl = (): URL => {
  const url = `${API_URL}/auction/lotSize`
  return new URL(url)
}
