import { axiosExtended } from 'api/axios'

import { getGetAuctionBidListUrl } from '../urls'
import {
  GetAuctionBidListData,
  GetAuctionBidListResponse,
} from './getAuctionBidList.types'

export const getAuctionBidList = async (data: GetAuctionBidListData) => {
  const { href } = getGetAuctionBidListUrl(data)
  const response = await axiosExtended.get<GetAuctionBidListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
