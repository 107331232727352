import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker'
import { getLocationList } from 'api/requests/location'
import {
  getOrderList,
  GetOrderListData,
  GetOrderListResponse,
} from 'api/requests/order'
import { Order, TableData } from 'components/Datatable'
import { useOrdering, useUserContext } from 'hooks'
import { debounce } from 'lodash'
import { useRouter } from 'next/router'
import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useMount } from 'react-use'
import { UserRoles } from 'types/enums'
import {
  OrderPaymentStatus,
  OrderShipmentStatus,
  OrderStatus,
} from 'types/enums/order'
import { Location } from 'types/location'
import { OrderFilters } from 'types/order'
import {
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { formatCurrency, formatDate } from 'utils/format'
import { smoothScroll } from 'utils/scroll'
import { CommaArrayParam } from 'utils/use-query-params'

export const useUserOrders = () => {
  const router = useRouter()

  const [queryParams, setQueryParams] = useQueryParams({
    order: withDefault(StringParam, 'desc'),
    orderBy: withDefault(StringParam, 'createdAt'),
    search: withDefault(StringParam, ''),
    startDate: DateParam,
    endDate: DateParam,
    shippingStatuses: withDefault(CommaArrayParam, []),
    paymentStatuses: withDefault(CommaArrayParam, []),
    orderStatuses: withDefault(CommaArrayParam, []),
    location: NumberParam,
  })

  const { user } = useUserContext()
  const isSeller = Boolean(
    user?.roles.some(role => role.code === UserRoles.Seller)
  )

  const [orders, setOrders] = useState<GetOrderListResponse['items']>([])

  const [data, setData] = useState<TableData | undefined>()
  const [total, setTotal] = useState<number>(0)

  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  const [search, setSearch] = useState<string>(() => queryParams.search)

  const [locations, setLocations] = useState<Location[]>([])
  const [activeLocation, setActiveLocation] = useState<number | undefined>(
    () => queryParams.location as number
  )

  const [paymentStatus, setPaymentStatus] = useState<OrderPaymentStatus[]>(
    () => queryParams.paymentStatuses as OrderPaymentStatus[]
  )
  const [shippingStatus, setShippingStatus] = useState<OrderShipmentStatus[]>(
    () => queryParams.shippingStatuses as OrderShipmentStatus[]
  )
  const [orderStatus, setOrderStatus] = useState<OrderStatus[]>(
    () => queryParams.orderStatuses as OrderStatus[]
  )
  const [filters, setFilters] = useState<OrderFilters>({})

  const filter =
    orderStatus.length > 1 || orderStatus.length === 0 ? 'all' : orderStatus[0]

  const [dateValues, setDateValues] = useState<DateRange<Date>>(() => [
    queryParams.startDate || null,
    queryParams.endDate || null,
  ])
  const [finalDateValues, setFinalDateValues] = useState<DateRange<Date>>([
    queryParams.startDate || null,
    queryParams.endDate || null,
  ])
  const [notEmpty, setNotEmpty] = useState<boolean | undefined>(false)

  const { order, orderBy, handleRequestSort } = useOrdering(
    queryParams.order as Order,
    queryParams.orderBy
  )

  const query: GetOrderListData = {
    page,
    perPage: 25,
    orderBy: 'createdAt',
    orderType: 'desc',
  }

  const fetchOrdersList = async (queryData?: GetOrderListData) => {
    const ordersList = await getOrderList(queryData)
    const ordersData = ordersList?.data
    const ordersItems = ordersData?.items
    const isNotEmpty = ordersList?.data?.isNotEmpty

    if (ordersItems) {
      setOrders(ordersItems)

      const currentData = {
        rows: ordersItems?.map(
          ({
            id,
            createdAt,
            totalAmount,
            status,
            paymentStatus: currentPaymentStatus,
            shippingStatus: currentShippingStatus,
            location,
            auctionIds,
            consolidatedOrders,
            shippingRequest,
            lotId,
            lotIds,
            referenceId,
          }) => ({
            cells: [
              {
                title: id,
                isConsolidatedOrder: auctionIds.length > 1,
                consolidatedFrom: consolidatedOrders,
              },
              {
                title: createdAt
                  ? formatDate(new Date(createdAt), 'MMMM dd, yyyy')
                  : '',
              },
              {
                title: lotIds.length ? lotIds.join(', ') : lotId,
                tooltipText: referenceId,
                isTextEllipsis: true,
              },
              { title: formatCurrency(totalAmount, { withPrefix: true }) },
              {
                title: `${location.city}, ${location.state}`,
                isTextEllipsis: true,
                maxCellWidth: '158px',
              },
              { title: currentPaymentStatus, isStatus: true },
              {
                title: currentShippingStatus,
                isStatus: true,
                isConsolidatedOrder: auctionIds.length > 1,
              },
              {
                title: isSeller && shippingRequest ? 'action_required' : status,
                isStatus: true,
                textAlign: 'right',
              },
            ],
            id,
            isDisabled: status === 'consolidated',
            isCheckboxDisabled: !['overdue', 'awaitingPayment'].includes(
              currentPaymentStatus as string
            ),
            isActionRequired: isSeller && shippingRequest,
          })
        ),
      }
      setData(currentData)
      setTotal(ordersData.total || 0)
      setCount(Math.ceil(ordersData.total / 25) || 0)
      setNotEmpty(isNotEmpty)
    }
  }

  const debouncedSearch = useCallback(debounce(fetchOrdersList, 500), [])

  useEffect(() => {
    setQueryParams({
      order,
      orderBy,
      startDate: finalDateValues[0],
      endDate: finalDateValues[1],
      search: search || undefined,
      paymentStatuses: filters.paymentStatus?.length
        ? (filters.paymentStatus as string[])
        : undefined,
      shippingStatuses: filters.shippingStatus?.length
        ? (filters.shippingStatus as string[])
        : undefined,
      orderStatuses: filters.status?.length
        ? (filters.status as string[])
        : undefined,
      location: filters.locationId ? filters.locationId : null,
    })
  }, [filters, finalDateValues, search, order, orderBy])

  useEffect(() => {
    if (!user) {
      return
    }

    if (search) {
      query.search = search
    }

    const [startDate, endDate] = finalDateValues

    if (startDate && endDate) {
      const startDateTime = startDate
      const endDateTime = endDate
      startDateTime?.setHours(0, 0, 0, 0)
      endDateTime?.setHours(23, 59, 59, 59)

      query.startDate = startDateTime?.getTime()
      query.endDate = endDateTime?.getTime()
    }

    if (order) {
      query.orderType = order
    }

    if (orderBy) {
      query.orderBy = orderBy
    }

    void debouncedSearch({ ...query, ...filters })
  }, [user, search, page, filters, finalDateValues, orderBy, order])

  useMount(async () => {
    const userLocationsResponse = await getLocationList()

    if (userLocationsResponse.data) {
      setLocations(userLocationsResponse.data)
    }
  })

  const handleClearSearchClick = () => {
    setSearch('')
  }

  const handleSearchChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    const value = e.target.value
    setSearch(value)
  }

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    smoothScroll()
  }

  const applyFilters = () => {
    const newFilters: OrderFilters = {}

    if (paymentStatus?.length) {
      newFilters.paymentStatus = paymentStatus
    }
    if (shippingStatus?.length) {
      newFilters.shippingStatus = shippingStatus
    }
    if (orderStatus?.length) {
      newFilters.status = orderStatus
    }
    if (activeLocation) {
      newFilters.locationId = activeLocation
    }

    setFilters(newFilters)
  }

  useEffect(() => {
    applyFilters()
  }, [])

  const prepareFiltersState = (
    status: (OrderPaymentStatus | OrderShipmentStatus | OrderStatus)[],
    value: OrderPaymentStatus | OrderShipmentStatus | OrderStatus
  ) => {
    let statusCopy = [...status]

    if (statusCopy.includes(value)) {
      statusCopy = statusCopy.filter(item => item !== value)
    } else {
      statusCopy.push(value)
    }

    return statusCopy
  }

  const handleCheckboxChange = (
    value: OrderPaymentStatus | OrderStatus | OrderShipmentStatus,
    type: 'payment' | 'shipping' | 'order'
  ) => {
    if (type === 'payment') {
      const newStatus = prepareFiltersState(paymentStatus, value)
      setPaymentStatus(newStatus as OrderPaymentStatus[])
    }
    if (type === 'shipping') {
      const newStatus = prepareFiltersState(shippingStatus, value)
      setShippingStatus(newStatus as OrderShipmentStatus[])
    }
    if (type === 'order') {
      const newStatus = prepareFiltersState(orderStatus, value)
      setOrderStatus(newStatus as OrderStatus[])
    }
  }

  const handleChangeActiveLocation = (locationId: number) => {
    setActiveLocation(locationId)
  }

  const handleSetSelectedFilers = () => {
    setShippingStatus(filters.shippingStatus || [])
    setPaymentStatus(filters.paymentStatus || [])
    setOrderStatus(filters.status || [])
    setActiveLocation(filters.locationId)
  }

  const handleClearFilersAndLocation = () => {
    setShippingStatus([])
    setPaymentStatus([])
    setOrderStatus([])
    setActiveLocation(undefined)
  }

  const handleCloseDateRange = () => {
    setDateValues(finalDateValues)
  }

  const handleAcceptDateRange = () => {
    setFinalDateValues(dateValues)
  }

  const handleClearDateRange = () => {
    setDateValues([null, null])
    setFinalDateValues([null, null])
  }

  const handleClearAllFilters = () => {
    handleClearFilersAndLocation()
    setFilters({})
    handleClearDateRange()
    setSearch('')
  }

  const handleStatusChange = (value: string) => {
    if (value === 'all') {
      handleClearAllFilters()
      return
    }

    const newFilters = { ...filters }
    const newStatus = [value as OrderStatus]
    newFilters.status = newStatus
    setOrderStatus(newStatus)
    setFilters(newFilters)
  }

  const handlePayMultipleOrders = (ids: number[]) => {
    const url =
      ids.length === 1
        ? `/orders/${ids[0]}/pay`
        : `/orders/pay?orders=${ids.join(',')}`
    void router.push(url)
  }

  return {
    data,
    total,
    filter,
    page,
    count,
    search,
    orders,
    handleClearSearchClick,
    handleSearchChange,
    handleStatusChange,
    handleChangePage,
    handleCheckboxChange,
    handleChangeActiveLocation,
    applyFilters,
    handleSetSelectedFilers,
    handleClearFilersAndLocation,
    handleClearAllFilters,
    paymentStatus,
    shippingStatus,
    orderStatus,
    locations,
    activeLocation,
    dateValues,
    handleCloseDateRange,
    handleAcceptDateRange,
    handleClearDateRange,
    setDateValues,
    handleRequestSort,
    order,
    orderBy,
    notEmpty,
    handlePayMultipleOrders,
  }
}
