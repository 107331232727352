import { Box, IconButton, Tooltip } from '@mui/material'
import { DropdownMenu } from 'components/DropdownMenu'
import React, { FC, useState } from 'react'

import { Props } from './ActionButton.types'

const ActionButton: FC<Props> = ({
  id,
  Icon,
  tooltipTitle,
  onClick,
  isDropdown,
  dropdownItems,
}) => {
  const [actionsVisible, setActionsVisible] = useState(false)

  const [open, setOpen] = useState<null | HTMLElement>(null)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
    setActionsVisible(true)
  }

  const handleCloseMenu = () => {
    setOpen(null)
    setActionsVisible(false)
  }

  const anchorRef = React.useRef<HTMLButtonElement>(null)
  return (
    <Box
      sx={{ position: 'relative' }}
      className={actionsVisible ? 'active-button' : ''}
    >
      <Tooltip title={tooltipTitle || ''}>
        <IconButton
          ref={anchorRef}
          onClick={isDropdown ? handleOpenMenu : onClick}
          sx={{ mr: '32px', padding: '8px' }}
        >
          {Icon}
        </IconButton>
      </Tooltip>
      {isDropdown && dropdownItems && (
        <DropdownMenu
          id={id as string}
          isCloseOnSelect
          onCloseClick={handleCloseMenu}
          open={Boolean(open)}
          items={dropdownItems}
          anchorEl={anchorRef.current}
        />
      )}
    </Box>
  )
}

export default ActionButton
