import { axiosExtended } from 'api/axios'

import { putNotificationRuleEmailUrl } from '../urls'
import {
  UpdateNotificationRuleEmailData,
  UpdateNotificationRuleEmailResponse,
} from './updateNotificationRuleEmail.types'

export const updateNotificationRuleEmail = async (
  data: UpdateNotificationRuleEmailData
) => {
  const { href } = putNotificationRuleEmailUrl()
  const response = await axiosExtended.put<UpdateNotificationRuleEmailResponse>(
    href,
    data,
    { withAuth: true }
  )
  return response.data
}
