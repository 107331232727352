import { axiosExtended } from 'api/axios'

import { getAuctionUrl } from '../urls'
import { GetAuctionData, GetAuctionResponse } from './getAuction.types'

export const getAuction = async (data: GetAuctionData) => {
  const { href } = getAuctionUrl(data)
  const response = await axiosExtended.get<GetAuctionResponse>(href, {
    withAuth: true,
  })
  return response.data
}
