import { ThemeOptions } from '@mui/material/styles'

export const lightThemeOptions: ThemeOptions = {
  palette: {
    common: {
      black: 'rgba(34, 34, 34, 1)',
      white: '#ffffff',
    },
    grey: {
      50: 'rgba(34, 34, 34, 0.04)',
      100: 'rgba(34, 34, 34, 0.08)',
      200: 'rgba(34, 34, 34, 0.12)',
      300: 'rgba(34, 34, 34, 0.24)',
      400: 'rgba(34, 34, 34, 0.32)',
      500: 'rgba(34, 34, 34, 0.48)',
      600: 'rgba(34, 34, 34, 0.72)',
    },
    primary: {
      main: '#009970',
      light: '#009816',
      dark: '#008662',
      contrastText: '#ffffff',
      100: 'rgba(0, 153, 112, 0.08)',
    },
    secondary: {
      main: '#004F44',
      contrastText: '#ffffff',
    },
    error: {
      main: 'rgba(245, 39, 26, 1)',
    },
    success: {
      main: '#009970',
    },
    text: {
      primary: '#222222',
      secondary: 'rgba(34, 34, 34, 0.48)',
    },
    action: {
      disabled: 'rgba(255, 255, 255, 0.4)',
      disabledBackground: '#009970',
    },
    divider: 'rgba(34, 34, 34, 0.12)',
  },
  typography: {
    fontFamily: 'Open Sans',
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    h1: {
      fontSize: 22,
      fontWeight: 600,
      lineHeight: '32px',
    },
    h2: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '24px',
    },
    h3: {
      fontSize: 17,
      fontWeight: 600,
      lineHeight: '22px',
    },
    h4: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '22px',
    },
    h5: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '22px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&.with-background': {
            backgroundColor: '#004F44',
          },
          '* :not(.MuiTableContainer-root)::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            width: 14,
            height: 14,
          },
          '& *::-webkit-scrollbar-thumb': {
            borderRadius: 14,
            backgroundColor: 'rgba(34, 34, 34, 0.24)',
            border: '4px solid transparent',
            backgroundClip: 'padding-box',
          },
          '*::-webkit-scrollbar-track': {
            border: '4px solid transparent',
            padding: '4px',
          },
          '& *::-webkit-scrollbar-corner': {
            backgroundColor: 'transparent',
          },
        },
        '.Toastify': {
          '--toastify-toast-min-height': '48px',
          '--toastify-toast-width': 'auto',
          '--toastify-color-dark': 'rgba(34, 34, 34, 1)',
          '.Toastify__toast': {
            borderRadius: 48,
            padding: '2px 10px',
            boxShadow: 'none',
          },
          '.Toastify__toast-container--top-center': {
            top: '20px',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          borderRadius: '24px',
          fontWeight: '600',
          textTransform: 'unset',
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#004F44',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#009970',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 60,
          height: 40,
        },
        thumb: {
          width: 22,
          height: 22,
          boxShadow:
            '0px 1px 4px rgba(34, 34, 34, 0.16), 0px 1px 1px rgba(34, 34, 34, 0.2)',
        },
        track: {
          opacity: 0.32,
          borderRadius: 8,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '4px',
          borderRadius: '2px',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: '16px',
          padding: '0px 16px 6px',
          color: 'rgba(34, 34, 34, 0.72)',
          textTransform: 'none',
          minHeight: '24px',
          '&.MuiButtonBase-root': {
            minHeight: '24px',
            minWidth: 'auto',
            padding: '0 16px  6px',
            '&:not(:last-child)': {
              marginRight: '12px',
            },
          },
          '&.Mui-selected': {
            color: 'rgba(34, 34, 34, 1)',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          lineHeight: '20px',
          maxWidth: '400px',
          color: '#ffffff',
          backgroundColor: '#222222',
          borderRadius: '16px',
          padding: '3px 8px',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          height: '22px',
          width: '22px',
          boxSizing: 'border-box',
          border: '1px solid white',
          borderRadius: '50%',
          top: '9px',
          right: '5px',
          fontWeight: 700,
          background: 'rgba(0, 153, 112, 1)',
          color: 'rgba(255, 255, 255, 1)',
        },
      },
    },
  },
}
