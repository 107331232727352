import { axiosExtended } from 'api/axios'

import { getLoginAsUserAsAdminUrl } from '../urls'
import {
  LoginAsUserAsAdminData,
  LoginAsUserAsAdminResponse,
} from './loginAsUserAsAdmin.types'

export const loginAsUserAsAdmin = async (data: LoginAsUserAsAdminData) => {
  const { href } = getLoginAsUserAsAdminUrl()
  const response = await axiosExtended.post<LoginAsUserAsAdminResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
