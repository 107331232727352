import { AllowedCountriesForBusiness } from 'types/enums'
import * as Yup from 'yup'

export const validationEmailSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Email is invalid'),
  firstName: Yup.string().trim().required('First name is required'),
  lastName: Yup.string().trim().required('Last name is required'),
})

export const validationPasswordSchema = Yup.object().shape({
  password: Yup.string().trim().required('Password is required'),
  newPassword: Yup.string().trim().required('New password is required'),
  repeatPassword: Yup.string()
    .trim()
    .required('Password confirmation is required'),
})

export const validationBusinessSchema = Yup.object().shape({
  businessName: Yup.string().trim().required('Business name is required'),
  address: Yup.string().trim().required('Address is required'),
  city: Yup.string().trim().required('City is required'),
  country: Yup.string().trim().required('Country is required'),
  zip: Yup.string().trim().required('Zip is required'),
  phoneNumber: Yup.string().trim().required('Phone number is required'),
  businessCountry: Yup.string().trim(),
  fullName: Yup.string().trim(),
  primaryContactPersonRole: Yup.string().trim(),
})

export const validationLocationSchema = Yup.object().shape({
  locationTypeId: Yup.number().required('Location type is required'),
  name: Yup.string().trim().required('Name is required'),
  address: Yup.string().trim().required('Address is required'),
  apartment: Yup.string().trim(),
  city: Yup.string().trim().required('City is required'),
  state: Yup.string().trim().required('State is required'),
  country: Yup.string().trim().required('Country is required'),
  zip: Yup.string().trim().required('Zip is required'),
  contactFullname: Yup.string()
    .trim()
    .min(4, ({ min }) => `Full name length should be ${min} characters long`)
    .required('Contact Person Full Name is required'),
  contactPhoneNumber: Yup.string()
    .trim()
    .required('Contact Person Phone number is required'),
  instruction: Yup.string().trim(),
  isDefault: Yup.bool().required('isDefault is required'),
})

export const validationBuyerLocationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Location name is required')
    .min(2, 'Location name length should be at least 2 characters long'),
  address: Yup.string()
    .trim()
    .required('Address is required')
    .min(3, 'Address length must be at least 3 characters long'),
  apartment: Yup.string().trim(),
  city: Yup.string()
    .trim()
    .required('City is required')
    .min(2, 'City length should be at least 2 characters long'),
  state: Yup.string()
    .trim()
    .when('country', {
      is: AllowedCountriesForBusiness['United States'],
      then: Yup.string().trim().required('State is required'),
    }),
  country: Yup.string().trim().required('Country is required'),
  zip: Yup.string()
    .trim()
    .required('Zip is required')
    .min(2, 'ZIP length must be at least 2 characters long'),
  contactPhoneNumber: Yup.string()
    .trim()
    .required('Contact Person Phone number is required'),
  isDefault: Yup.bool().required('isDefault is required'),
})
