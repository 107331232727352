import { axiosExtended } from 'api/axios'

import { getSetAuctionWinnerUrl } from '../urls'
import {
  SetAuctionWinnerData,
  SetAuctionWinnerResponse,
} from './setAuctionWinner.types'

export const setAuctionWinner = async (data: SetAuctionWinnerData) => {
  const { href } = getSetAuctionWinnerUrl(data)
  const response = await axiosExtended.put<SetAuctionWinnerResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
