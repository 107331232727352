import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const MenuIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M3 6h18v2H3V6ZM3 11h18v2H3v-2ZM3 18h18v-2H3v2Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
