import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const JCBLogo: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M36 24.281a5.6 5.6 0 0 1-5.59 5.594H0V7.719a5.6 5.6 0 0 1 5.59-5.594H36v22.156Z'
      fill='#fff'
    />
    <path
      d='M26.12 18.599h2.313c.066 0 .22-.022.286-.022.44-.089.815-.485.815-1.036 0-.528-.374-.925-.815-1.035-.066-.022-.198-.022-.286-.022H26.12V18.6Z'
      fill='url(#a)'
    />
    <path
      d='M28.168 3.996a4.009 4.009 0 0 0-4.008 4.008v4.163h5.66c.132 0 .287 0 .397.022 1.277.066 2.224.727 2.224 1.872 0 .903-.639 1.674-1.828 1.828v.044c1.3.088 2.29.815 2.29 1.938 0 1.211-1.1 2.004-2.554 2.004h-6.21v8.15h5.88a4.009 4.009 0 0 0 4.008-4.01V3.997h-5.859Z'
      fill='url(#b)'
    />
    <path
      d='M29.248 14.325a.937.937 0 0 0-.815-.946c-.044 0-.154-.023-.22-.023H26.12v1.938h2.092c.067 0 .199 0 .22-.021a.937.937 0 0 0 .816-.947Z'
      fill='url(#c)'
    />
    <path
      d='M6.012 3.996a4.009 4.009 0 0 0-4.008 4.008v9.89c1.123.55 2.29.902 3.458.902 1.387 0 2.136-.837 2.136-1.982v-4.67h3.436v4.648c0 1.806-1.123 3.281-4.933 3.281-2.313 0-4.119-.506-4.119-.506v8.435h5.88a4.009 4.009 0 0 0 4.009-4.008V3.996H6.012Z'
      fill='url(#d)'
    />
    <path
      d='M17.09 3.996a4.009 4.009 0 0 0-4.008 4.008v5.242c1.013-.859 2.775-1.41 5.616-1.277 1.52.066 3.15.484 3.15.484v1.696c-.815-.418-1.784-.793-3.04-.88-2.158-.155-3.457.902-3.457 2.752 0 1.872 1.299 2.93 3.457 2.753 1.256-.088 2.225-.485 3.04-.88v1.695s-1.608.418-3.15.485c-2.84.132-4.603-.419-5.616-1.278v9.25h5.88a4.009 4.009 0 0 0 4.009-4.008V3.996h-5.88Z'
      fill='url(#e)'
    />
    <defs>
      <linearGradient
        id='a'
        x1={24.148}
        y1={17.549}
        x2={34.047}
        y2={17.549}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#007940' />
        <stop offset={0.229} stopColor='#00873F' />
        <stop offset={0.743} stopColor='#40A737' />
        <stop offset={1} stopColor='#5CB531' />
      </linearGradient>
      <linearGradient
        id='b'
        x1={24.149}
        y1={16.017}
        x2={34.038}
        y2={16.017}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#007940' />
        <stop offset={0.229} stopColor='#00873F' />
        <stop offset={0.743} stopColor='#40A737' />
        <stop offset={1} stopColor='#5CB531' />
      </linearGradient>
      <linearGradient
        id='c'
        x1={24.148}
        y1={14.333}
        x2={34.046}
        y2={14.333}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#007940' />
        <stop offset={0.229} stopColor='#00873F' />
        <stop offset={0.743} stopColor='#40A737' />
        <stop offset={1} stopColor='#5CB531' />
      </linearGradient>
      <linearGradient
        id='d'
        x1={1.992}
        y1={16.006}
        x2={12.042}
        y2={16.006}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#1F286F' />
        <stop offset={0.475} stopColor='#004E94' />
        <stop offset={0.826} stopColor='#0066B1' />
        <stop offset={1} stopColor='#006FBC' />
      </linearGradient>
      <linearGradient
        id='e'
        x1={13.018}
        y1={16.028}
        x2={22.778}
        y2={16.028}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#6C2C2F' />
        <stop offset={0.173} stopColor='#882730' />
        <stop offset={0.573} stopColor='#BE1833' />
        <stop offset={0.859} stopColor='#DC0436' />
        <stop offset={1} stopColor='#E60039' />
      </linearGradient>
    </defs>
  </SvgWrapper>
)
