import { axiosExtended } from 'api/axios'

import { getResetPasswordUrl } from '../urls'
import { ResetPasswordData } from './resetPassword.types'

export const resetPassword = async (data: ResetPasswordData) => {
  const { href } = getResetPasswordUrl()
  // TODO: add corresponding type for the response
  // eslint-disable-next-line
  const response = await axiosExtended.post<any>(href, data)
  return response.data
}
