import React, { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const CardIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2l-.01-12c0-1.11-.88-2-1.99-2H4Zm0 2v2h16V6H4Zm0 6v6h16v-6H4Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
