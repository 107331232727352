import { axiosExtended } from 'api/axios'

import { getRemoveLocationUrl } from '../urls'
import { RemoveLocationData } from './removeLocation.types'

export const removeLocation = async (query: RemoveLocationData) => {
  const { href } = getRemoveLocationUrl(query)
  const response = await axiosExtended.delete(href, {
    withAuth: true,
  })
  return response.data
}
