import {
  getManufacturersList,
  GetManufacturersListResponse,
} from 'api/requests/auction'
import { useEffect, useState } from 'react'
import { useQueryParam } from 'use-query-params'
import { CommaArrayParam } from 'utils/use-query-params'

export const useManufacturers = (syncWithUrl = false) => {
  const [manufacturersQuery, setManufacturersQuery] = useQueryParam(
    'manufacturers',
    CommaArrayParam
  )

  const [manufacturers, setManufacturers] =
    useState<GetManufacturersListResponse>()
  const [favoriteManufacturers, setFavoriteManufacturers] = useState<number[]>(
    () =>
      syncWithUrl
        ? manufacturersQuery?.map(manufacturer => Number(manufacturer)) ?? []
        : []
  )

  useEffect(() => {
    if (!syncWithUrl) {
      return
    }

    if (favoriteManufacturers.length === 0) {
      setManufacturersQuery(null)
      return
    }

    setManufacturersQuery(
      favoriteManufacturers.map(manufacturer => String(manufacturer))
    )
  }, [favoriteManufacturers, syncWithUrl])

  const fetchManufacturers = async () => {
    try {
      const response = await getManufacturersList({
        extended: true,
        mainQuantity: 29,
      })
      if (response?.data) {
        setManufacturers(response.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleManufacturerSelect = (manufacturerId: number) => {
    let nextFavoriteManufacturers = [...favoriteManufacturers]

    if (nextFavoriteManufacturers?.includes(manufacturerId)) {
      nextFavoriteManufacturers = nextFavoriteManufacturers.filter(
        id => id !== manufacturerId
      )
    } else {
      nextFavoriteManufacturers.push(manufacturerId)
    }
    setFavoriteManufacturers(nextFavoriteManufacturers)
  }

  const handleRestManufacturersSelect = (manufacturerIds: number[]) => {
    let nextFavoriteManufacturers = [...favoriteManufacturers]
    if (nextFavoriteManufacturers?.includes(manufacturerIds[0])) {
      nextFavoriteManufacturers = nextFavoriteManufacturers.filter(
        id => !manufacturerIds.includes(id)
      )
    } else {
      nextFavoriteManufacturers = [
        ...nextFavoriteManufacturers,
        ...manufacturerIds,
      ]
    }
    setFavoriteManufacturers(nextFavoriteManufacturers)
  }

  return {
    manufacturers,
    fetchManufacturers,
    favoriteManufacturers,
    handleManufacturerSelect,
    setFavoriteManufacturers,
    handleRestManufacturersSelect,
  }
}
