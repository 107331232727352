import { axiosExtended } from 'api/axios'

import { getAdminLocationListUrl } from '../urls'
import { GetLocationListAsAdminData } from './getLocationListAsAdmin.types'

export const getLocationListAsAdmin = async () => {
  const { href } = getAdminLocationListUrl()
  const response = await axiosExtended.get<GetLocationListAsAdminData>(href, {
    withAuth: true,
  })
  return response.data
}
