import { axiosExtended } from 'api/axios'

import { getAuctionLocationListUrl } from '../urls'
import { GetLocationListResponse } from './getLocationList.types'

export const getLocationList = async () => {
  const { href } = getAuctionLocationListUrl()
  const response = await axiosExtended.get<GetLocationListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
