/* eslint-disable */
export const areValuesInArraySame = (
  array: any[],
  normalizeFn?: (value: any) => any
) => {
  return array.every(value => {
    return array.every(valueToCompare => {
      return normalizeFn
        ? normalizeFn(valueToCompare) === normalizeFn(value)
        : valueToCompare === value
    })
  })
}
