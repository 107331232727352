import { AuctionCreateTemplate } from 'types/auction'
import { normalizeString } from 'utils/string'

export const getValuesByColumnName = (
  template: AuctionCreateTemplate[],
  columnName: string
): unknown[] => {
  return template
    .map(row => {
      return Object.entries(row)
        .filter(([column]) => normalizeString(column) === columnName)
        .map(([, value]) => value)
    })
    .flat()
}
