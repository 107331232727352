import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const BigCircledTickIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='m47.508 23.519-2.829-2.829-17.997 17.997-7.351-7.35-2.828 2.827 10.18 10.18L47.507 23.52Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.667 32c0 14.727-11.94 26.666-26.667 26.666C17.272 58.666 5.333 46.727 5.333 32 5.333 17.272 17.273 5.333 32 5.333c14.728 0 26.667 11.94 26.667 26.667Zm-4 0c0 12.518-10.148 22.666-22.667 22.666C19.482 54.666 9.333 44.518 9.333 32 9.333 19.48 19.482 9.333 32 9.333c12.519 0 22.667 10.148 22.667 22.667Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
