import { axiosExtended } from 'api/axios'

import { getInvitedUserUrl } from '../urls'
import {
  GetInvitedUserItemData,
  GetInvitedUserResponse,
} from './getInvitedUser.types'

export const getInvitedUser = async (data: GetInvitedUserItemData) => {
  const { href } = getInvitedUserUrl(data)
  const response = await axiosExtended.get<GetInvitedUserResponse>(href)
  return response.data
}
