import { axiosExtended } from 'api/axios'

import { getGetAuctionConditionListUrl } from '../urls'
import { GetAuctionConditionListResponse } from './getAuctionConditionList.types'

export const getAuctionConditionList = async () => {
  const { href } = getGetAuctionConditionListUrl()
  const response = await axiosExtended.get<GetAuctionConditionListResponse>(
    href,
    { withAuth: true }
  )
  return response.data
}
