import { axiosExtended } from 'api/axios'

import { getEditLotDetailsUrl } from '../urls'
import { GetLotDetailsResponse } from './getLotDetails.types'

export const getLotDetails = async (lotId: string) => {
  const { href } = getEditLotDetailsUrl(lotId)
  const response = await axiosExtended.get<GetLotDetailsResponse>(href, {
    withAuth: true,
  })
  return response.data
}
