import { yupResolver } from '@hookform/resolvers/yup'
import {
  getAuctionCategoryList,
  GetAuctionCategoryListData,
} from 'api/requests/auction'
import { createAuctionCategory } from 'api/requests/auction'
import { TableData } from 'components/CollapsibleTable'
import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AuctionCategory, CreateAuctionCategory } from 'types/auction'
import { smoothScroll } from 'utils/scroll'
import * as Yup from 'yup'

const categoryValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Category name is required'),
})

const subcategoryValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Subcategory name is required'),
  parentCategoryId: Yup.number().required('Parent category is required'),
})

export const useAdminCategories = () => {
  const [activeTab, setActiveTab] = useState<string>('category')
  const isActiveTabCategory = activeTab === 'category'

  const [data, setData] = useState<TableData | undefined>()
  const [categories, setCategories] = useState<AuctionCategory[]>([])

  const perPage = 25

  const [openCategory, setOpenCategory] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [count, setCount] = useState<number>(1)

  const {
    control: categoryControl,
    handleSubmit: handleSubmitCategory,
    formState: categoryFormState,
    reset: categoryReset,
  } = useForm<CreateAuctionCategory>({
    mode: 'all',
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(categoryValidationSchema),
  })

  const {
    control: subcategoryControl,
    handleSubmit: handleSubmitSubcategory,
    formState: subcategoryFormState,
    reset: subcategoryReset,
  } = useForm<CreateAuctionCategory>({
    mode: 'all',
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(subcategoryValidationSchema),
  })

  const onSubmit = async (formData: CreateAuctionCategory) => {
    try {
      await createAuctionCategory(formData)
    } catch (e) {
      console.error(e)
    }
  }

  const fetchCategories = async (query?: GetAuctionCategoryListData) => {
    const response = await getAuctionCategoryList(query)

    if (response?.data) {
      const categoriesData = response?.data?.items
      const currentData = {
        rows: categoriesData.map(
          ({ id, value, subCategories, subCategoriesTotal, auctions }) => ({
            cells: [
              { title: value, isTextEllipsis: true, maxCellWidth: '342px' },
              {
                title: subCategoriesTotal,
                isTextEllipsis: true,
                maxCellWidth: '225px',
              },
              {
                title: auctions || 0,
                isMarkedText: true,
                isTextEllipsis: true,
                maxCellWidth: '232px',
              },
              // TODO will be used in future
              // {
              //   title: isActive,
              //   isCheckbox: true,
              // },
            ],
            id,
            subRows: subCategories?.map(
              ({
                value: subCategoryValue,
                id: subCategoryId,
                auctions: subCategoryAuctions,
              }) => ({
                cells: [
                  {
                    title: subCategoryValue,
                    isTextEllipsis: true,
                    maxCellWidth: '345px',
                  },
                  { title: '-', isTextEllipsis: true, maxCellWidth: '222px' },
                  {
                    title: subCategoryAuctions || 0,
                    isMarkedText: true,
                    isTextEllipsis: true,
                    maxCellWidth: '230px',
                  },
                  // {
                  //   title: true,
                  //   isCheckbox: true,
                  // },
                ],
                id: subCategoryId,
              })
            ),
          })
        ),
      }
      setCategories(categoriesData)
      setData(currentData)
      setTotal(response?.data?.total)
      setCount(Math.ceil(response?.data?.total / perPage))
    }
  }

  const handleTabChange = (value: string) => {
    setActiveTab(value)
  }

  const handleResetForm = () => {
    categoryReset()
    subcategoryReset()
  }

  const handleCreateCategory = async () => {
    if (isActiveTabCategory) {
      await handleSubmitCategory(onSubmit)()
    } else {
      await handleSubmitSubcategory(onSubmit)()
    }
    setOpenCategory(false)
    handleResetForm()
    await fetchCategories()
  }

  const handleCloseCategory = () => setOpenCategory(false)

  const handleCategory = () => setOpenCategory(true)

  const isSubmitDisabled = isActiveTabCategory
    ? categoryFormState.isSubmitting || !categoryFormState.isValid
    : subcategoryFormState.isSubmitting || !subcategoryFormState.isValid

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    smoothScroll()
  }

  return {
    data,
    page,
    count,
    perPage,
    total,
    setPage,
    categories,
    fetchCategories,
    handleCreateCategory,
    handleResetForm,
    handleTabChange,
    handleCloseCategory,
    handleCategory,
    handleChangePage,
    categoryControl,
    subcategoryControl,
    isSubmitDisabled,
    activeTab,
    openCategory,
  }
}
