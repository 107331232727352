import { axiosExtended } from 'api/axios'

import { getDownloadInvoiceAsAdminUrl } from '../urls'
import { DownloadInvoiceAsAdminResponse } from './downloadInvoiceAsAdmin.types'

export const downloadInvoiceAsAdmin = async (id: number) => {
  const { href } = getDownloadInvoiceAsAdminUrl(id)
  const response = await axiosExtended.get<DownloadInvoiceAsAdminResponse>(
    href,
    {
      withAuth: true,
      responseType: 'blob',
    }
  )

  return response.data
}
