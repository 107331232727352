import { axiosExtended } from 'api/axios'

import { getAddWatchlistUrl } from '../urls'
import { AddWatchlistData, AddWatchlistResponse } from './addWatchlist.types'

export const addWatchlist = async (data: AddWatchlistData) => {
  const { href } = getAddWatchlistUrl()
  const response = await axiosExtended.post<AddWatchlistResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
