import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { GetInvitedUserItemData } from './getInvitedUser'
import { RemoveStaffUserData } from './removeStaffUser'

export const getInviteUserUrl = () => {
  const url = `${API_URL}/user/staff/invite`
  return new URL(url)
}

export const resendInviteUrl = () => {
  const url = `${API_URL}/user/staff/resendInvite`
  return new URL(url)
}

export const getInvitedUserUrl = (query: GetInvitedUserItemData) => {
  const url = `${API_URL}/user/staff`
  if (!query) {
    return new URL(url)
  }

  const params = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + params)
}

export const getRegisterInvitedUserUrl = () => {
  const url = `${API_URL}/user/staff/register`
  return new URL(url)
}

export const getStaffUserListUrl = () => {
  const url = `${API_URL}/user/staff/list`
  return new URL(url)
}

export const getRemoveStaffUserUrl = (query: RemoveStaffUserData) => {
  const url = `${API_URL}/user/staff/delete`
  if (!query) {
    return new URL(url)
  }

  const params = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + params)
}
