import { axiosExtended } from 'api/axios'

import { putReadNotifications } from '../urls'
import {
  ReadNotificationsData,
  ReadNotificationsResponse,
} from './readNotifications.types'

export const readNotifications = async (data?: ReadNotificationsData) => {
  const { href } = putReadNotifications(data)
  const response = await axiosExtended.put<ReadNotificationsResponse>(
    href,
    null,
    {
      withAuth: true,
    }
  )
  return response.data
}
