import { axiosExtended } from 'api/axios'

import { getCloseAuctionUrl } from '../urls'
import { CloseAuctionData, CloseAuctionResponse } from './closeAuction.types'

export const closeAuction = async (data: CloseAuctionData) => {
  const { href } = getCloseAuctionUrl()
  const response = await axiosExtended.post<CloseAuctionResponse>(href, data, {
    withAuth: true,
  })
  return response.data
}
