import { axiosExtended } from 'api/axios'

import { getDownloadLotAssetsUrl } from '../urls'
import {
  DownloadLotAssetsData,
  DownloadLotAssetsResponse,
} from './downloadLotAssets.types'

export const downloadLotAssets = async (data: DownloadLotAssetsData) => {
  const { href } = getDownloadLotAssetsUrl(data)
  const response = await axiosExtended.get<DownloadLotAssetsResponse>(href, {
    responseType: 'blob',
  })

  return response.data
}
