import { getLocationList, GetLocationListResponse } from 'api/requests/auction'
import { useEffect, useState } from 'react'
import { useQueryParam } from 'use-query-params'
import { CommaArrayParam } from 'utils/use-query-params'

export const useLocations = (syncWithUrl = false) => {
  const [locationsQuery, setLocationsQuery] = useQueryParam(
    'locations',
    CommaArrayParam
  )

  const [locations, setLocations] = useState<GetLocationListResponse>()
  const [favoriteLocations, setFavoriteLocations] = useState<string[]>(() =>
    syncWithUrl ? locationsQuery?.map(location => String(location)) ?? [] : []
  )

  useEffect(() => {
    if (!syncWithUrl) {
      return
    }

    if (favoriteLocations.length === 0) {
      setLocationsQuery(null)
      return
    }

    setLocationsQuery(favoriteLocations)
  }, [favoriteLocations, syncWithUrl])

  const fetchLocations = async () => {
    try {
      const response = await getLocationList()
      if (response?.data) {
        setLocations(response.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleLocationSelect = (locationState: string) => {
    let nextFavoriteLocations = [...favoriteLocations]

    if (nextFavoriteLocations?.includes(locationState)) {
      nextFavoriteLocations = nextFavoriteLocations.filter(
        state => state !== locationState
      )
    } else {
      nextFavoriteLocations.push(locationState)
    }
    setFavoriteLocations(nextFavoriteLocations)
  }

  return {
    locations,
    fetchLocations,
    handleLocationSelect,
    favoriteLocations,
    setFavoriteLocations,
  }
}
