export enum UserRoles {
  Buyer = 'buyer',
  Seller = 'seller',
  Admin = 'admin',
}

export enum PrimaryContactPersonRoles {
  Owner = '1',
  Representative = '2',
}
