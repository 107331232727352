import { axiosExtended } from 'api/axios'

import { getOrdersUrl } from '../urls'
import { GetOrdersData, GetOrdersResponse } from './getOrders.types'

export const getOrders = async (query: GetOrdersData) => {
  const { href } = getOrdersUrl(query)
  const response = await axiosExtended.get<GetOrdersResponse>(href, {
    withAuth: true,
  })
  return response.data
}
