export enum StripePaymentMethodTypes {
  Card = 'card',
  UsBankAccount = 'us_bank_account',
  AchCreditTransfer = 'ach_credit_transfer',
}

export enum PaymentMethodTypes {
  Card = 'card',
  UsBankAccount = 'us_bank_account',
  AchDomestic = 'ach_domestic',
  // AchInternational = 'ach_international',
}
