export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'

export const LOGGED_IN_AS_USER_ACCESS_TOKEN = 'LOGGED_IN_AS_USER_ACCESS_TOKEN'
export const LOGGED_IN_AS_USER_REFRESH_TOKEN = 'LOGGED_IN_AS_USER_REFRESH_TOKEN'

export const PAYMENT_ACCESS_TOKEN = 'PAYMENT_ACCESS_TOKEN'

export const USER = 'USER'

export const DASHBOARD_FILTERS = 'DASHBOARD_FILTERS'
