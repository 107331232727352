import { FC, SVGProps } from 'react'

import { SvgWrapper } from './SvgWrapper'

export const FollowingIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <SvgWrapper {...props}>
    <path
      d='M16 14v3H8v-7c0-2.21 1.79-4 4-4 .85 0 1.64.26 2.28.72l1.43-1.43A5.87 5.87 0 0 0 13.5 4.2v-.7c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.7C7.91 4.86 6 7.21 6 10v7H4v2h16v-2h-2v-3h-2Zm-4 8c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2Z'
      fill='currentColor'
    />
    <path
      d='M13.67 9.66 17.01 13l6.09-6.09-1.43-1.41L17 10.17l-1.92-1.92-1.41 1.41Z'
      fill='currentColor'
    />
  </SvgWrapper>
)
