import {
  getLocationListAsAdmin,
  GetLocationListAsAdminResponse,
} from 'api/requests/admin'
import { useState } from 'react'

export const useAdminLocations = () => {
  const [adminLocationData, setAdminLocationData] = useState<
    GetLocationListAsAdminResponse[]
  >([])

  const fetchAdminLocation = async () => {
    try {
      const response = await getLocationListAsAdmin()
      if (response?.data?.items) {
        setAdminLocationData(response.data?.items)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return { adminLocationData, fetchAdminLocation }
}
