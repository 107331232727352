import { axiosExtended } from 'api/axios'

import { getAuctionCreateReminderUrl } from '../urls'
import {
  CreateAuctionReminderData,
  CreateAuctionReminderResponse,
} from './createAuctionReminder.types'

export const createAuctionReminder = async (
  data: CreateAuctionReminderData
) => {
  const { href } = getAuctionCreateReminderUrl()
  const response = await axiosExtended.post<CreateAuctionReminderResponse>(
    href,
    data,
    {
      withAuth: true,
    }
  )
  return response.data
}
