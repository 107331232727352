import { API_URL } from 'constants/env'
import { getSearchParamsWithSeparatorFromObject } from 'utils/url'

import { DownloadOrderFilesData } from './downloadOrderFiles'
import { GetOrderData } from './getOrder'
import { GetOrderListData } from './getOrderList'
import { GetOrdersData } from './getOrders'

export const getOrderListUrl = (query?: GetOrderListData): URL => {
  const url = `${API_URL}/order/list`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getOrderUrl = (params: GetOrderData): URL => {
  const url = `${API_URL}/order/`
  if (!params) {
    return new URL(url)
  }

  const { id } = params
  return new URL(url + String(id))
}

export const getOrdersUrl = (query?: GetOrdersData): URL => {
  const url = `${API_URL}/order/multiple`
  if (!query) {
    return new URL(url)
  }

  return new URL(`${url}?orderIds=${query.orderIds.join(',')}`)
}

export const getCompleteOrderUrl = (): URL => {
  const url = `${API_URL}/order/close`
  return new URL(url)
}

export const getOrdersToConsolidateUrl = (): URL => {
  const url = `${API_URL}/order/consolidate`
  return new URL(url)
}

export const getConsolidateOrdersUrl = (): URL => {
  const url = `${API_URL}/order/consolidate`
  return new URL(url)
}

export const getConsolidateShipmentUrl = (): URL => {
  const url = `${API_URL}/order/consolidate/shipping`
  return new URL(url)
}

export const getGetShippingCarrierListUrl = (): URL => {
  const url = `${API_URL}/shipping/carriers/list`
  return new URL(url)
}

export const getShippingTypeListUrl = (): URL => {
  const url = `${API_URL}/shipping/types/list`
  return new URL(url)
}

export const getUploadShippingLabelUrl = (): URL => {
  const url = `${API_URL}/shipping/labels/create`
  return new URL(url)
}

export const getUploadSignedShippingLabelUrl = (): URL => {
  const url = `${API_URL}/shipping/labels/file/upload`
  return new URL(url)
}

export const getDownloadOrderFilesUrl = (
  query?: DownloadOrderFilesData
): URL => {
  const url = `${API_URL}/shipping/labels/file`
  if (!query) {
    return new URL(url)
  }

  const searchParams = getSearchParamsWithSeparatorFromObject(query)
  return new URL(url + searchParams)
}

export const getDownloadInvoiceUrl = (id: number): URL => {
  const url = `${API_URL}/order/invoice`
  const searchParams = getSearchParamsWithSeparatorFromObject({ id })
  return new URL(url + searchParams)
}

export const getDownloadPackingListUrl = (id: number): URL => {
  const url = `${API_URL}/order/packingList`
  const searchParams = getSearchParamsWithSeparatorFromObject({ id })
  return new URL(url + searchParams)
}

export const getOrderGraphUrl = (today: number) => {
  const url = `${API_URL}/order/graph`
  const searchParams = getSearchParamsWithSeparatorFromObject({ today })
  return new URL(url + searchParams)
}

export const getRateOrderUrl = () => {
  const url = `${API_URL}/rating`
  return new URL(url)
}
