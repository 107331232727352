import { axiosExtended } from 'api/axios'

import { getDownloadUserDocument } from '../urls'
import { DownloadUserDocumentResponse } from './downloadUserDocument.types'

export const downloadUserDocument = async (userId: number) => {
  const { href } = getDownloadUserDocument(userId)
  const response = await axiosExtended.get<DownloadUserDocumentResponse>(href, {
    withAuth: true,
    responseType: 'blob',
  })

  return response.data
}
