import { axiosExtended } from 'api/axios'

import { getResendConfirmEmailUrl } from '../urls'
import { ResendConfirmEmailData } from './resendConfirmEmail.types'

export const resendConfirmEmail = async (data: ResendConfirmEmailData) => {
  const { href } = getResendConfirmEmailUrl()
  const response = await axiosExtended.post<any>(href, data)
  return response.data
}
