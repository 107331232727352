import { axiosExtended } from 'api/axios'

import { getStatusesListUrl } from '../urls'
import { GetStatusesListResponse } from './getStatusesList.types'

export const getStatusesList = async () => {
  const { href } = getStatusesListUrl()
  const response = await axiosExtended.get<GetStatusesListResponse>(href, {
    withAuth: true,
  })
  return response.data
}
