import {
  getAuctionCategoriesFavorites,
  getAuctionCategoryList,
  GetAuctionCategoryListData,
  setAuctionCategoriesFavorites,
} from 'api/requests/auction'
import { ChangeEventHandler, useEffect, useState } from 'react'
import { AuctionCategory } from 'types/auction'
import { useQueryParam } from 'use-query-params'
import { CommaArrayParam } from 'utils/use-query-params'

export const useCategories = (syncWithUrl = false) => {
  const [categoriesQuery, setCategoriesQuery] = useQueryParam(
    'categories',
    CommaArrayParam
  )

  const [categories, setCategories] = useState<AuctionCategory[]>([])
  const [favoriteCategories, setFavoriteCategories] = useState<number[]>(
    syncWithUrl ? categoriesQuery?.map(category => Number(category)) ?? [] : []
  )
  const [favoriteCategoriesCopy, setFavoriteCategoriesCopy] = useState<
    number[]
  >(() =>
    syncWithUrl ? categoriesQuery?.map(category => Number(category)) ?? [] : []
  )

  useEffect(() => {
    if (!syncWithUrl) {
      return
    }

    if (favoriteCategoriesCopy.length === 0) {
      setCategoriesQuery(null)
      return
    }

    setCategoriesQuery(favoriteCategoriesCopy.map(category => String(category)))
  }, [favoriteCategoriesCopy, syncWithUrl])

  const [search, setSearch] = useState<string>('')

  const handleClearSearchClick = () => {
    setSearch('')
  }

  const handleSearchChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = e => {
    const value = e.target.value
    setSearch(value)
  }

  const fetchCategories = async (query?: GetAuctionCategoryListData) => {
    try {
      const response = await getAuctionCategoryList(query)
      if (response?.data) {
        setCategories(response.data.items)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchFavoriteCategories = async () => {
    try {
      const response = await getAuctionCategoriesFavorites()
      if (response?.data) {
        setFavoriteCategories(response.data)
        setFavoriteCategoriesCopy(response.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleCategorySelect = (
    categoryId: number,
    subcategoryIds: number[]
  ) => {
    let nextFavoriteCategoriesCopy = [...favoriteCategoriesCopy].filter(
      id => !subcategoryIds.includes(id)
    )

    if (nextFavoriteCategoriesCopy?.includes(categoryId)) {
      nextFavoriteCategoriesCopy = nextFavoriteCategoriesCopy.filter(
        id => id !== categoryId
      )
    } else {
      nextFavoriteCategoriesCopy.push(categoryId)
    }

    setFavoriteCategoriesCopy(nextFavoriteCategoriesCopy)
  }

  const handleSubCategorySelect = (
    categoryId: number,
    parentCategoryId: number,
    subcategoryIds: number[],
    checkedSubCategories: number[]
  ) => {
    // creating a copy of favoriteCategoriesCopy
    let nextFavoriteCategoriesCopy = [...favoriteCategoriesCopy]

    if (nextFavoriteCategoriesCopy?.includes(categoryId)) {
      // if nextFavoriteCategoriesCopy includes current subcategoryId we just removing it from an array
      nextFavoriteCategoriesCopy = nextFavoriteCategoriesCopy.filter(
        id => id !== categoryId
      )
    } else {
      if (nextFavoriteCategoriesCopy?.includes(parentCategoryId)) {
        // if nextFavoriteCategoriesCopy includes parentCategoryId we are removing parentCategoryId from an array
        nextFavoriteCategoriesCopy = nextFavoriteCategoriesCopy.filter(
          id => id !== parentCategoryId
        )

        // and pushing all subcategories to an array except current subcategoryId
        nextFavoriteCategoriesCopy = [
          ...nextFavoriteCategoriesCopy,
          ...subcategoryIds,
        ].filter(id => id !== categoryId)
      } else {
        // we are removing all subcategories from an array if we are adding the last subcategory and other are selected
        if (subcategoryIds.length - checkedSubCategories.length === 1) {
          nextFavoriteCategoriesCopy = nextFavoriteCategoriesCopy.filter(
            id => !subcategoryIds.includes(id)
          )

          // and adding only parentCategoryId
          nextFavoriteCategoriesCopy.push(parentCategoryId)
        } else {
          // here we are just pushing categoryId to an array
          nextFavoriteCategoriesCopy.push(categoryId)
        }
      }
    }

    setFavoriteCategoriesCopy(nextFavoriteCategoriesCopy)
  }

  const resetFavoriteCategories = () => {
    setFavoriteCategoriesCopy(favoriteCategories)
  }

  const handleAcceptFavoriteCategories = async () => {
    try {
      await setAuctionCategoriesFavorites({
        categoryIds: favoriteCategoriesCopy,
      })
      await fetchFavoriteCategories()
    } catch (e) {
      console.error(e)
    }
  }

  const handleRestCategoriesSelect = (categoryIds: number[]) => {
    let nextFavoriteCategoriesCopy = [...favoriteCategoriesCopy]
    if (nextFavoriteCategoriesCopy?.includes(categoryIds[0])) {
      nextFavoriteCategoriesCopy = nextFavoriteCategoriesCopy.filter(
        catId => !categoryIds.includes(catId)
      )
    } else {
      nextFavoriteCategoriesCopy = [
        ...nextFavoriteCategoriesCopy,
        ...categoryIds,
      ]
    }
    setFavoriteCategoriesCopy(nextFavoriteCategoriesCopy)
  }

  return {
    categories,
    search,
    favoriteCategories,
    favoriteCategoriesCopy,
    fetchCategories,
    fetchFavoriteCategories,
    resetFavoriteCategories,
    handleCategorySelect,
    handleSubCategorySelect,
    handleAcceptFavoriteCategories,
    handleClearSearchClick,
    handleSearchChange,
    setFavoriteCategories,
    setFavoriteCategoriesCopy,
    handleRestCategoriesSelect,
    setSearch,
  }
}
